import React, { useState, useEffect } from 'react';
import Chart from 'react-apexcharts';

const SingleLineChart = ({ data, xLabel, ylabel, categories }) => {
    const initialChartOptions = {
        chart: {
            type: 'line',
            height: 350,
            toolbar: {
                show: false, // Hides the toolbar
                dashArray: 5,
            },
            zoom: {
                enabled: false,
            },
        },
        stroke: {
            curve: 'smooth', // Smooth line for all series
            width: 2,
        },
        xaxis: {
            categories: categories, // X-axis labels
            title: {
                text: xLabel,
                style: {
                    fontSize: '14px',
                    fontWeight: '500',
                    // color: '#344054',
                },
            },
        },
        yaxis: {
            title: {
                text: ylabel,
                style: {
                    fontSize: '14px',
                    fontWeight: '500',
                    // color: '#344054',
                },
            },
        },
        markers: {
            size: 6, // Circular marker sizecolors: ['#fff'], // Background color of the markerstrokeColors: ['#072B15', '#0B4522', '#126E36', '#1DB056'], // Line color for each markerstrokeWidth: 2, // Marker border widthshape: 'circle', // Circular shape
        },
        tooltip: {
            enabled: true,
        },
        colors: ['#1DB056'], // Colors for each line
        legend: {
            position: 'top',
            horizontalAlign: 'left',
            offsetX: 10, // Adjusts the X-offset of the legend
            offsetY: 10,
        },
    };

    const [chartOptions, setChartOptions] = useState(initialChartOptions);
    const [chartSeries, setChartSeries] = useState(data);

    useEffect(() => {
        setChartOptions(initialChartOptions);
        setChartSeries(data);
    }, []);
    return (
        <div style={{ width: '100%', height: '350px' }}>
            <Chart
                options={chartOptions}
                series={chartSeries}
                type='line'
                height={350}
            />
        </div>
    );
};

export default SingleLineChart;
