import Chart from 'react-apexcharts';
import React from 'react';

const SentimentChart = ({xLabel,yLabel, data}) => {
    const chart1Multi = {
        options: {
            chart: {
                type: 'bar',
                height: 350,
                toolbar: {
                    show: false, // Hide the toolbar
                },
            },
            colors: ['#12B76A', '#98A2B3', '#D92D20'], // Define colors for each series
            plotOptions: {
                bar: {
                    horizontal: false,
                    columnWidth: '40%', // Set bar width to 40% of available space
                    dataLabels: {
                        position: 'top',
                    },
                },
            },
            dataLabels: {
                enabled: false, // Disable data label values
                offsetX: -6,
                style: {
                    fontSize: '12px',
                },
            },
            stroke: {
                show: true,
                width: 1,
            },
            tooltip: {
                shared: true,
                intersect: false,
            },
            xaxis: {
                categories: xLabel,
            },
            yaxis: {
                title: {
                    text: yLabel, // Set text for y-axis
                    style: {
                        fontSize: '14px',
                        fontWeight: '400',
                        color: '#344054', // Customize color as needed
                    },
                },
            },
            legend: {
                position: 'top', // Set legend to top position
                horizontalAlign: 'left', // Align legend to the left
                markers: {
                    shape: 'circle', // Make legend icons circular
                    width: 12,
                    height: 12,
                    radius: 12, // Makes the markers circular
                },
            },
        },
        series: data,
    };

    return (
        <Chart
            options={chart1Multi.options}
            series={chart1Multi.series}
            type='bar'
            height='350'
        />
    );
};

export default SentimentChart;
