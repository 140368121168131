import React, { useState, useRef, useEffect } from 'react';
import { ErrorNotify } from '../../CommonLogic';
import { client } from '../../services/apiService';
import ObsStructure from '../Audit/ObsStructure';
import Select from 'react-select';
import { Form, FormCheck } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import * as AuthSelector from '../../store/auth/selectors';
import { useTranslation } from 'react-i18next';

const BulkUpload = () => {
    const [files, setFiles] = useState([]);
    const [uploading, setUploading] = useState(false);
    const [fileStatuses, setFileStatuses] = useState([]);
    const [fileStatusesCopy, setFileStatusesCopy] = useState([]);
    const [selectedDepartment, setSelectedDepartment] = useState(null);
    const [uploadButtonDisable, setUploadButtonDisable] = useState(false);
    const [selectedFileType, setSelectedFileType] = useState([]);
    const [fileTypeOptions, setFileTypeOptions] = useState([]);
    const [agentList, setAgentList] = useState([]);
    const [selectedAgent, setSelectedAgent] = useState('');
    const [loadingAgent, setLoadingAgent] = useState(true);
    const [showDropdown, setShowDropdown] = useState(false);
    const [selectedValue, setSelectedValue] = useState('');
    const [fileUploaded, setfileUploaded] = useState(false);
    const fileUploadedRef = useRef(false)
    const dropdownRef = useRef(null);
    const [minValue, setMinValue] = useState(null);
    const [maxValue, setMaxValue] = useState(null);
    const selectedValueRef = useRef(selectedValue);
    const minValueRef = useRef(minValue);
    const maxValueRef = useRef(maxValue);
    const selectedFileTypeRef = useRef(selectedFileType);
    const formatTypeRef = useRef();
    const obsRef = useRef();
    const agentListRef = useRef();
    const submitButtonRef = useRef();
    const navigate = useNavigate();
    const orgStrId = useSelector(AuthSelector.orgStrId);
    const { t } = useTranslation()
    useEffect(() => {
        setMaxValue(selectedValue === "Between" ? 5 : null)
        maxValueRef.current = selectedValue === "Between" ? 5 : null;
        setMinValue(selectedValue ? 2 : null)
        minValueRef.current = selectedValue ? 2 : null;
    }, [selectedValue]);

    const handleFileChange = async e => {
        const selectedFiles = [...e.target.files];
        const initialStatuses = selectedFiles.map(file => ({
            fileName: file.name,
            progress: 0,
            status: t('Bulk Audio Upload.Pending'),
            duration: null,
            fileType: file?.type,
        }));
        const fileTypes = initialStatuses.map(el => {
            let fileType = el.fileType?.split('/')?.[1];
            let val = fileType === 'mpeg' ? 'mp3' : fileType;
            return { label: val, value: el?.fileType };
        });
        setFileTypeOptions([
            ...new Map(fileTypes.map(item => [item.value, item])).values(),
        ]);
        setFiles(selectedFiles);
        setFileStatuses(initialStatuses);
        setFileStatusesCopy(initialStatuses);
        // Extract durations using Web Audio API
        selectedFiles.forEach((file, index) => {
            getAudioDuration(file)
                .then(duration => {
                    setFileStatuses(prevStatuses => {
                        const updatedStatuses = [...prevStatuses];
                        updatedStatuses[index] = {
                            ...updatedStatuses[index],
                            duration: duration.toFixed(2), // Duration in seconds
                        };
                        return updatedStatuses;
                    });
                    setFileStatusesCopy(prevStatuses => {
                        const updatedStatuses = [...prevStatuses];
                        updatedStatuses[index] = {
                            ...updatedStatuses[index],
                            duration: duration.toFixed(2), // Duration in seconds
                        };
                        return updatedStatuses;
                    });
                })
                .catch(() => {
                    setFileStatuses(prevStatuses => {
                        const updatedStatuses = [...prevStatuses];
                        updatedStatuses[index] = {
                            ...updatedStatuses[index],
                            duration: 'Error',
                        };
                        return updatedStatuses;
                    });
                    setFileStatusesCopy(prevStatuses => {
                        const updatedStatuses = [...prevStatuses];
                        updatedStatuses[index] = {
                            ...updatedStatuses[index],
                            duration: 'Error',
                        };
                        return updatedStatuses;
                    });
                });
        });
        setUploadButtonDisable(false);
    };

    // Function to get audio duration using Web Audio API
    const getAudioDuration = file => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsArrayBuffer(file);

            reader.onload = () => {
                const audioContext = new (window.AudioContext ||
                    window.webkitAudioContext)();

                audioContext
                    .decodeAudioData(reader.result)
                    .then(buffer => resolve(buffer.duration))
                    .catch(error => {
                        console.error('Decoding Error:', error);
                        reject('Decoding Failed');
                    });
            };

            reader.onerror = () => reject('File Read Error');
        });
    };

    const handleAudioUpload = async () => {
        fileUploadedRef.current = true
        const NewfiletoUpload = files?.filter(el => {
            if (fileStatusesCopy?.some(file => file.fileName == el?.name)) {
                return el;
            }
        });
        if (files.length === 0) {
            ErrorNotify(t('Bulk Audio Upload.Please select files to upload'));
            return;
        }

        const supportedFileType = ['audio'];
        const invalidFiles = files.filter(
            file => !supportedFileType.includes(file.type.split('/')[0])
        );

        if (invalidFiles.length > 0) {
            ErrorNotify(t('Bulk Audio Upload.Please upload only valid audio files'));
            return;
        }

        setUploading(true);

        const updatedStatuses = [...fileStatusesCopy];

        try {
            for (let i = 0; i < NewfiletoUpload.length; i++) {
                const file = NewfiletoUpload[i];
                const formData = new FormData();
                formData.append('files', file);
                formData.append('path', selectedDepartment?.Department_Code);

                updatedStatuses[i] = {
                    ...updatedStatuses[i],
                    status: t('Bulk Audio Upload.Uploading'),
                };
                setFileStatusesCopy([...updatedStatuses]);

                const res = await client.post('/upload', formData, {
                    headers: { 'Content-Type': 'multipart/form-data' },
                    onUploadProgress: progressEvent => {
                        const total = progressEvent.total || 1;
                        const percentage = Math.round(
                            (progressEvent.loaded / total) * 100
                        );
                        updatedStatuses[i] = {
                            ...updatedStatuses[i],
                            progress: percentage,
                        };
                        setFileStatusesCopy([...updatedStatuses]);
                    },
                });
                const payload = {
                    employee_id: selectedAgent?.value || '',
                    call_datetime: new Date().toISOString(),
                    public_link: res?.data?.data?.[0]?.url,
                    duration: +fileStatusesCopy?.[i]?.duration || 100,
                };

                const response = await client.post(
                    `/dialler/webhook/${selectedDepartment?.Department_Code}/perfectpitch`,
                    {
                       ...payload,
                    }
                );
                updatedStatuses[i] = {
                    ...updatedStatuses[i],
                    progress: 100,
                    status: t('Bulk Audio Upload.Completed'),
                };
                setFileStatusesCopy([...updatedStatuses]);
            }
        } catch (error) {
            const failedFileIndex = updatedStatuses.findIndex(
                status => status.status === t('Bulk Audio Upload.Uploading')
            );
            if (failedFileIndex !== -1) {
                updatedStatuses[failedFileIndex] = {
                    ...updatedStatuses[failedFileIndex],
                    status: t('Bulk Audio Upload.Failed'),
                };
                setFileStatusesCopy([...updatedStatuses]);
            }
            ErrorNotify(`${t('Bulk Audio Upload.File upload failed')}: ${error.message}`);
        } finally {
            setUploadButtonDisable(true);
            setUploading(false);
            setFiles([]);
            setfileUploaded(true);
        }
    };

    function FilterDurationOnType(
        type,
        val1,
        val2,
        fileStatuses,
        selectedFileType
    ) {
        const duration1 = +val1 * 60;
        const duration2 = +val2 ? val2 * 60 : null;

        const filteredFiles = fileStatuses?.filter(el => {
            const fileDuration = +el.duration;
            const fileTypeMatches = selectedFileType?.[0]?.value
                ? selectedFileType?.map(el => el.value)?.includes(el.fileType)
                : true;
            if (type) {
                switch (type) {
                    case 'LessThan':
                        return fileDuration < duration1 && fileTypeMatches;

                    case 'LessThanEqualTo':
                        return fileDuration <= duration1 && fileTypeMatches;

                    case 'GreaterThan':
                        return fileDuration > duration1 && fileTypeMatches;

                    case 'GreaterThanEqualTo':
                        return fileDuration >= duration1 && fileTypeMatches;

                    case 'EqualTo':
                        return fileDuration === duration1 && fileTypeMatches;

                    case 'Between':
                        return duration2 !== null
                            ? fileDuration >= duration1 &&
                                  fileDuration <= duration2 &&
                                  fileTypeMatches
                            : false;

                    default:
                        return true;
                }
            } else {
                return fileTypeMatches;
            }
        });
        return filteredFiles;
    }

    const handleFormatType = selectedOption => {
        setSelectedFileType(selectedOption);
        selectedFileTypeRef.current = selectedOption;
        const filteredFiles = FilterDurationOnType(
            selectedValueRef.current,
            minValueRef.current,
            maxValueRef.current,
            fileStatuses,
            selectedOption
        );

        setFileStatusesCopy(filteredFiles);
    };

    const fetchAgent = async deptId => {
        const agents = await client.get(
            `/users?page=1&pageSize=1000&sort=username:ASC&filters[organisations]={{org-id}}&filters[role_map][departments]=${deptId}`
        );
        let finalAgent;
        if (agents?.success) {
            finalAgent = agents?.data?.map(agent => ({
                label: agent?.email,
                value: agent?.unique_id,
            }));
            setLoadingAgent(false);
        } else {
            setLoadingAgent(true);
        }
        setAgentList(finalAgent);
    };

    const handleObsChange = dept => {
        setSelectedDepartment(dept);
        fetchAgent(dept?.value);
        if (!dept?.value) {
            setSelectedAgent('');
        }
    };

    const toggleDropdown = () => {
        setShowDropdown(!showDropdown);
    };

    const handleSelect = value => {
        setSelectedValue(value);
        selectedValueRef.current = value;
    };

    useEffect(() => {
        const handleClickOutside = event => {
            if (
                dropdownRef.current &&
                !dropdownRef.current.contains(event.target)
            ) {
                if (
                    selectedValueRef.current &&
                    !formatTypeRef.current.contains(event.target) &&
                    !agentListRef.current.contains(event.target) &&
                    !obsRef.current.contains(event.target) &&
                    !submitButtonRef.current.contains(event.target)
                ) {
                    const filteredFiles = FilterDurationOnType(
                        selectedValueRef.current,
                        minValueRef.current,
                        maxValueRef.current,
                        fileStatuses,
                        selectedFileTypeRef.current
                    );
                    if(!fileUploadedRef.current){
                        setFileStatusesCopy(filteredFiles);
                    }
                }
                setShowDropdown(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [fileStatuses, selectedValue]);

    const handleMinChanges = e => {
        minValueRef.current = e.target.value;
        setMinValue(e.target.value);
    };
    const handleMaxChanges = e => {
        maxValueRef.current = e.target.value;
        setMaxValue(e.target.value);
    };
    return (
        <>
            {/* new start */}
            <div className='page-wrapper'>
                <div className='content pb-0'>
                    <div className='row'>
                        <div className='col-sm-12 content_wrap_style'>
                            <div className='content_wrap_comman'>
                                <div className='page_title_box two_column_title' ref={submitButtonRef}>
                                    <h3 className='page_title'>
                                        <span class='pe-3'>
                                            <button class='btn btn_transparent' onClick={()=> navigate(
                                                            `/o/${orgStrId}/setup`
                                                        )}>
                                                <img
                                                    src='/images/back_arrow.svg'
                                                    alt='back button'
                                                />
                                            </button>
                                        </span>
                                        {t('Bulk Audio Upload.Upload Audio Files')}
                                    </h3>
                                    <button
                                    
                                        onClick={handleAudioUpload}
                                        disabled={
                                            uploading ||
                                            !selectedDepartment?.value ||
                                            files.length === 0 ||
                                            uploadButtonDisable || fileStatusesCopy?.length == 0
                                        }
                                        className='btn comman_btn'>
                                        {uploading ? t('Bulk Audio Upload.Submitting') : t('Common.Submit')}
                                    </button>
                                </div>

                                {/*  */}
                                <form className='form_style_comman mb-1'>
                                    <div className='row'>
                                        <div className='col-lg-4 col-sm-6 form-group'>
                                            <label>{t('Bulk Audio Upload.File Upload')}</label>
                                            <input
                                                id="file-upload"
                                                type='file'
                                                multiple
                                                accept='audio/*'
                                                onChange={handleFileChange}
                                                className='form-control'
                                                disabled = {uploading || fileUploaded}
                                                placeholder=''
                                                // style={{opacity: files?.length === 0 ? 0 : 1}}
                                            />
                                        </div>
                                        <div className='col-lg-4 col-sm-6 form-group'>
                                            <div
                                                ref={dropdownRef}
                                                className='custom_duration_inp_box'>
                                                <label>{t('Bulk Audio Upload.Duration')}</label>
                                                <div
                                                    className='custom_duration_inp_only'
                                                    onClick={toggleDropdown}>
                                                    <input
                                                        type='text'
                                                        className='form-control custom_duration_inp'
                                                        value={selectedValue ? t(`Bulk Audio Upload.${selectedValue}`) : selectedValue}
                                                        onClick={toggleDropdown}
                                                        readOnly // Prevent manual input
                                                        placeholder={t('Bulk Audio Upload.Select condition')}
                                                        disabled={
                                                            (files.length === 0 || uploading)
                                                                ? true
                                                                : false
                                                        }
                                                    />
                                                </div>

                                                {/* Dropdown list */}
                                                {showDropdown && (
                                                    <div className='custom_duration_ddn'>
                                                        <ul
                                                            style={{
                                                                listStyle:
                                                                    'none',
                                                                padding: 0,
                                                                margin: 0,
                                                            }}>
                                                            <li
                                                                onClick={() =>
                                                                    handleSelect(
                                                                        'LessThan'
                                                                    )
                                                                }>
                                                                <Form.Check>
                                                                    <Form.Check.Input
                                                                        type='radio'
                                                                        name='radios'
                                                                        id='exampleRadio1'
                                                                        checked={
                                                                            selectedValue ===
                                                                            'LessThan'
                                                                        }
                                                                    />
                                                                    <Form.Check.Label htmlFor='exampleRadio1'>
                                                                        {t('Bulk Audio Upload.Less Than')}
                                                                    </Form.Check.Label>
                                                                </Form.Check>
                                                            </li>
                                                            <li
                                                                onClick={() =>
                                                                    handleSelect(
                                                                        'LessThanEqualTo'
                                                                    )
                                                                }>
                                                                <Form.Check>
                                                                    <Form.Check.Input
                                                                        type='radio'
                                                                        name='radios'
                                                                        id='exampleRadio2'
                                                                        checked={
                                                                            selectedValue ===
                                                                            'LessThanEqualTo'
                                                                        }
                                                                    />
                                                                    <Form.Check.Label htmlFor='exampleRadio2'>
                                                                        {t('Bulk Audio Upload.Less than equal to')}
                                                                    </Form.Check.Label>
                                                                </Form.Check>
                                                            </li>
                                                            <li
                                                                onClick={() =>
                                                                    handleSelect(
                                                                        'GreaterThan'
                                                                    )
                                                                }>
                                                                <Form.Check>
                                                                    <Form.Check.Input
                                                                        type='radio'
                                                                        name='radios'
                                                                        id='exampleRadio3'
                                                                        checked={
                                                                            selectedValue ===
                                                                            'GreaterThan'
                                                                        }
                                                                    />
                                                                    <Form.Check.Label htmlFor='exampleRadio3'>
                                                                        {t('Bulk Audio Upload.Greater Than')}
                                                                    </Form.Check.Label>
                                                                </Form.Check>
                                                            </li>
                                                            <li
                                                                onClick={() =>
                                                                    handleSelect(
                                                                        'GreaterThanEqualTo'
                                                                    )
                                                                }>
                                                                <Form.Check>
                                                                    <Form.Check.Input
                                                                        type='radio'
                                                                        name='radios'
                                                                        id='exampleRadio4'
                                                                        checked={
                                                                            selectedValue ===
                                                                            'GreaterThanEqualTo'
                                                                        }
                                                                    />
                                                                    <Form.Check.Label htmlFor='exampleRadio4'>
                                                                        {t('Bulk Audio Upload.Greater Tha Equals To')}
                                                                    </Form.Check.Label>
                                                                </Form.Check>
                                                            </li>
                                                            <li
                                                                onClick={() =>
                                                                    handleSelect(
                                                                        'EqualTo'
                                                                    )
                                                                }>
                                                                <Form.Check>
                                                                    <Form.Check.Input
                                                                        type='radio'
                                                                        name='radios'
                                                                        id='exampleRadio5'
                                                                        checked={
                                                                            selectedValue ===
                                                                            'EqualTo'
                                                                        }
                                                                    />
                                                                    <Form.Check.Label htmlFor='exampleRadio5'>
                                                                        {t('Bulk Audio Upload.Equals To')}
                                                                    </Form.Check.Label>
                                                                </Form.Check>
                                                            </li>
                                                            <li
                                                                onClick={() =>
                                                                    handleSelect(
                                                                        'Between'
                                                                    )
                                                                }>
                                                                <Form.Check>
                                                                    <Form.Check.Input
                                                                        type='radio'
                                                                        name='radios'
                                                                        id='exampleRadio6'
                                                                        checked={
                                                                            selectedValue ===
                                                                            'Between'
                                                                        }
                                                                    />
                                                                    <Form.Check.Label htmlFor='exampleRadio6'>
                                                                        {t('Bulk Audio Upload.Between')}
                                                                    </Form.Check.Label>
                                                                </Form.Check>
                                                            </li>
                                                        </ul>
                                                        {selectedValue ===
                                                        'Between' ? (
                                                            <div className='ddn_value_between_box d-flex align-items-center'>
                                                                <input
                                                                    type='text'
                                                                    className='form-control'
                                                                    placeholder={t('Bulk Audio Upload.Min (mins)')}
                                                                    value={
                                                                        minValue
                                                                    }
                                                                    onChange={
                                                                        handleMinChanges
                                                                    }
                                                                />
                                                                <div className='ddn_dash_icon'>
                                                                    <img
                                                                        src='/images/dash_icon.svg'
                                                                        alt='dash'
                                                                    />
                                                                </div>
                                                                <input
                                                                    type='text'
                                                                    className='form-control'
                                                                    placeholder={t('Bulk Audio Upload.Max (mins)')}
                                                                    value={
                                                                        maxValue
                                                                    }
                                                                    onChange={
                                                                        handleMaxChanges
                                                                    }
                                                                />
                                                            </div>
                                                        ) : (
                                                            <input
                                                                type='text'
                                                                className='form-control'
                                                                placeholder={t('Bulk Audio Upload.mins')}
                                                                value={minValue}
                                                                onChange={
                                                                    handleMinChanges
                                                                }
                                                            />
                                                        )}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        <div
                                            className='col-lg-4 col-sm-6  form-group'
                                            ref={formatTypeRef}>
                                            <label htmlFor=''>
                                                {t('Bulk Audio Upload.Format Type')}
                                            </label>
                                            <Select
                                                options={fileTypeOptions.map(
                                                    des => ({
                                                        value: des.value,
                                                        label: des?.label,
                                                    })
                                                )}
                                                defaultValue={selectedFileType}
                                                onChange={selectedOption => {
                                                    handleFormatType(
                                                        selectedOption
                                                    );
                                                }}
                                                value={selectedFileType}
                                                isSearchable
                                                placeholder={t('Common.Select')}
                                                isDisabled={
                                                    (files.length === 0 || uploading)
                                                        ? true
                                                        : false
                                                }
                                                isMulti
                                            />
                                        </div>
                                        {/*  */}
                                        <div
                                            className='col-lg-4 col-sm-6 form-group'
                                            ref={obsRef}>
                                            <label>
                                                {t('Common.OBS')}{' '}
                                                <sup className='sup_manatory text-danger'>
                                                    *
                                                </sup>
                                            </label>
                                            <ObsStructure
                                                selectedDepartment={
                                                    selectedDepartment
                                                }
                                                setSelectedDepartment={
                                                    handleObsChange
                                                }
                                                selectPlaceholder={true}
                                                disabled = {uploading || (files.length === 0 ? true : false)}
                                            />
                                        </div>

                                        <div
                                            className='col-lg-4 col-sm-6 form-group'
                                            ref={agentListRef}>
                                            <label>{t('Bulk Audio Upload.Agent List')}</label>
                                            <Select
                                                options={agentList?.map(
                                                    des => ({
                                                        value: des.value,
                                                        label: des?.label,
                                                    })
                                                )}
                                                defaultValue={selectedAgent}
                                                onChange={selectedOption => {
                                                    setSelectedAgent(
                                                        selectedOption
                                                    );
                                                }}
                                                value={selectedAgent}
                                                isSearchable
                                                placeholder={t('Common.Select')}
                                                isDisabled={loadingAgent || uploading || files.length === 0}
                                            />
                                        </div>
                                    </div>
                                </form>
                                {/*  */}
                                {/* no files start */}
                                {fileStatuses?.length > 0 ? (
                                    <div className='table_style_comman'>
                                        <div className='table-responsive'>
                                            <table className='table table-bordered'>
                                                <thead>
                                                    <tr>
                                                        <th>
                                                            {t('Bulk Audio Upload.File Name')}{' '}
                                                            {` ( ${fileStatusesCopy?.length} )`}
                                                        </th>
                                                        <th>{t('Bulk Audio Upload.Duration')} ({t('Bulk Audio Upload.seconds')})</th>
                                                        <th>{t('Bulk Audio Upload.Progress')} (%)</th>
                                                        <th>{t('Bulk Audio Upload.Status')}</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {fileStatusesCopy.map(
                                                        (fileStatus, index) => (
                                                            <tr key={index}>
                                                                <td>
                                                                    {
                                                                        fileStatus.fileName
                                                                    }
                                                                </td>
                                                                <td>
                                                                    {fileStatus.duration
                                                                        ? fileStatus.duration !==
                                                                        'Error'
                                                                            ? `${fileStatus.duration} s`
                                                                            : t('Bulk Audio Upload.Error')
                                                                        : `${t('Bulk Audio Upload.Loading')} ...`}
                                                                </td>
                                                                <td>
                                                                    {
                                                                        fileStatus.progress
                                                                    }
                                                                    %
                                                                </td>
                                                                <td>
                                                                    {
                                                                        fileStatus.status
                                                                    }
                                                                </td>
                                                            </tr>
                                                        )
                                                    )}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                ) : (
                                    <div
                                        class='empty-element '
                                        style={{ padding: '20px 0' }}>
                                        <div class='img-block'>
                                            <img
                                                src='/images/no_files_empty_img.svg'
                                                alt=''
                                            />
                                            <input
                                                type='file'
                                                multiple
                                                accept='audio/*'
                                                onChange={handleFileChange}
                                                className='form-control'
                                                style={{position: "relative", top: "-85px", height: "90px", opacity: 0, marginBottom: "-85px"}}
                                            />
                                        </div>
                                        <h5>{t('Bulk Audio Upload.No Audio Files')}</h5>
                                        <p>
                                            {t('Bulk Audio Upload.Select department and upload audios from your local computer.')}
                                        </p>
                                    </div>
                                )}

                                {/* no files end */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default BulkUpload;
