export const transformQAData = (data = []) => {
    const categories = data.map(item => item?.["QA.client_name"] || "Unknown Client");
    const qaData = data.map(item => parseInt(item?.["QA.total_qas"], 10) || 0);
    const totalCount = qaData.reduce((sum, currentValue) => sum + currentValue, 0);
    return {
      data: qaData,
      categories: categories,
      totalCount: totalCount,
    };
  };
  
  export const transformAgentData = (data = []) => {
    const categories = data.map(item => item?.["Agent.client_name"] || "Unknown Client");
    const agentData = data.map(item => parseInt(item?.["Agent.total_agents"], 10) || 0);
    const totalCount = agentData.reduce((sum, currentValue) => sum + currentValue, 0);
    return {
      data: agentData,
      categories: categories,
      totalCount: totalCount,
    };
  };
  
  export const transformDepartmentData = (data = []) => {
    const categories = data.map(item => item?.["Agent.client_name"] || "Unknown Client");
    const departmentData = data.map(item => parseInt(item?.["Agent.total_departments"], 10) || 0);
    const totalCount = departmentData.reduce((sum, currentValue) => sum + currentValue, 0);
    return {
      data: departmentData,
      categories: categories,
      totalCount: totalCount,
    };
  };
  
  export const formatData = (data = []) => {
    const formatDate = (dateString) => {
      if (!dateString) return "Invalid Date";
      const date = new Date(dateString);
      const options = { day: "2-digit", month: "short" };
      const startDate = new Date(date);
      const endDate = new Date(date);
      endDate.setDate(startDate.getDate() + 6);
      return `${startDate.toLocaleDateString("en-GB", options)}-${endDate.toLocaleDateString("en-GB", options)}`;
    };
  
    return data.reduce((acc, row) => {
      const report_month = formatDate(row?.["Agent.report_month"]);
      const department_name = row?.["Agent.department_name"] || "Unknown Department";
      const trendValue = row?.["Agent.total_fatal_count"] || 0
  
      if (!acc[report_month]) {
        acc[report_month] = [];
      }
  
      acc[report_month].push({ [department_name]: trendValue });
      return acc;
    }, {});
  };
  
  export const transformDataForChart = (formattedData = {}) => {
    const categories = Object.keys(formattedData);
  
    const allDepartmentNames = Array.from(
      new Set(
        Object.values(formattedData).flatMap((weekData) =>
          weekData.map((entry) => Object.keys(entry)[0])
        )
      )
    );
  
    const data = allDepartmentNames.map((clientName) => {
      const clientData = categories.map((week) => {
        const weekData = formattedData[week]?.find((entry) => entry[clientName] !== undefined);
        return weekData ? parseFloat(weekData[clientName]) : null;
      });
  
      return {
        name: clientName,
        data: clientData || [],
      };
    }) || [];
  
    return { data, categories };
  };
  
  export const numberOfProgramsByClient = (categories = [], series = []) => {
    return {
        options: {
            chart: {
                type: 'bar',
                height: 150,
                toolbar: {
                    show: false,
                },
            },
            //colors: colors,
            colors: '#126E36',
            plotOptions: {
                bar: {
                    horizontal: false,
                    columnWidth: '30%',
                    dataLabels: {
                        position: 'top',
                    },
                },
            },
            dataLabels: {
                enabled: false,
                offsetX: -6,
                style: {
                    fontSize: '12px',
                },
            },
            stroke: {
                show: true,
                width: 0,
            },
            tooltip: {
                enabled: true,
            },
            xaxis: {
                categories: categories,
                title: {
                    text: 'Client',
                    style: {
                        fontFamily: 'Inter, sans-serif',
                        fontSize: '12px',
                        fontWeight: 400,
                        lineHeight: '13px',
                        color: '#828282',
                    },
                },
            },
            yaxis: {
                title: {
                    text: 'Count',
                    style: {
                        fontFamily: 'Inter, sans-serif',
                        fontSize: '12px',
                        fontWeight: 400,
                        lineHeight: '13px',
                        color: '#828282',
                    },
                },
            },
        },
        series: [
            {
                data: series,
                name: 'Audit Done',
            },
        ],
    };
  }