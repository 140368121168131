import React, { useState, useEffect } from 'react';
import Card from '../../../../bootstrap/card';
import { useNavigate } from 'react-router-dom';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import WorldMap from '../../../Widgets/WorldMap';
import TableWithBar from '../../../Widgets/TableWithBar';
import DonutChartWithScorePercentage from '../../../Widgets/DonutChartWithScorePercentage';
import LineChartComponent from '../../../Widgets/MultiLineGraph';
import { useAnalyticsContext } from '../../../AnalyticsContext';
import { fetchAgentMetrics, fetchAgentsByClientData, fetchAgentsByLocationData, fetchClientWiseAuditCount, fetchClientWiseAuditScore, fetchCountryWiseAuditMetrics, fetchDepartmentsByClientData, fetchDepartmentWiseAuditScore, fetchLocationWiseAuditScore, fetchQAByClientData, fetchQAByLocationData, fetchQAMetrics, fetchWowTrend } from './service/PageData/Page';
import Filter from '../../../Filter';
const CountryDashboard = () => {

    const { analyticsData, handleBackClick, setFilterData } = useAnalyticsContext()
    const { dateTimeRange, selectedFilters, baseURL, dateLabel, dateParameter} = analyticsData

    const [total_audits, setTotalAudits] = useState(0)
    const [averageAuditScore, setAverageAuditScore] = useState(0)
    const [totalAgents, settotalAgents] = useState(0)
    const [totalQa, setTotalQa] = useState(0)
    
    const [clientWiseAuditScore, setClientWiseAuditScore] = useState([])
    const [locationWiseAuditScore, setLocationWiseAuditScore] = useState([])
    const [wowTrend, setWowTrend] = useState({data: [], categories: []})
    const [totalAgentsByLocation, setTotalAgentsByLocation] = useState([])
    const [totalQasByLocation, setTotalQasByLocation] = useState([])
    const [totalDepartmentsByClient, setTotalDepartmentsByClient] = useState([])
    const [locationWiseAuditCount, setLocationWiseAuditCount] = useState([])
    const [departmentWiseAuditCount, setDepartmentWiseAuditCount] = useState([])
    const [dateParameterTimeFrame, setDateParameterTimeFrame] = useState('Day')
    const navigate = useNavigate();

    const department_redirection = (id, name) => {
        setFilterData('department', {id: id, name: name})
        navigate(`${baseURL}/program-dashboard/${id}`);
    }

    const ticket_redirection = () => {
        navigate(`${baseURL}/ticket-wise-dashboard`);
    }

    useEffect(() => {
        if(dateLabel == 'DOD'){
            setDateParameterTimeFrame('Day')
        }else if(dateLabel == 'WOW'){
            setDateParameterTimeFrame('Week')
        }else {
            setDateParameterTimeFrame('Month')
        }
        fetchAgentMetrics(settotalAgents, setAverageAuditScore, setTotalAudits, dateTimeRange, selectedFilters['sbu']?.id, selectedFilters['geography']?.id, selectedFilters['client']?.id, selectedFilters['country']?.id)
        fetchQAMetrics(setTotalQa, dateTimeRange, selectedFilters['sbu']?.id, selectedFilters['geography']?.id, selectedFilters['client']?.id, selectedFilters['country']?.id)
        fetchLocationWiseAuditScore(setLocationWiseAuditCount, dateTimeRange, selectedFilters['sbu']?.id, selectedFilters['geography']?.id, selectedFilters['client']?.id, selectedFilters['country']?.id)
        fetchDepartmentWiseAuditScore(setDepartmentWiseAuditCount, dateTimeRange, selectedFilters['sbu']?.id, selectedFilters['geography']?.id, selectedFilters['client']?.id, selectedFilters['country']?.id)
        fetchAgentsByLocationData(setTotalAgentsByLocation, dateTimeRange, selectedFilters['sbu']?.id, selectedFilters['geography']?.id, selectedFilters['client']?.id, selectedFilters['country']?.id);
        fetchQAByLocationData(setTotalQasByLocation, dateTimeRange, selectedFilters['sbu']?.id, selectedFilters['geography']?.id, selectedFilters['client']?.id, selectedFilters['country']?.id);
      }, [dateTimeRange, selectedFilters]);

    return (
        <>
            <div className='page-wrapper'>
                <div className='content pb-0'>
                    <div className='row'>
                        <div className='col-sm-12 content_wrap_style'>
                            <div className='content_wrap_comman'>
                            <div className='page_title_box'>
                                    <h3 className='page_title'>
                                        <span className='pe-3'><button className='btn btn_transparent'>
                                            <img src='/images/back_arrow.svg' alt='back button' onClick={handleBackClick}/>
                                        </button></span>
                                        Country Dashboard
                                    </h3>
                                </div>
                                <Filter showDate showCountry/>
                                <div className='row'>
                                    <div className='col-lg-3 card_legends_box'>
                                        <div className='card_legends card_legends_clickable card w-100 text-center'  onClick={ticket_redirection}>
                                            <h4 className='mb-2'>{total_audits}</h4>
                                            <h6 className='mb-0'>
                                                Total Audits
                                            </h6>
                                        </div>
                                    </div>
                                    <div className='col-lg-3 card_legends_box'>
                                        <div className='card_legends card_legends_clickable card w-100 text-center'  onClick={ticket_redirection}>
                                            <h4 className='mb-2'>{`${parseFloat(averageAuditScore).toFixed(2)}%`}</h4>
                                            <h6 className='mb-0'>
                                                Avg Audit Score
                                            </h6>
                                        </div>
                                    </div>
                                    <div className='col-lg-3 card_legends_box'>
                                        <div className='card_legends card w-100 text-center'>
                                            <h4 className='mb-2'>{totalAgents}</h4>
                                            <h6 className='mb-0'>
                                                Total Agents Audits
                                            </h6>
                                        </div>
                                    </div>
                                    <div className='col-lg-3 card_legends_box'>
                                        <div className='card_legends card w-100 text-center'>
                                            <h4 className='mb-2'>{totalQa}</h4>
                                            <h6 className='mb-0'>
                                                Total QA who Audited
                                            </h6>
                                        </div>
                                    </div>
                                </div>
                                {/* graph */}
                                <div className='row'>
                                    <div className='col-lg-6 dash_graph dash_graph_height'>
                                    <Card>
                                            <Card.Header className='d-flex justify-content-between card_header'>
                                                <Card.Header.Title className='w-100'>
                                                    <div className='two_column_title'>
                                                        <h5>
                                                            Location Wise Avg
                                                            Audit Scores
                                                        </h5>
                                                    </div>
                                                </Card.Header.Title>
                                            </Card.Header>
                                            <Card.Body>
                                                <TableWithBar
                                                    data={locationWiseAuditCount.map(item => ({
                                                        score: item['Agent.average_audit_score'],
                                                        name: item['Agent.site_name'],
                                                        id: item['Agent.site_id']
                                                      }))}
                                                    header={['Location', 'Average Audit Score']}
                                                />
                                            </Card.Body>
                                        </Card>
                                    </div>
                                    <div className='col-lg-6 dash_graph dash_graph_height'>
                                        <Card>
                                            <Card.Header className='d-flex justify-content-between card_header'>
                                                <Card.Header.Title className='w-100'>
                                                    <div className='two_column_title'>
                                                        <h5>
                                                            Process Wise Avg
                                                            Audit Scores
                                                        </h5>
                                                    </div>
                                                </Card.Header.Title>
                                            </Card.Header>
                                            <Card.Body>
                                                <TableWithBar
                                                    rowOnClick={department_redirection}
                                                    data={departmentWiseAuditCount.map(item => ({
                                                        score: item['Agent.average_audit_score'],
                                                        name: item['Agent.department_name'],
                                                        id: item['Agent.department_id']
                                                      }))}
                                                    header={['Department', 'Average Audit Score']}
                                                />
                                            </Card.Body>
                                        </Card>
                                    </div>
                                    <div className='col-lg-6 dash_graph dash_graph_height'>
                                        <Card>
                                            <Card.Header className='d-flex justify-content-between card_header'>
                                                <Card.Header.Title className='w-100'>
                                                    <div className='two_column_title'>
                                                        <h5>
                                                            Total Agents By
                                                            Location
                                                        </h5>
                                                    </div>
                                                </Card.Header.Title>
                                            </Card.Header>
                                            <Card.Body>
                                                <DonutChartWithScorePercentage
                                                    data={totalAgentsByLocation?.data || []}
                                                    categories={totalAgentsByLocation?.categories || []}
                                                    totalCount={totalAgentsByLocation?.totalCount}
                                                />
                                            </Card.Body>
                                        </Card>
                                    </div>
                                    <div className='col-lg-6 dash_graph dash_graph_height'>
                                        <Card>
                                            <Card.Header className='d-flex justify-content-between card_header'>
                                                <Card.Header.Title className='w-100'>
                                                    <div className='two_column_title'>
                                                        <h5>
                                                            Total QA By Location
                                                        </h5>
                                                    </div>
                                                </Card.Header.Title>
                                            </Card.Header>
                                            <Card.Body
                                                className=''
                                                style={{ width: '400px' }}>
                                                <DonutChartWithScorePercentage
                                                    data={totalQasByLocation?.data || []}
                                                    categories={totalQasByLocation?.categories || []}
                                                    totalCount={totalQasByLocation?.totalCount}
                                                />
                                            </Card.Body>
                                        </Card>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default CountryDashboard;
