import React from 'react';
import { toolTipHOC } from "../../../../CommonLogic"

const scoreParameter = {
    "Yes, Partial, No, N/A": ["Yes", "PARTIAL", "No", "N/A"],
    "Excellent, Average, Fair, Needs Improvement, Unsatisfactory, N/A": ["Excellent", "Average", "Fair", "Needs Improvement", "Unsatisfactory", "N/A"],
    "5, 4, 3, 2, 1, N/A": ["5", "4", "3", "2", "1", "N/A"],
    "Compliant, Non-Compliant, N/A": ["Compliant", "Non-Compliant", "N/A"],
    "Complete, Incomplete, N/A": ["Complete", "Incomplete", "N/A"],
    "Pass, Fail, N/A": ["Pass", "Fail", "N/A"],
    "Observed, Not Observed, N/A": ["Observed", "Not Observed", "N/A"],
    "Met,Partially Met, Not Met, N/A": ["Met", "Partially Met", "Not Met", "N/A"],
    "Acceptable, Unacceptable, N/A": ["Acceptable", "Unacceptable", "N/A"],
    "Non-Fatal, Fatal, N/A": ["Non-Fatal", "Fatal", "N/A"],
    "Minor, Medium, Severe, Critical, N/A": ["Minor", "Medium", "Severe", "Critical", "N/A"],
    "No Coaching, Coaching, N/A": ["No Coaching", "Coaching", "N/A"]
}

export const scoreMapping = {
    "Yes": 1,
    "No": 0,
    "Partial": 0.5,
    "PARTIAL": 0.5,
    "Excellent": 1,
    "Average": 0.75,
    "Fair": 0.5,
    "Needs Improvement": 0.25,
    "Unsatisfactory": 0,
    "5": 1,
    "4": 0.75,
    "3": 0.5,
    "2": 0.25,
    "1": 0,
    "Compliant": 1,
    "Non-Compliant": 0,
    "Complete": 1,
    "Incomplete": 0,
    "Pass": 1,
    "Fail": 0,
    "Observed": 1,
    "Not Observed": 0,
    "Met": 1,
    "Not Met": 0,
    "Partially Met": 0.5,
    "Acceptable": 1,
    "Unacceptable": 0,
    "Fatal": 0,
    "Non-Fatal": 1,
    "Critical": 0,
    "Severe": 0.33,
    "Medium": 0.66,
    "Minor": 1,
    "Coaching": 0,
    "No Coaching": 1,
    "N/A": 0
};

const classMapping = {
    "Yes": "btn-outline-success",
    "No": "btn-outline-danger",
    "Partial": "btn-outline-partially",
    "PARTIAL": "btn-outline-partially",
    "Excellent": "btn-outline-success",
    "Average": "btn-outline-average",
    "Fair": "btn-outline-partially",
    "Needs Improvement": "btn-outline-improvement",
    "Unsatisfactory": "btn-outline-danger",
    "5": "btn-outline-success",
    "4": "btn-outline-average",
    "3": "btn-outline-partially",
    "2": "btn-outline-improvement",
    "1": "btn-outline-danger",
    "Compliant": "btn-outline-success",
    "Non-Compliant": "btn-outline-danger",
    "Complete": "btn-outline-success",
    "Incomplete": "btn-outline-danger",
    "Pass": "btn-outline-success",
    "Fail": "btn-outline-danger",
    "Observed": "btn-outline-success",
    "Not Observed": "btn-outline-danger",
    "Met": "btn-outline-success",
    "Not Met": "btn-outline-danger",
    "Partially Met": "btn-outline-partially",
    "Acceptable": "btn-outline-success",
    "Unacceptable": "btn-outline-danger",
    "Fatal": "btn-outline-danger",
    "Non-Fatal": "btn-outline-success",
    "Critical": "btn-outline-danger",
    "Severe": "btn-outline-improvement",
    "Medium": "btn-outline-average",
    "Minor": "btn-outline-success",
    "Coaching": "btn-outline-danger",
    "No Coaching": "btn-outline-success",
    "N/A": "btn-outline-secondary"
};

export function ButtonType(buttonKey, Parameter_Name, subparam, rowNum, handleResponse, handleFatalEarnedScore, isReport, auditStatus, isParameterEditable, EditablebeforeTatForButton) {
    const { Weightage, Is_Fatal, Subparameter_Name, Critical_Type, Status } = subparam?.attributes || subparam;

    const isEditable = auditStatus === "initialAudit" || (auditStatus === "rebuttalJourney" && isParameterEditable) || EditablebeforeTatForButton;
    const preventEdit = auditStatus === "agentView" || (auditStatus === "rebuttalJourney" && !isEditable);

    const handleInputChange = (e, type) => {
        if (isEditable) {
            handleResponse(Parameter_Name, Weightage, Is_Fatal, Critical_Type, Subparameter_Name, rowNum, e, isReport);
            if (auditStatus === "initialAudit" && handleFatalEarnedScore) {
                handleFatalEarnedScore(subparam, e);
            }
        } else if (preventEdit) {
            e.preventDefault();
        }
    };

    const performViewMap = {
        "N/A": "N/A",
        "Needs Improvement": "NI",
        "Unsatisfactory": "US",
        "Non-Compliant": "NC",
        "Incomplete": "IC",
        "Not Observed": "NO",
        "Partially Met": "PM",
        "Not Met": "NM",
        "Unacceptable": "UA",
        "Non-Fatal": "NF",
        "Minor": "Mi",
        "Medium": "Me",
        "No Coaching": "NC"
    };

    const performToolTipViewMap = {
        "PARTIAL": "Partial"
    }

    return (
        <>
            {(buttonKey && buttonKey !== "N/A" ? buttonKey.includes(",") ? scoreParameter[buttonKey] : scoreParameter[Object.keys(scoreParameter).filter(key =>
                key.includes(buttonKey)
            )[0]] : scoreParameter["Yes, Partial, No, N/A"]).map((value, idx) => {
                let isChecked = (subparam?.Status === value);
                return (
                    <React.Fragment key={value}>
                        <input
                            onChange={(e) => handleInputChange(e, value)}
                            value={value}
                            type="radio"
                            required
                            id={`${value}-${rowNum}`}
                            checked={auditStatus !== "initialAudit" ? isChecked : null}
                            name={Subparameter_Name}
                            className={idx == 0 ? "firstoption" : ""}
                        />
                        <label
                            className={`btn rounded-pill ${classMapping[value]}`}
                            htmlFor={`${value}-${rowNum}`}
                        >
                            {toolTipHOC(performViewMap[value] ?? value.substring(0, 1), performToolTipViewMap[value] ?? value, true, 'top')}
                        </label>
                    </React.Fragment>
                );
            })}
            <div className="invalid-feedback">Please choose an option</div>
        </>
    );
}


