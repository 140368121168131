import { getAgentSentimentPerSBU, getAgentWiseAverageAuditScore, getCustomerSentimentPerSBU, getDepartmentsByClient, getDepartmentWiseAuditScore, getOrganisationMetricsAgent, getOverallSentimentPerSBU, getQAWiseAverageAuditScore, getWOWTrendPerDepartment, getWOWTrendPerDepartmentAuditCount, getWOWTrendPerDepartmentFatalCount } from "../ApiService";
import { formatData, formatDataFatal, formatDataTotal, numberOfProgramsByClient, transformDataForChart } from "./utils";

export const fetchOrganisationMetrics = async (setAverageAuditScore, setTotalAudits, setTotalDepartments, setTotalFatals, setBusinessCriticalScore, setComplianceCriticalScore, setCustomerCriticalScore, dateTimeRange, client_id, department_id, sbu_id) => {
    try {
      let agentresult = await getOrganisationMetricsAgent(dateTimeRange, client_id, department_id, sbu_id);
      agentresult = agentresult[0]
      if (!agentresult || agentresult?.length < 1) {
        setAverageAuditScore(0);
        setTotalAudits(0);
        setBusinessCriticalScore(0);
        setComplianceCriticalScore(0);
        setCustomerCriticalScore(0);
        setTotalDepartments(0)
        setTotalFatals(0)
        return;
      }

      setTotalAudits(agentresult?.['Agent.total_reports'] || []);
      setAverageAuditScore(agentresult['Agent.average_audit_score']);
      setBusinessCriticalScore(agentresult['Agent.avg_business_critical'] || 0);
      setComplianceCriticalScore(agentresult['Agent.avg_compliance_critical'] || 0);
      setCustomerCriticalScore(agentresult['Agent.avg_customer_critical'] || 0);
      setTotalDepartments(agentresult['Agent.total_departments'] || 0)
      setTotalFatals(agentresult['Agent.reports_with_fatal'] || 0)
    } catch (err) {
      console.error('Error fetching data:', err);
    }
};

export const fetchWowTrend = async (setWowTrend, dateTimeRange, selectedClient, selectedDepartment, dateParameter, sbu_id) => {
    try {
        const result = await getWOWTrendPerDepartment(dateTimeRange, selectedClient, selectedDepartment, dateParameter, sbu_id);
        const formattedData = formatData(result, dateParameter);
        const wowTrendData = transformDataForChart(formattedData);
        setWowTrend(wowTrendData);
    } catch (err) {
        console.error('Error fetching Wow Trend:', err);
    }
  };

  export const fetchWowTrendAuditCount = async (setWowTrend, dateTimeRange, selectedClient, selectedDepartment, dateParameter, sbu_id) => {
    try {
        const result = await getWOWTrendPerDepartmentAuditCount(dateTimeRange, selectedClient, selectedDepartment, dateParameter, sbu_id);
        const formattedData = formatDataTotal(result, dateParameter);
        const wowTrendData = transformDataForChart(formattedData);
        setWowTrend(wowTrendData);
    } catch (err) {
        console.error('Error fetching Wow Trend:', err);
    }
  };

  export const fetchWowTrendFatalCount = async (setWowTrend, dateTimeRange, selectedClient, selectedDepartment, dateParameter, sbu_id) => {
    try {
        const result = await getWOWTrendPerDepartmentFatalCount(dateTimeRange, selectedClient, selectedDepartment, dateParameter, sbu_id);
        const formattedData = formatDataFatal(result, dateParameter);
        const wowTrendData = transformDataForChart(formattedData);
        setWowTrend(wowTrendData);
    } catch (err) {
        console.error('Error fetching Wow Trend:', err);
    }
    }

export const fetchAgentWise = async (setAgentWise, dateTimeRange, client_id, department_id, sbu_id) => {
    try {
        let result = await getAgentWiseAverageAuditScore(dateTimeRange, client_id, department_id, sbu_id)
        result = result.map((item) => {
            return {
                name: item['Agent.agent_name'],
                score: item['Agent.average_audit_score'],
                count: item['Agent.total_reports']
            }
        })
        setAgentWise(result)
    } catch (err) {
        console.error('Error fetching data:', err);
      }
}

  export const fetchQAWise = async (setQaWise, dateTimeRange, client_id, department_id, sbu_id) => {
    try {
        let result = await getQAWiseAverageAuditScore(dateTimeRange, client_id, department_id, sbu_id)
        result = result.map((item) => {
            return {
                name: item['QA.qa_name'],
                score: item['QA.average_audit_score'],
                count: item['QA.total_reports']
            }
        })
        setQaWise(result)
    } catch (err) {
        console.error('Error fetching data:', err);
      }
  }

  export const fetchSentimentData = async (setSentimentData, dateTimeRange, client_id, department_id, sbu_id) => {
    try {
        let overall = await getOverallSentimentPerSBU(dateTimeRange, client_id, department_id, sbu_id)
        let customer = await getCustomerSentimentPerSBU(dateTimeRange, client_id, department_id, sbu_id)
        let agent = await getAgentSentimentPerSBU(dateTimeRange, client_id, department_id, sbu_id)
        let overallSentiment_data = {
            departments: overall.map((item) => item['Agent.department_name']),
            positive: overall.map((item) => parseInt(item['Agent.reports_with_positive_sentiment'])),
            negative: overall.map((item) => parseInt(item['Agent.reports_with_negative_sentiment'])),
            neutral: overall.map((item) => parseInt(item['Agent.reports_with_neutral_sentiment']))
        }
        let customerSentiment_data = {
            departments: customer.map((item) => item['Agent.department_name']),
            positive: customer.map((item) => parseInt(item['Agent.reports_with_positive_customer_sentiment'])),
            negative: customer.map((item) => parseInt(item['Agent.reports_with_negative_customer_sentiment'])),
            neutral: customer.map((item) => parseInt(item['Agent.reports_with_neutral_customer_sentiment']))
        }
        let agentSentiment_data = {
            departments: agent.map((item) => item['Agent.department_name']),
            positive: agent.map((item) => parseInt(item['Agent.reports_with_positive_agent_sentiment'])),
            negative: agent.map((item) => parseInt(item['Agent.reports_with_negative_agent_sentiment'])),
            neutral: agent.map((item) => parseInt(item['Agent.reports_with_neutral_agent_sentiment']))
        }
        setSentimentData({
            overall: overallSentiment_data,
            customer: customerSentiment_data,
            agent: agentSentiment_data
        })
    } catch (err) {
        console.error('Error fetching data:', err);
    }
  }

  export const fetchDepartmentWise = async (setDepartmentWise, dateTimeRange, client_id, department_id, sbu_id) => {
    try {
        let result = await getDepartmentWiseAuditScore(dateTimeRange, client_id, department_id, sbu_id)
        result = result.map((item) => {
            return {
                id: item['Agent.department_id'],
                name: item['Agent.department_name'],
                score: item['Agent.average_audit_score'],
                count: item['Agent.total_reports']
            }
        })
        setDepartmentWise(result)
    } catch (err) {
        console.error('Error fetching data:', err);
      }
  }

  export const fetchNumberOfDepartmentsPerClient = async (setProgramsPerClient, dateTimeRange, client_id, sbu_id) => {
    try {
        let result = await getDepartmentsByClient(dateTimeRange, client_id, sbu_id)
        let categories = result.map((item) => item['Agent.client_name'])
        let series = result.map((item) => item['Agent.total_departments'])

        setProgramsPerClient(numberOfProgramsByClient(categories, series))
    } catch (err) {
        console.error('Error fetching data:', err);
      }
  }
