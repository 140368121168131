import React from 'react'

function Coaching() {
  return (
    <iframe
    src={`https://icoaching.vercel.app/`}
    // src={`${import.meta.env.VITE_KB_URL}?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJwZXJtaXNzaW9ucyI6eyJhZG1pbiI6dHJ1ZSwidmlldyI6dHJ1ZX0sIm9yZ19pZCI6IkFCQ0QtMDkxMSIsImNsaWVudCI6InJpc2V1cCIsImlhdCI6MTcyNzc2NTI0NywiZXhwIjoxODA1NTI1MjQ3fQ.mZwYPIGMrN8Ps1oSwe3KfVrwNTgtrKcqbckWzGaJnBw`}
    width={'100%'}
    height={'100%'}
    style={{
      border: '0px',
      marginTop: "85px",
      borderRadius: "7px",
    }}
  />
  )
}

export default Coaching
