export const transformAgentData = (data) => {
    const categories = data.map(item => item["Agent.sbu_name"]);
    const agentData = data.map(item => parseInt(item["Agent.total_agents"], 10));
    const totalCount = agentData.reduce((sum, currentValue) => sum + currentValue, 0);
    return { data: agentData, categories, totalCount };
};

export const transformQAData = (data) => {
    const categories = data.map(item => item["QA.sbu_name"]);
    const qaData = data.map(item => parseInt(item["QA.total_qas"], 10));
    const totalCount = qaData.reduce((sum, currentValue) => sum + currentValue, 0);
    return { data: qaData, categories, totalCount };
};

export const formatData = (data, dateParameter='report_week') => {
    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const options = { day: '2-digit', month: 'short' };
        const startDate = new Date(date);
        const endDate = new Date(date);
        let diff = 0;
        if(dateParameter=='report_week'){
            diff = 6
        } else if(dateParameter=='report_month'){
            diff = 29
        }else {
            return `${startDate.toLocaleDateString('en-GB', options)}`
        }
        endDate.setDate(startDate.getDate() + diff);
        return `${startDate.toLocaleDateString('en-GB', options)}-${endDate.toLocaleDateString('en-GB', options)}`;
    };

    return data.reduce((acc, row) => {
        const reportWeek = formatDate(row[`Agent.${dateParameter}`]);
        const sbuName = row['Agent.sbu_name'];
        const trendValue = parseFloat(row['Agent.wow_trend_of_audit_score']).toFixed(1);

        if (!acc[reportWeek]) acc[reportWeek] = [];
        acc[reportWeek].push({ [sbuName]: trendValue });

        return acc;
    }, {});
};

export const transformDataForChart = (formattedData) => {
    const categories = Object.keys(formattedData);
    const allSBUNames = Array.from(
        new Set(
            Object.values(formattedData).flatMap(weekData =>
                weekData.map(entry => Object.keys(entry)[0])
            )
        )
    );

    const data = allSBUNames.map(sbuName => ({
        name: sbuName,
        data: categories.map(week => {
            const weekData = formattedData[week].find(entry => entry[sbuName] !== undefined);
            return weekData ? weekData[sbuName] : null;
        }),
    }));

    return { data, categories };
};
