import React, { useState } from 'react';
import ApexChart from 'react-apexcharts';

const VerticalBarGraphWithColors = ({
    data,
    categories,
    xLabel,
    yLabel,
    showLabels = false
}) => {
    const chart2 = {
        options: {
            chart: {
                type: 'bar',
                height: 150,
                toolbar: {
                    show: false,
                },
            },
            plotOptions: {
                bar: {
                    horizontal: false,
                    columnWidth: '30%',
                    distributed: true, // Enable color distribution for individual bars
                    dataLabels: {
                        position: 'top',
                    },
                },
            },
            colors: ['#072B15', '#126E36', '#23CE6B'], // Array of colors for each bar
            dataLabels: {
                enabled: false,
                offsetX: -6,
                style: {
                    fontSize: '12px',
                },
            },
            stroke: {
                show: true,
                width: 0,
            },
            tooltip: {
                enabled: true,
            },
            xaxis: {
                categories: categories,
                title: {
                    text: xLabel,
                    style: {
                        fontFamily: 'Inter, sans-serif',
                        fontSize: '14px',
                        fontWeight: 400,
                        lineHeight: '13px',
                        color: '#344054',
                    },

                },
                labels: {show: showLabels}
            },
            yaxis: {
                title: {
                    text: yLabel,
                    style: {
                        fontFamily: 'Inter, sans-serif',
                        fontSize: '14px',
                        fontWeight: 400,
                        lineHeight: '13px',
                        color: '#344054',
                    },
                },
            },
            legend: {
                position: 'top',
                horizontalAlign: 'left',
                markers: {
                    width: 12,
                    height: 12,
                    radius: 12, // Makes the markers circular
                },
                itemMargin: {
                    horizontal: 10,
                    vertical: 5,
                },
            },
        },
        series: data
    };
    return (
        <ApexChart
            options={chart2.options}
            series={chart2.series}
            type='bar'
            height='350'
        />
    );
};

export default VerticalBarGraphWithColors;
