import React, { useEffect, useState } from 'react';
import Card from '../../../bootstrap/card';
import ApexChart from 'react-apexcharts';
import ReactApexChart from 'react-apexcharts';
import WordCloud from 'react-wordcloud';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file

import DateRangePickerComp from '../../../../views/modules/social/AuditSheet/DateRangePickerComp';
import { DateRangeFunction } from '../../../../CommonLogic';
import HorizontalBarWithoutAxis from '../../Widgets/HorizontalBarWithoutAxis';
import LineChartComponent from '../../Widgets/MultiLineGraph';
import TableWithBar from '../../Widgets/TableWithBar';
import SentimentChart from '../../Widgets/SentimentChart';
import DynamicTable from '../../Widgets/Table/TableWithSorting';
import HorizontalBarChartWithPer from '../../Widgets/HorizontalBarChartWithPer';
import ListView from '../../Widgets/ListView';
import Filter from '../../Filter';
import { fetchAgentWise, fetchDepartmentWise, fetchNumberOfDepartmentsPerClient, fetchOrganisationMetrics, fetchQAWise, fetchSentimentData, fetchWowTrend, fetchWowTrendAuditCount, fetchWowTrendFatalCount } from './service/PageData/Page';
import { useAnalyticsContext } from '../../AnalyticsContext';
import { numberOfProgramsByClient } from './service/PageData/utils';
import { useNavigate } from 'react-router-dom';
import VoiceOfCustomer from './VoiceOfCustomer'

const ClientDashboard = () => {
    const [total_audits, setTotalAudits] = useState(0)
    const [averageAuditScore, setAverageAuditScore] = useState(0)
    const [total_departments, setTotalDepartments] = useState(0)
    const [total_fatals, setTotalFatals] = useState(0)
    const [businessCriticalScore, setBusinessCriticalScore] = useState(0)
    const [complianceCriticalScore, setComplianceCriticalScore] = useState(0)
    const [customerCriticalScore, setCustomerCriticalScore] = useState(0)

    const [wowTrendAuditScore, setWowTrendAuditScore] = useState([])
    const [wowTrendFatalCount, setWowTrendFatalCount] = useState([])
    const [wowTrendAuditCount, setWowTrendAuditCount] = useState([])
    const [departmentWise, setDepartmentWise] = useState([])
    const [agentWise, setAgentWise] = useState([])
    const [qaWise, setQaWise] = useState([])
    const [programsPerClient, setProgramsPerClient] = useState(numberOfProgramsByClient())
    const [allSentimentData, setSentimentData] = useState({
        overall: {},
        customer: {},
        agent: {}
    })
    const { analyticsData, handleBackClick, setFilterData } = useAnalyticsContext()
    const { dateTimeRange, selectedFilters, baseURL, dateLabel, dateParameter } = analyticsData
    const navigate = useNavigate();
    const department_redirection = (id, name) => {
        setFilterData('department', {id: id, name: name})
        navigate(`${baseURL}/program-dashboard/${id}`);
    }

    const ticket_redirection = () => {
        navigate(`${baseURL}/ticket-wise-dashboard`);
    }
    const agent_redirection = (id, name) => {
        setFilterData('agent', {id: id, name: name})
        navigate(`${baseURL}/agents-dashboard/${id}`);
    }

    useEffect(() => {
        fetchOrganisationMetrics(setAverageAuditScore, setTotalAudits, setTotalDepartments, setTotalFatals, setBusinessCriticalScore, setComplianceCriticalScore, setCustomerCriticalScore, dateTimeRange, selectedFilters['client']?.id, selectedFilters['department']?.id, selectedFilters['sbu']?.id,)
        fetchNumberOfDepartmentsPerClient(setProgramsPerClient, dateTimeRange, selectedFilters['client']?.id, selectedFilters['sbu']?.id, )
        fetchAgentWise(setAgentWise, dateTimeRange, selectedFilters['client']?.id, selectedFilters['department']?.id, selectedFilters['sbu']?.id,);
        fetchQAWise(setQaWise, dateTimeRange, selectedFilters['client']?.id, selectedFilters['department']?.id, selectedFilters['sbu']?.id,);
        fetchDepartmentWise(setDepartmentWise, dateTimeRange, selectedFilters['client']?.id, selectedFilters['department']?.id, selectedFilters['sbu']?.id,)
        fetchSentimentData(setSentimentData, dateTimeRange, selectedFilters['client']?.id, selectedFilters['department']?.id, selectedFilters['sbu']?.id,);
        fetchWowTrend(setWowTrendAuditScore, dateTimeRange, selectedFilters['client']?.id, selectedFilters['department']?.id, dateParameter, selectedFilters['sbu']?.id,)
        fetchWowTrendAuditCount(setWowTrendAuditCount, dateTimeRange, selectedFilters['client']?.id, selectedFilters['department']?.id, dateParameter, selectedFilters['sbu']?.id,)
        fetchWowTrendFatalCount(setWowTrendFatalCount, dateTimeRange, selectedFilters['client']?.id, selectedFilters['department']?.id, dateParameter, selectedFilters['sbu']?.id,)
    }, [dateTimeRange, selectedFilters])


    // positive sentimate word cloud
    const PositiveSentimentWords = [
        { text: 'Efficient', value: 100 },
        { text: 'Helpful', value: 95 },
        { text: 'Accurate', size: 90 },
        { text: 'Simple', value: 88 },
        { text: 'Quick', value: 85 },
        { text: 'Reliable', value: 83 },
        { text: 'Professional', value: 80 },
        { text: 'Smooth', value: 78 },
        { text: 'Effective', value: 75 },
        { text: 'Friendly', value: 73 },
        { text: 'Convenient', value: 70 },
        { text: 'Clear', value: 68 },
        { text: 'Fast', value: 65 },
        { text: 'Great Service', value: 63 },
        { text: 'Quality', value: 60 },
    ];
    const PositiveSentimentOptions = {
        rotations: 2,
        rotationAngles: [-90, 0],
        fontSizes: [12, 50],
        spiral: 'archimedean', // Circular layout shape
        padding: 2, // Adjust padding between words for readability
        colors: [
            '#072B15',
            '#0B4522',
            '#126E36',
            '#1DB056',
            '#23CE6B',
            '#27E376',
            '#2FFF8E',
            '#84FFBC',
            '#4080d4',
            '#d978b4',
        ], // Add your custom colors here
        enableTooltip: false, // Disable tooltip
    };

    // negative sentimate word cloud
    const NegativeSentimentWords = [
        { text: 'Confusing', value: 100 },
        { text: 'Delay', value: 95 },
        { text: 'Difficult', value: 90 },
        { text: 'Long Wait', value: 88 },
        { text: 'Unclear', value: 85 },
        { text: 'Issue', value: 83 },
        { text: 'Problem', value: 80 },
        { text: 'Complicated', value: 78 },
        { text: 'Poor Support', value: 75 },
        { text: 'Error', value: 73 },
        { text: 'Troubleshoot', value: 70 },
        { text: 'Frustrating', value: 68 },
        { text: 'Incorrect', value: 65 },
        { text: 'Cancelled', value: 60 },
        { text: 'Expensive', value: 55 },
    ];
    const NegativeSentimentOptions = {
        rotations: 2,
        rotationAngles: [-90, 0],
        fontSizes: [12, 50],
        spiral: 'archimedean', // Circular layout shape
        padding: 2, // Adjust padding between words for readability
        colors: [
            '#7A271A',
            '#912018',
            '#B42318',
            '#D92D20',
            '#F04438',
            '#F97066',
            '#FDA29B',
            '#FECDCA',
            '#f576c5',
            '#fb8b6d',
        ], // Add your custom colors here
        enableTooltip: false, // Disable tooltip
    };

    //
    const PositiveSentimentBar = {
        labels: ['Pkg', 'Clean', 'Staff', 'Avail', 'Response'],
        values: [90, 770, 650, 470, 340],
        colors: ['#072B15', '#0B4522', '#126E36', '#1DB056', '#23CE6B'],
    };

    const NegativeSentimentBar = {
        labels: ['Service', 'Support', 'Speed', 'Quality', 'Price'],
        values: [450, 300, 500, 600, 700],
        colors: ['#7A271A', '#912018', '#B42318', '#D92D20', '#F04438'],
    };

    return (
        <>
            <div className='page-wrapper'>
                <div className='content pb-0'>
                    <div className='row'>
                        <div className='col-sm-12 content_wrap_style'>
                            <div className='content_wrap_comman'>
                            <div className='page_title_box'>
                                    <h3 className='page_title'>
                                        <span className='pe-3'><button className='btn btn_transparent' onClick={handleBackClick}>
                                            <img src='/images/back_arrow.svg' alt='back button' />
                                        </button></span>
                                        Client Dashboard
                                    </h3>
                                </div>
                                <Filter showDate showClient showDepartment />

                                {/* count */}
                                <div className='row'>
                                    <div className='col-lg-6'>
                                        <div className='row'>
                                            <div className='col-lg-6 card_legends_box client_card_only'>
                                                <div className='card_legends card_legends_clickable card w-100 text-center' onClick={ticket_redirection}>
                                                    <h4 className='mb-2'>
                                                    {`${parseFloat(averageAuditScore).toFixed(2)}%`}
                                                    </h4>
                                                    <h6 className='mb-0'>
                                                        Overall Audit Score
                                                    </h6>
                                                </div>
                                            </div>
                                            <div className='col-lg-6 card_legends_box client_card_only'>
                                                <div className='card_legends card_legends_clickable card w-100 text-center' onClick={ticket_redirection}>
                                                    <h4 className='mb-2'>
                                                    {total_audits}
                                                    </h4>
                                                    <h6 className='mb-0'>
                                                        Total Audits
                                                    </h6>
                                                </div>
                                            </div>
                                            <div className='col-lg-6 card_legends_box client_card_only'>
                                                <div className='card_legends card w-100 text-center'>
                                                    <h4 className='mb-2'>{total_departments}</h4>
                                                    <h6 className='mb-0'>
                                                        No. of Programs
                                                    </h6>
                                                </div>
                                            </div>
                                            <div className='col-lg-6 card_legends_box client_card_only'>
                                                <div className='card_legends card_legends_clickable card w-100 text-center' onClick={ticket_redirection}>
                                                    <h4 className='mb-2'>{total_fatals}</h4>
                                                    <h6 className='mb-0'>
                                                        Total Fatals
                                                    </h6>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-lg-6 dash_graph dash_graph_height'>
                                        <Card>
                                            <Card.Header className='d-flex justify-content-between card_header'>
                                                <Card.Header.Title className='w-100'>
                                                    <div className='two_column_title'>
                                                        <h5>
                                                            Number of Programs
                                                            By Client
                                                        </h5>
                                                    </div>
                                                </Card.Header.Title>
                                            </Card.Header>
                                            <Card.Body className='text-center'>
                                                <ApexChart
                                                    options={programsPerClient?.options}
                                                    series={programsPerClient?.series}
                                                    type='bar'
                                                    height='307'
                                                />
                                            </Card.Body>
                                        </Card>
                                    </div>

                                    <div className='col-lg-4 card_legends_box'>
                                        <div className='card_legends card_legends_clickable card w-100 text-center' onClick={ticket_redirection}>
                                            <h4 className='mb-2'>{`${parseFloat(businessCriticalScore).toFixed(2)}%`}</h4>
                                            <h6 className='mb-0'>
                                                Business Critical
                                            </h6>
                                        </div>
                                    </div>
                                    <div className='col-lg-4 card_legends_box'>
                                        <div className='card_legends card_legends_clickable card w-100 text-center' onClick={ticket_redirection}>
                                            <h4 className='mb-2'>{`${parseFloat(complianceCriticalScore).toFixed(2)}%`}</h4>
                                            <h6 className='mb-0'>
                                                Compliance Critical
                                            </h6>
                                        </div>
                                    </div>
                                    <div className='col-lg-4 card_legends_box'>
                                        <div className='card_legends card_legends_clickable card w-100 text-center' onClick={ticket_redirection}>
                                            <h4 className='mb-2'>{`${parseFloat(customerCriticalScore).toFixed(2)}%`}</h4>
                                            <h6 className='mb-0'>
                                                Customer Critical
                                            </h6>
                                        </div>
                                    </div>
                                </div>
                                {/* graph */}
                                <div className='row'>
                                    <div className='col-lg-6 dash_graph dash_graph_height'>
                                        <Card>
                                            <Card.Header className='d-flex justify-content-between card_header'>
                                                <Card.Header.Title className='w-100'>
                                                    <div className='two_column_title'>
                                                        <h5>
                                                            {`Avg Audit Score
                                                            - ${dateLabel}`}
                                                        </h5>
                                                    </div>
                                                </Card.Header.Title>
                                            </Card.Header>
                                            <Card.Body>
                                                <LineChartComponent
                                                    data={wowTrendAuditScore?.data}
                                                    categories={wowTrendAuditScore?.categories}
                                                    yLabel={"Average of Overall Audit Score"}
                                                />
                                            </Card.Body>
                                        </Card>
                                    </div>
                                    <div className='col-lg-6 dash_graph dash_graph_height'>
                                        <Card>
                                                <Card.Header className='d-flex justify-content-between card_header'>
                                                    <Card.Header.Title className='w-100'>
                                                        <div className='two_column_title'>
                                                            <h5>
                                                                Program wise Avg Audit
                                                                Score
                                                            </h5>
                                                        </div>
                                                    </Card.Header.Title>
                                                </Card.Header>
                                                <Card.Body>
                                                <TableWithBar
                                                        data={departmentWise}
                                                        header = {["Program Name", "Audits Count","Average of Overall Audit Score",]}
                                                        rowOnClick={department_redirection}
                                                    />
                                                </Card.Body>
                                            </Card>
                                    </div>
                                    <div className='col-lg-6 dash_graph dash_graph_height'>
                                        <Card>
                                            <Card.Header className='d-flex justify-content-between card_header'>
                                                <Card.Header.Title className='w-100'>
                                                    <div className='two_column_title'>
                                                        <h5>
                                                            Agent Wise Avg Audit
                                                            Score
                                                        </h5>
                                                    </div>
                                                </Card.Header.Title>
                                            </Card.Header>
                                            <Card.Body>
                                            <TableWithBar
                                                    data={agentWise}
                                                    header = {["Agent", "Audits Count","Average of Overall Audit Score",]}
                                                    rowOnClick={agent_redirection}
                                                />
                                            </Card.Body>
                                        </Card>
                                    </div>
                                    <div className='col-lg-6 dash_graph dash_graph_height'>
                                        <Card>
                                            <Card.Header className='d-flex justify-content-between card_header'>
                                                <Card.Header.Title className='w-100'>
                                                    <div className='two_column_title'>
                                                        <h5>
                                                            QA wise Avg Audit
                                                            Score
                                                        </h5>
                                                    </div>
                                                </Card.Header.Title>
                                            </Card.Header>
                                            <Card.Body>
                                            <TableWithBar
                                                    data={qaWise}
                                                    header = {["QA Name", "Audits Count","Average of Overall Audit Score",]}
                                                />
                                            </Card.Body>
                                        </Card>
                                    </div>
                                    <div className='col-lg-6 dash_graph dash_graph_height'>
                                        <Card>
                                            <Card.Header className='d-flex justify-content-between card_header'>
                                                <Card.Header.Title className='w-100'>
                                                    <div className='two_column_title'>
                                                        <h5>
                                                            Count of Audit -
                                                            Per Day
                                                        </h5>
                                                    </div>
                                                </Card.Header.Title>
                                            </Card.Header>
                                            <Card.Body>
                                            <LineChartComponent
                                                    data={wowTrendAuditCount?.data}
                                                    categories={wowTrendAuditCount?.categories}
                                                    yLabel={"Audit Count"}
                                                />
                                            </Card.Body>
                                        </Card>
                                    </div>
                                    <div className='col-lg-6 dash_graph dash_graph_height'>
                                        <Card>
                                            <Card.Header className='d-flex justify-content-between card_header'>
                                                <Card.Header.Title className='w-100'>
                                                    <div className='two_column_title'>
                                                        <h5>Fatal Count</h5>
                                                    </div>
                                                </Card.Header.Title>
                                            </Card.Header>
                                            <Card.Body>
                                            <LineChartComponent
                                                    data={wowTrendFatalCount?.data}
                                                    categories={wowTrendFatalCount?.categories}
                                                    yLabel={"Fatal Count"}
                                                />
                                            </Card.Body>
                                        </Card>
                                    </div>
                                    <VoiceOfCustomer/>
                                    {/* <div className='col-lg-6 dash_graph dash_graph_height'>
                                        <Card>
                                            <Card.Header className='d-flex justify-content-between card_header'>
                                                <Card.Header.Title className='w-100'>
                                                    <div className='two_column_title'>
                                                        <h5>
                                                            Overall Sentiment
                                                        </h5>
                                                    </div>
                                                </Card.Header.Title>
                                            </Card.Header>
                                            <Card.Body>
                                            <SentimentChart
                                                    xLabel={allSentimentData?.overall?.departments || []}
                                                    yLabel={
                                                        'Customer Sentiment'
                                                    }
                                                    data={[
                                                        {
                                                            name: 'Positive',
                                                            data: allSentimentData?.overall?.positive || [],
                                                        },
                                                        {
                                                            name: 'Neutral',
                                                            data: allSentimentData?.overall?.neutral || [],
                                                        },
                                                        {
                                                            name: 'Negative',
                                                            data: allSentimentData?.overall?.negative || [],
                                                        },
                                                    ]}
                                                />
                                            </Card.Body>
                                        </Card>
                                    </div>
                                    <div className='col-lg-6 dash_graph dash_graph_height'>
                                        <Card>
                                            <Card.Header className='d-flex justify-content-between card_header'>
                                                <Card.Header.Title className='w-100'>
                                                    <div className='two_column_title'>
                                                        <h5>
                                                            Customer Sentiment
                                                        </h5>
                                                    </div>
                                                </Card.Header.Title>
                                            </Card.Header>
                                            <Card.Body>
                                            <SentimentChart
                                                    xLabel={allSentimentData?.customer?.departments || []}
                                                    yLabel={
                                                        'Customer Sentiment'
                                                    }
                                                    data={[
                                                        {
                                                            name: 'Positive',
                                                            data: allSentimentData?.customer?.positive || [],
                                                        },
                                                        {
                                                            name: 'Neutral',
                                                            data: allSentimentData?.customer?.neutral || [],
                                                        },
                                                        {
                                                            name: 'Negative',
                                                            data: allSentimentData?.customer?.negative || [],
                                                        },
                                                    ]}
                                                />
                                            </Card.Body>
                                        </Card>
                                    </div>
                                    <div className='col-lg-6 dash_graph dash_graph_height'>
                                        <Card>
                                            <Card.Header className='d-flex justify-content-between card_header'>
                                                <Card.Header.Title className='w-100'>
                                                    <div className='two_column_title'>
                                                        <h5>Agent Sentiment</h5>
                                                    </div>
                                                </Card.Header.Title>
                                            </Card.Header>
                                            <Card.Body>
                                            <SentimentChart
                                                    xLabel={allSentimentData?.agent?.departments || []}
                                                    yLabel={
                                                        'Agent Sentiment'
                                                    }
                                                    data={[
                                                        {
                                                            name: 'Positive',
                                                            data: allSentimentData?.agent?.positive || [],
                                                        },
                                                        {
                                                            name: 'Neutral',
                                                            data: allSentimentData?.agent?.neutral || [],
                                                        },
                                                        {
                                                            name: 'Negative',
                                                            data: allSentimentData?.agent?.negative || [],
                                                        },
                                                    ]}
                                                />
                                            </Card.Body>
                                        </Card>
                                    </div>
                                    <div className='col-lg-6 dash_graph dash_graph_height'>
                                        <Card>
                                            <Card.Header className='d-flex justify-content-between card_header'>
                                                <Card.Header.Title className='w-100'>
                                                    <div className='two_column_title'>
                                                        <h5>
                                                            Date wise Sentiment
                                                            Analysis
                                                        </h5>
                                                    </div>
                                                </Card.Header.Title>
                                            </Card.Header>
                                            <Card.Body>
                                                <SentimentChart
                                                    xLabel={[
                                                        '06-Sept-24',
                                                        '05-Nov-24',
                                                        '02-Dec-24',
                                                    ]}
                                                    yLabel={'Count'}
                                                    data={[
                                                        {
                                                            name: 'Positive',
                                                            data: [74, 80, 90],
                                                        },
                                                        {
                                                            name: 'Neutral',
                                                            data: [30, 25, 20],
                                                        },
                                                        {
                                                            name: 'Negative',
                                                            data: [10, 10, 15],
                                                        },
                                                    ]}
                                                />
                                            </Card.Body>
                                        </Card>
                                    </div>
                                    <div className='col-lg-6 dash_graph dash_graph_height'>
                                        <Card>
                                            <Card.Header className='d-flex justify-content-between card_header'>
                                                <Card.Header.Title className='w-100'>
                                                    <div className='two_column_title'>
                                                        <h5>
                                                            Performance Metrics
                                                        </h5>
                                                    </div>
                                                </Card.Header.Title>
                                            </Card.Header>
                                            <Card.Body>
                                                <div className='table_style_comman'>
                                                    <DynamicTable
                                                        headers={[
                                                            'Metrics',
                                                            'Self',
                                                            'Team',
                                                            'Process',
                                                        ]}
                                                        widths={[
                                                            '60%',
                                                            '15%',
                                                            '15%',
                                                            '10%',
                                                        ]}
                                                        data={[
                                                            {
                                                                Metrics:
                                                                    'Opening',
                                                                Self: '82%',
                                                                Team: '82%',
                                                                Process: '82%',
                                                            },
                                                            {
                                                                Metrics: 'Tone',
                                                                Self: '12%',
                                                                Team: '12%',
                                                                Process: '12%',
                                                            },
                                                            {
                                                                Metrics:
                                                                    'Rate of Speech',
                                                                Self: '58%',
                                                                Team: '58%',
                                                                Process: '58%',
                                                            },
                                                            {
                                                                Metrics:
                                                                    'Enunciation',
                                                                Self: '82%',
                                                                Team: '82%',
                                                                Process: '82%',
                                                            },
                                                            {
                                                                Metrics:
                                                                    'Vocabulary',
                                                                Self: '58%',
                                                                Team: '58%',
                                                                Process: '58%',
                                                            },
                                                            {
                                                                Metrics:
                                                                    'Product Knowledge',
                                                                Self: '58%',
                                                                Team: '58%',
                                                                Process: '58%',
                                                            },
                                                            {
                                                                Metrics:
                                                                    'Process Knowledge',
                                                                Self: '58%',
                                                                Team: '58%',
                                                                Process: '58%',
                                                            },
                                                            {
                                                                Metrics:
                                                                    'Sales Skills',
                                                                Self: '58%',
                                                                Team: '58%',
                                                                Process: '58%',
                                                            },
                                                            {
                                                                Metrics:
                                                                    'Customer Service Skills',
                                                                Self: '87%',
                                                                Team: '87%',
                                                                Process: '87%',
                                                            },
                                                        ]}
                                                    />
                                                </div>
                                            </Card.Body>
                                        </Card>
                                    </div>
                                    <div className='col-lg-6 dash_graph dash_graph_height'>
                                        <Card>
                                            <Card.Header className='d-flex justify-content-between card_header'>
                                                <Card.Header.Title className='w-100'>
                                                    <div className='two_column_title'>
                                                        <h5>Call Drivers</h5>
                                                    </div>
                                                </Card.Header.Title>
                                            </Card.Header>
                                            <Card.Body className='horizontal_chart_only'>
                                                <div className='horizontal_chart_align_only'>
                                                    <HorizontalBarChartWithPer
                                                        xLabel={[
                                                            'Home Sleep Apnea Testing (HSAT)',
                                                            'Mobile Cardiac Telemetry (MCT)',
                                                            'Overnight Pulse Oximetry (POX)',
                                                            'Real-time Diagnotics',
                                                            'Remote Patient Monitoring',
                                                            'EEG Insomnia Testin',
                                                            'Billing and Reimbursement Issues',
                                                            'Integration with Telehealth',
                                                            'Accurate Diagnostic Reports',
                                                            'User-Friendly Testing Devices',
                                                        ]}
                                                        data={[
                                                            100, 78, 68, 61, 55,
                                                            50, 48, 38, 30, 25,
                                                        ]}
                                                    />
                                                </div>
                                            </Card.Body>
                                        </Card>
                                    </div>
                                    <div className='col-lg-12 dash_graph dash_graph_height'>
                                        <Card>
                                            <Card.Header className='d-flex justify-content-between card_header'>
                                                <Card.Header.Title className='w-100'>
                                                    <div className='two_column_title'>
                                                        <h5>
                                                            Performance Metrics
                                                        </h5>
                                                    </div>
                                                </Card.Header.Title>
                                            </Card.Header>
                                            <Card.Body>
                                                <div className='table_style_comman'>
                                                    <DynamicTable
                                                        headers={[
                                                            'Team Members',
                                                            'Opening',
                                                            'Tone',
                                                            'Rate of Speech',
                                                            'Enunciation',
                                                            'Vocabulary',
                                                            'Product Knowledge',
                                                            'Process Knowledge',
                                                            'Sales Skills',
                                                        ]}
                                                        widths={[
                                                            '20%',
                                                            '10%',
                                                            '10%',
                                                            '10%',
                                                            '10%',
                                                            '10%',
                                                            '10%',
                                                            '10%',
                                                            '10%',
                                                        ]}
                                                        data={[
                                                            {
                                                                'Team Members':
                                                                    'Hanna Kenter',
                                                                Opening: 1,
                                                                Tone: 1,
                                                                'Rate of Speech': 1,
                                                                Enunciation: 1,
                                                                Vocabulary: 2,
                                                                'Product Knowledge': 3,
                                                                'Process Knowledge': 1,
                                                                'Sales Skills': 2,
                                                            },
                                                            {
                                                                'Team Members':
                                                                    'Haylie Stanton',
                                                                Opening: 2,
                                                                Tone: 3,
                                                                'Rate of Speech': 3,
                                                                Enunciation: 3,
                                                                Vocabulary: 4,
                                                                'Product Knowledge': 3,
                                                                'Process Knowledge': 3,
                                                                'Sales Skills': 4,
                                                            },
                                                            {
                                                                'Team Members':
                                                                    'Livia Calzoni',
                                                                Opening: 3,
                                                                Tone: 2,
                                                                'Rate of Speech': 4,
                                                                Enunciation: 4,
                                                                Vocabulary: 2,
                                                                'Product Knowledge': 3,
                                                                'Process Knowledge': 4,
                                                                'Sales Skills': 5,
                                                            },
                                                            {
                                                                'Team Members':
                                                                    'Kaiya Calzoni',
                                                                Opening: 3,
                                                                Tone: 4,
                                                                'Rate of Speech': 3,
                                                                Enunciation: 2,
                                                                Vocabulary: 3,
                                                                'Product Knowledge': 4,
                                                                'Process Knowledge': 4,
                                                                'Sales Skills': 4,
                                                            },
                                                            {
                                                                'Team Members':
                                                                    'Makenna Ekstrom Bothman',
                                                                Opening: 4,
                                                                Tone: 2,
                                                                'Rate of Speech': 4,
                                                                Enunciation: 4,
                                                                Vocabulary: 4,
                                                                'Product Knowledge': 4,
                                                                'Process Knowledge': 2,
                                                                'Sales Skills': 2,
                                                            },
                                                            {
                                                                'Team Members':
                                                                    'Nolan Aminoff',
                                                                Opening: 2,
                                                                Tone: 3,
                                                                'Rate of Speech': 3,
                                                                Enunciation: 3,
                                                                Vocabulary: 4,
                                                                'Product Knowledge': 3,
                                                                'Process Knowledge': 3,
                                                                'Sales Skills': 3,
                                                            },
                                                            {
                                                                'Team Members':
                                                                    'Chance Westervelt',
                                                                Opening: 2,
                                                                Tone: 4,
                                                                'Rate of Speech': 3,
                                                                Enunciation: 4,
                                                                Vocabulary: 3,
                                                                'Product Knowledge': 3,
                                                                'Process Knowledge': 3,
                                                                'Sales Skills': 4,
                                                            },
                                                            {
                                                                'Team Members':
                                                                    'Kaiya Calzoni',
                                                                Opening: 2,
                                                                Tone: 3,
                                                                'Rate of Speech': 3,
                                                                Enunciation: 3,
                                                                Vocabulary: 3,
                                                                'Product Knowledge': 4,
                                                                'Process Knowledge': 4,
                                                                'Sales Skills': 4,
                                                            },
                                                        ]}
                                                    />
                                                </div>
                                            </Card.Body>
                                        </Card>
                                    </div>
                                    <div className='col-lg-6 dash_graph dash_graph_height'>
                                        <Card>
                                            <Card.Header className='d-flex justify-content-between card_header'>
                                                <Card.Header.Title className='w-100'>
                                                    <div className='two_column_title'>
                                                        <h5>Top 5 Reasons</h5>
                                                    </div>
                                                </Card.Header.Title>
                                            </Card.Header>
                                            <Card.Body className='sentimate_chart_only-'>
                                                <div className='mb-4'>
                                                    <h6 className='mt-1 graph_sub_heading'>
                                                        Positive Sentiment
                                                    </h6>
                                                    <HorizontalBarWithoutAxis
                                                        categories={
                                                            PositiveSentimentBar.labels
                                                        }
                                                        values={
                                                            PositiveSentimentBar.values
                                                        }
                                                        colors={
                                                            PositiveSentimentBar.colors
                                                        }
                                                    />
                                                </div>
                                                <div className=''>
                                                    <h6 className='graph_sub_heading'>
                                                        Negative Sentiment
                                                    </h6>
                                                    <div className='mt-2'>
                                                        <HorizontalBarWithoutAxis
                                                            categories={
                                                                NegativeSentimentBar.labels
                                                            }
                                                            values={
                                                                NegativeSentimentBar.values
                                                            }
                                                            colors={
                                                                NegativeSentimentBar.colors
                                                            }
                                                        />
                                                    </div>
                                                </div>
                                            </Card.Body>
                                        </Card>
                                    </div>
                                    <div className='col-lg-6 dash_graph dash_graph_height'>
                                        <Card>
                                            <Card.Header className='d-flex justify-content-between card_header'>
                                                <Card.Header.Title className='w-100'>
                                                    <div className='two_column_title'>
                                                        <h5>
                                                            Most Common Words
                                                        </h5>
                                                    </div>
                                                </Card.Header.Title>
                                            </Card.Header>
                                            <Card.Body className=''>
                                                <div className='mb-4'>
                                                    <h6 className='graph_sub_heading'>
                                                        Positive Sentiment
                                                    </h6>
                                                    <div className='mt-2'>
                                                        <WordCloud
                                                            words={
                                                                PositiveSentimentWords
                                                            }
                                                            options={
                                                                PositiveSentimentOptions
                                                            }
                                                            height='300'
                                                        />
                                                    </div>
                                                </div>
                                                <div className='mt-2'>
                                                    <h6 className='graph_sub_heading'>
                                                        Negative Sentiment
                                                    </h6>
                                                    <div className='mt-2'>
                                                        <WordCloud
                                                            words={
                                                                NegativeSentimentWords
                                                            }
                                                            options={
                                                                NegativeSentimentOptions
                                                            }
                                                            height='400'
                                                        />
                                                    </div>
                                                </div>
                                            </Card.Body>
                                        </Card>
                                    </div>
                                    <div className='col-lg-12 dash_graph dash_graph_height'>
                                        <Card>
                                            <Card.Header className='d-flex justify-content-between card_header'>
                                                <Card.Header.Title className='w-100'>
                                                    <div className='two_column_title'>
                                                        <h5>
                                                            Top 5 Most Common
                                                            Feedback
                                                        </h5>
                                                    </div>
                                                </Card.Header.Title>
                                            </Card.Header>
                                            <Card.Body>
                                                <div className='row'>
                                                    <div className='col-lg-6'>
                                                        <h6 className='graph_sub_heading'>
                                                            Positive Sentiment
                                                        </h6>
                                                        <div className='mt-3 '>
                                                            <ListView
                                                                data={[
                                                                    'Lorem ipsum dolor sit amet consectetur. Id vulputate adipiscing urna netus turpis dictum ornare odio velit.',
                                                                    'Lorem ipsum dolor sit amet consectetur. Id vulputate adipiscing urna netus turpis dictum ornare odio velit.',
                                                                    'Lorem ipsum dolor sit amet consectetur. Id vulputate adipiscing urna netus turpis dictum ornare odio velit.',
                                                                    'Lorem ipsum dolor sit amet consectetur. Id vulputate adipiscing urna netus turpis dictum ornare odio velit.',
                                                                    'Lorem ipsum dolor sit amet consectetur. Id vulputate adipiscing urna netus turpis dictum ornare odio velit.',
                                                                    'Lorem ipsum dolor sit amet consectetur. Id vulputate adipiscing urna netus turpis dictum ornare odio velit.',
                                                                    'Lorem ipsum dolor sit amet consectetur. Id vulputate adipiscing urna netus turpis dictum ornare odio velit.',
                                                                ]}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className='col-lg-6'>
                                                        <h6 className='graph_sub_heading'>
                                                            Negative Sentiment
                                                        </h6>
                                                        <div className='mt-3'>
                                                            <ListView
                                                                data={[
                                                                    'Lorem ipsum dolor sit amet consectetur. Id vulputate adipiscing urna netus turpis dictum ornare odio velit.',
                                                                    'Lorem ipsum dolor sit amet consectetur. Id vulputate adipiscing urna netus turpis dictum ornare odio velit.',
                                                                    'Lorem ipsum dolor sit amet consectetur. Id vulputate adipiscing urna netus turpis dictum ornare odio velit.',
                                                                    'Lorem ipsum dolor sit amet consectetur. Id vulputate adipiscing urna netus turpis dictum ornare odio velit.',
                                                                    'Lorem ipsum dolor sit amet consectetur. Id vulputate adipiscing urna netus turpis dictum ornare odio velit.',
                                                                    'Lorem ipsum dolor sit amet consectetur. Id vulputate adipiscing urna netus turpis dictum ornare odio velit.',
                                                                    'Lorem ipsum dolor sit amet consectetur. Id vulputate adipiscing urna netus turpis dictum ornare odio velit.',
                                                                ]}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </Card.Body>
                                        </Card>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ClientDashboard;
