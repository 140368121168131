import React, { useState, useEffect, lazy } from 'react';
import Card from '../bootstrap/card';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import './reporting_style.css'
import TableWithFixedColumn from './Widgets/Table/TableWithFixedColumn'
import LineChartComponent from './Widgets/MultiLineGraph';
import VerticalBarGraph from './Widgets/VerticalBarGraph';
import { getAgentAvgOverallScriptAccuracy, getAgentLeaderBoard, getDistinctAgentIdsAndNames, getDistinctDepartmentIdsAndNames, getDODScriptAccuracy, getSubparameterWiseAccuracy } from './services/ApiService';
import { useSelector } from 'react-redux';
import * as AuthSelector from '../../store/auth/selectors'
import DateRangePickerComp from '../../views/modules/social/AuditSheet/DateRangePickerComp';
import MultiSelectSBU from '../Analytics/MultiSelectSbu';
import { infoNotify } from '../../CommonLogic';
import { useTranslation } from 'react-i18next';

const COAReporting = () => {
    const [agentAvgOverallScriptAccuracy, setAgentAvgOverallScriptAccuracy] = useState([])
    const [subparameterWiseAccuracy, setSubparameterWiseAccuracy] = useState({ headers: [], data: [] })
    const [dodScriptAccuracy, setdodScriptAccuracy] = useState({ data: [], categories: [] })
    const [agentLeaderboard, setAgentLeaderboard] = useState()
    let endDate = new Date()
    let startDate = new Date(endDate);
    startDate.setMonth(endDate.getMonth() - 1);
    const [datePickerRange, setDatePickerRange] = useState({
        startDate: startDate,
        endDate: endDate,
        key: "selection"
    })
    const [dateTimeRange, setDateTimeRange] = useState([startDate, endDate])
    const departments = useSelector(AuthSelector.organization)?.departments
    const organisation_name = useSelector(AuthSelector.organization)?.organisationName
    const department_ids = departments?.map((department) => department.id)
    const [listAllAgents, setAllAgents] = useState([])
    const [selectedAgents, setSelectedAgents] = useState([]);
    const [listAllDepartments, setAllDepartments] = useState([])
    const [selectedDepartment, setSelectedDepartment] = useState(department_ids);
    const { t, i18n } = useTranslation();

    useEffect(() => {
        const fetchAgentAvgOverallScriptAccuracy = async () => {
            try {
                const data = await getAgentAvgOverallScriptAccuracy(dateTimeRange, selectedDepartment);
                setAgentAvgOverallScriptAccuracy(data)
            } catch (error) {
                console.error('Error fetching agent-wise avg script accuracy:', error);
                throw error;
            }
        }
        fetchAgentAvgOverallScriptAccuracy()

        const fetchAgentLeaderBoard = async () => {
            try {
                const data = await getAgentLeaderBoard(dateTimeRange, selectedDepartment)
                setAgentLeaderboard(data?.map((item) => ({
                    [t('COA Reporting.Agent Name')]: item["AgentSubparameterMv.agentName"],
                    [t('COA Reporting.Count of Calls Audited')]: item["AgentSubparameterMv.totalReports"],
                    [t('COA Reporting.Overall Script Accuracy (%)')]: `${parseFloat(item["AgentSubparameterMv.avgScriptAccuracy"]).toFixed(2)}%`,
                })));                
            } catch (error) {
                console.error('Error fetching agent-wise avg script accuracy:', error);
                throw error;
            }
        }
        fetchAgentLeaderBoard()

        const fetchSubparameterWiseAccuracy = async () => {
            try {
                const data = await getSubparameterWiseAccuracy(dateTimeRange, selectedDepartment);
                const headers = [t('COA Reporting.Agent Name'), ...new Set(data.map(item => item['AgentSubparameterMv.subparameterName']))];
                
                const result = data.reduce((acc, item) => {
                    const agentName = item["AgentSubparameterMv.agentName"];
                    const subparameterName = item["AgentSubparameterMv.subparameterName"];
                    const subparameterScriptAccuracy = `${parseFloat(item["AgentSubparameterMv.subparameterScriptAccuracy"]).toFixed(2)}%`
                    let agentData = acc.find(record => record['Agent Name'] === agentName);
                    if (agentData) {
                        agentData[t('COA Reporting.Agent Name')] = agentData['Agent Name'];  
                        delete agentData['Agent Name'];  
                    }
                    if (!agentData) {
                        agentData = { [t('COA Reporting.Agent Name')]: agentName };
                        acc.push(agentData);
                    }
                    agentData[subparameterName] = subparameterScriptAccuracy;

                    return acc;
                }, []);
                setSubparameterWiseAccuracy({
                    headers: headers,
                    data: result
                })
            } catch (error) {
                console.error('Error fetching agent-wise avg script accuracy:', error);
                throw error;
            }
        }
        fetchSubparameterWiseAccuracy()

        const fetchDODScriptAccuracy = async (agentList = []) => {
            const agentId = agentList.map((agent) => agent.id)
            try {
                const inputData = await getDODScriptAccuracy(dateTimeRange, selectedDepartment, agentId);
                const groupedData = inputData.reduce((acc, curr) => {
                    const agentName = curr["AgentSubparameterMv.agentName"];
                    const reportDate = new Date(curr["AgentSubparameterMv.reportDate"]).toLocaleDateString("en-GB");
                    const avgScriptAccuracy = parseFloat(curr["AgentSubparameterMv.avgScriptAccuracy"]).toFixed(2);

                    if (!acc[agentName]) {
                        acc[agentName] = { name: agentName, data: [] };
                    }

                    acc[agentName].data.push(avgScriptAccuracy);

                    if (!acc.categories.includes(reportDate)) {
                        acc.categories.push(reportDate);
                    }

                    return acc;
                }, { categories: [] });

                // Extract results into the desired format
                const data = Object.values(groupedData).filter((item) => item.name); // Exclude categories
                const categories = groupedData.categories.sort((a, b) => new Date(a) - new Date(b));

                setdodScriptAccuracy({ data: data, categories: categories })
            } catch (error) {
                console.error('Error fetching agent-wise avg script accuracy:', error);
                throw error;
            }
        }
        if (selectedAgents.length > 0) {
            fetchDODScriptAccuracy(selectedAgents)
        }
        else {
            setdodScriptAccuracy({ data: [], categories: [] });
        }

    }, [departments, dateTimeRange, selectedDepartment, selectedAgents])


    useEffect(() => {
        const getAllAgents = async () => {
            try {
                const agentRaw = await getDistinctAgentIdsAndNames(dateTimeRange, selectedDepartment, organisation_name)
                const formattedAgentRaw = agentRaw.map((agents) => {
                    return {
                        value: agents.agentName,
                        label: agents.agentName,
                        id: agents.agent_id
                    }
                })
                setAllAgents(formattedAgentRaw)
                setSelectedAgents([...formattedAgentRaw].splice(0, 5))
            } catch (error) {
                setAllAgents(agentId)
            }
        }
        getAllAgents();
    }, [departments, dateTimeRange, selectedDepartment, organisation_name]);

    useEffect(() => {
        const getAllDepartments = async () => {
            try {
                const departmentsRaw = await getDistinctDepartmentIdsAndNames(organisation_name)
                const formattedDepartmentRaw = departmentsRaw.map((department) => {
                    return {
                        value: department.department_name,
                        id: department.department_id
                    }
                })
                formattedDepartmentRaw.unshift({value: 'None', id: 0})
                setAllDepartments(formattedDepartmentRaw)
                setSelectedDepartment(formattedDepartmentRaw[0])
            } catch (error) {
                console.error('Error fetching department names', error);
                throw error;
            }
        }
        getAllDepartments();
    },[organisation_name])

    useEffect(() => {
            const language = localStorage.getItem('selectedLanguage');
            i18n.changeLanguage(language);
    }, []);
        
    function handleChange(option) {
        if (option.length <= 5) {
            setSelectedAgents(option);
        } else {
            infoNotify(t('COA Reporting.You can only select up to 5 agents at a time.'));
        }

    };

    const handleDepartmentChange = (e) => {
        setSelectedDepartment([e.target.value])
    }
    return (
        <>
            <div className='page-wrapper'>
                <div className='content pb-0'>
                    <div className='row'>
                        <div className='col-sm-12 content_wrap_style'>
                            <div className='content_wrap_comman'>
                                <div className='page_title_box'>
                                    <h3 className='page_title'>
                                        <span className='pe-3'><button className='btn btn_transparent'>
                                            <img src='/images/back_arrow.svg' alt='back button' />
                                        </button></span>
                                        {t('COA Reporting.Dashboard')}
                                    </h3>
                                    <form className="form_style_comman mb-1">
                                        <div className="row">
                                            <div
                                                className="col-lg-3 form-group"
                                            >
                                                <label>{t('COA Reporting.Date Range')}</label>
                                                <DateRangePickerComp
                                                    defaultRange={datePickerRange}
                                                    onDateChange={(e) => {
                                                        const startDate = new Date(e.startDate).toLocaleDateString("en-CA");
                                                        const endDate = new Date(e.endDate).toLocaleDateString("en-CA");
                                                        setDateTimeRange([startDate, endDate]);
                                                        setDatePickerRange({
                                                            startDate: new Date(startDate),
                                                            endDate: new Date(endDate)
                                                        })

                                                    }}
                                                    disabled={false}
                                                />
                                            </div>

                                            <div
                                                className="col-lg-3 form-group"
                                            >
                                                <label>{t('COA Reporting.Department')}</label>
                                                <select className="form-control" onChange={handleDepartmentChange} value={selectedDepartment}>
                                                    {listAllDepartments.map((obj) => (
                                                        <option key={obj.id} value={obj.id}>
                                                            {obj.value}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>


                                        </div>
                                    </form>
                                </div>


                                {/* graph */}

                                <div className='row'>
                                    <div className='col-lg-6 dash_graph dash_graph_height'>
                                        <Card>
                                            <Card.Header className='d-flex justify-content-between card_header'>
                                                <Card.Header.Title className='w-100'>
                                                    <div className='two_column_title'>
                                                        <h5>
                                                            {t('COA Reporting.Agent Manager vs Avg. Overall Script Accuracy')}
                                                        </h5>
                                                    </div>
                                                </Card.Header.Title>
                                            </Card.Header>
                                            <Card.Body className='text-center'>
                                                <VerticalBarGraph
                                                    data={[
                                                        {
                                                            data: agentAvgOverallScriptAccuracy?.map((item) => `${parseFloat(item['AgentSubparameterMv.avgScriptAccuracy']).toFixed(2)}%`),
                                                            name: 'Overall Accuracy',
                                                        },
                                                    ]}
                                                    categories={agentAvgOverallScriptAccuracy?.map((item) => item['AgentSubparameterMv.agentManagerName'])}
                                                    yLabel={t('COA Reporting.Accuracy Percentage (%)')}
                                                    xLabel={t('COA Reporting.Agent Manager Names')} />
                                            </Card.Body>
                                        </Card>
                                    </div>
                                    <div className='col-lg-6 dash_graph dash_graph_height'>
                                        <Card>
                                            <Card.Header className='d-flex justify-content-between card_header'>
                                                <Card.Header.Title className='w-100'>
                                                    <div className='two_column_title'>
                                                        <h5>
                                                            {t('COA Reporting.Trend of Script Accuracy Over Time')}
                                                        </h5>
                                                        <div className='form_style_comman multiselect_scroll_only'>
                                                            <MultiSelectSBU
                                                                allOptions={listAllAgents}
                                                                selectedAgents={selectedAgents}
                                                                handleChange={handleChange}
                                                            />
                                                        </div>
                                                    </div>
                                                </Card.Header.Title>
                                            </Card.Header>
                                            <Card.Body>
                                                <LineChartComponent
                                                    data={dodScriptAccuracy?.data}
                                                    categories={dodScriptAccuracy?.categories}
                                                    xLabel={t('COA Reporting.Time')}
                                                    yLabel={
                                                        t('COA Reporting.Overall Script Accuracy (%)')
                                                    }
                                                />
                                            </Card.Body>
                                        </Card>
                                    </div>
                                    <div className='col-lg-12 dash_graph dash_graph_height'>
                                        <Card>
                                            <Card.Header className='d-flex justify-content-between card_header'>
                                                <Card.Header.Title className='w-100'>
                                                    <div className='two_column_title'>
                                                        <h5>
                                                            {t('COA Reporting.Sub-Parameter-wise Accuracy Overview')}
                                                        </h5>
                                                    </div>
                                                </Card.Header.Title>
                                            </Card.Header>
                                            <Card.Body>
                                                <TableWithFixedColumn
                                                    headers={subparameterWiseAccuracy?.headers}
                                                    widths={[
                                                        '20%'
                                                    ]}
                                                    data={subparameterWiseAccuracy?.data}
                                                    tooltipEnabled
                                                />
                                            </Card.Body>
                                        </Card>
                                    </div>
                                    <div className='col-lg-12 dash_graph dash_graph_height'>
                                        <Card>
                                            <Card.Header className='d-flex justify-content-between card_header'>
                                                <Card.Header.Title className='w-100'>
                                                    <div className='two_column_title'>
                                                        <h5>
                                                        {t('COA Reporting.Agent Ranking by Script Accuracy')}
                                                        </h5>
                                                    </div>
                                                </Card.Header.Title>
                                            </Card.Header>
                                            <Card.Body>
                                                <div className='table_style_comman'>
                                                    <div className='table-responsive'>
                                                        <TableWithFixedColumn
                                                            headers={[
                                                                t('COA Reporting.Agent Name'),
                                                                t('COA Reporting.Count of Calls Audited'),
                                                                t('COA Reporting.Overall Script Accuracy (%)'),
                                                            ]}                                                            
                                                            widths={[
                                                                '70%',
                                                                '30%',
                                                                '30%'
                                                            ]}
                                                            data={agentLeaderboard || []}
                                                        />
                                                    </div>
                                                </div>
                                            </Card.Body>
                                        </Card>
                                    </div>


                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default COAReporting;
