import React, { useState } from "react";

const getBackgroundColor = (score) => {
    let r = 0, g = 0, b = 0;
    if (score <= 50) {
        r = Math.round(183 - (183 * score) / 50);
        g = 28;
        b = 28;
    } else {
        r = 0;
        g = Math.round(28 + (49 * (score - 50)) / 50);
        b = 0;
    }
    return `rgb(${r}, ${g}, ${b})`;
};

const TableWithBar = ({ data, header = ["Client", "Average of Audit Score",], rowOnClick = () => console.log("") }) => {

    const [sortConfig, setSortConfig] = useState({ key: null, direction: "asc" });

    const sortedData = [...data].sort((a, b) => {
        if (!sortConfig.key) return 0;
        const valA = a[sortConfig.key];
        const valB = b[sortConfig.key];
        if (sortConfig.direction === "asc") {
            return valA > valB ? 1 : valA < valB ? -1 : 0;
        } else {
            return valA < valB ? 1 : valA > valB ? -1 : 0;
        }
    });

    const handleSort = (i) => {
        const arr = ["name", 'count', "score"];
        const key = arr[i]
        setSortConfig((prev) => ({
            key,
            direction: prev.key === key && prev.direction === "asc" ? "desc" : "asc",
        }));
    };

    const getArrow = (i) => {
        const arr = ["name", 'count', "score"];
        const key = arr[i]
        if (sortConfig.key === key) {
            return sortConfig.direction === "asc" ? true : false;
        }
        return false;
    };

    return (
        <div className='table_width_bar'>
            <table
                className='tbl_custom_reporing'
                style={{ width: '100%', borderCollapse: 'collapse' }}>
                <thead>
                    <tr>
                        {header.map((columnKey, i) => (
                            <th
                                key={columnKey}
                                onClick={() => handleSort(i)}
                                style={{
                                    cursor: 'pointer',
                                    textAlign: (i == header?.length-1) ? "right" : ""
                                }}>
                                {columnKey}
                                {getArrow(i)}
                                <span
                                    className={`tbl_sorting_icon ${getArrow(i) ? 'ascending-icon' : 'descending-icon'}`}></span>
                            </th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {sortedData.map((item, index) => (
                        <tr key={index}>
                            <td className="styled-link" onClick={() => rowOnClick(item.id, item.name)}>{item.name}</td>
                            {item?.count && <td style={{ padding: '8px' }}>{item.count}</td>}
                            <td
                                style={{
                                    padding: '8px',
                                    position: 'relative',
                                }}>
                                <div
                                    style={{
                                        backgroundColor: getBackgroundColor(
                                            item.score
                                        ),
                                        width: '100%',
                                        height: '100%',
                                        position: 'absolute',
                                        left: 0,
                                        top: '50%',
                                        transform: 'translateY(-50%)',
                                    }}
                                />
                                <div
                                    style={{
                                        position: 'relative',
                                        zIndex: 1,
                                        textAlign: 'right',
                                        paddingRight: '8px',
                                        color: '#D0D5DD',
                                    }}>
                                    {parseFloat(item.score).toFixed(2)}
                                </div>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default TableWithBar;
