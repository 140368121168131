import { fetchData } from "../../../../services/config";

export const getReportData = async (dateRange, sbu_id, geography_id, client_id, department_id, country_id, site_id, organisation_id) => {
    const query = {
      dimensions: [
        'ReportAggregated.ticketId',
        'ReportAggregated.sbuName',
        'ReportAggregated.clientName',
        'ReportAggregated.departmentName',
        'ReportAggregated.agentName',
        'ReportAggregated.qaName',
        'ReportAggregated.reportDate',
        'ReportAggregated.fatalTotalCount',
        'ReportAggregated.overallAuditScore',
        'ReportAggregated.criticalBusinessScoreOverall',
        'ReportAggregated.criticalCustomerScoreOverall',
        'ReportAggregated.criticalComplianceScoreOverall',
      ],
      filters: [{
        dimension: 'ReportAggregated.organisationId',
        operator: 'equals',
        values: [organisation_id],
      }],
      timeDimensions: [
        {
          dimension: 'ReportAggregated.reportDate',
          dateRange: dateRange,
        },
      ],
    }
    if (sbu_id && sbu_id!='0') {
      query.filters.push({
        dimension: 'ReportAggregated.sbu_id',
        operator: 'equals',
        values: [sbu_id],
      });
    }
    if (geography_id && geography_id!='0') {
      query.filters.push({
        dimension: 'ReportAggregated.geography_id',
        operator: 'equals',
        values: [geography_id],
      });
    }
    if (client_id && client_id!='0') {
      query.filters.push({
        dimension: 'ReportAggregated.client_id',
        operator: 'equals',
        values: [client_id],
      });
    }
    if (department_id && department_id!='0') {
      query.filters.push({
        dimension: 'ReportAggregated.department_id',
        operator: 'equals',
        values: [department_id],
      });
    }
    if (country_id && country_id!='0') {
      query.filters.push({
        dimension: 'ReportAggregated.country_id',
        operator: 'equals',
        values: [country_id],
      });
    }
    if (site_id && site_id!='0') {
      query.filters.push({
        dimension: 'ReportAggregated.site_id',
        operator: 'equals',
        values: [site_id],
      });
    }
    return fetchData(query);
  }