
import { getAgentDataperClient, getAgentDataperLocation, getAgentDataperSBU, getClientWiseAuditCounts, getClientWiseAuditScore, getCountryWiseAuditScore, getDepartmentsDataperClient, getDepartmentWiseAuditScore, getLocationWiseAuditScore, getOrganisationMetricsAgent, getOrganisationMetricsQA, getQADataperClient, getQADataperLocation, getQADataperSBU, getWowTrendPerClient } from '../ApiService';
import { transformAgentData, transformQAData, formatData, transformDataForChart, transformDepartmentData } from './utils';


export const fetchAgentMetrics = async (settotalAgents, setAverageAuditScore, setTotalAudits, dateTimeRange, selectedSBU, selectedGeo, selectedClient, selectedCountry) => {
  try {
    let agentResult = await getOrganisationMetricsAgent(dateTimeRange, selectedSBU, selectedGeo, selectedClient, selectedCountry);
    if (agentResult?.length < 1) {
      settotalAgents(0);
      setTotalQa(0)
      setAverageAuditScore(0);
      setTotalAudits(0);

      return;
    }
    agentResult = agentResult[0];
    setTotalAudits(agentResult['Agent.total_reports']);
    setAverageAuditScore(agentResult['Agent.average_audit_score']);
    settotalAgents(agentResult['Agent.total_agents']);
  } catch (err) {
    console.error('Error fetching agent metrics:', err);
  }
};

export const fetchQAMetrics = async (setTotalQa, dateTimeRange, selectedSBU, selectedGeo, selectedClient, selectedCountry) => {
  try {
    let qaResult = await getOrganisationMetricsQA(dateTimeRange, selectedSBU, selectedGeo, selectedClient, selectedCountry);
    if (qaResult?.length > 0) {
      setTotalQa(qaResult[0]['QA.total_qas']);
    } else {
      setTotalQa(0);
    }
  } catch (err) {
    console.error('Error fetching QA metrics:', err);
  }
};

export  const fetchLocationWiseAuditScore = async (setClientWiseAuditScore, dateTimeRange, selectedSBU, selectedGeo, selectedClient, selectedCountry) => {
  try {
    const result = await getLocationWiseAuditScore(dateTimeRange, selectedSBU, selectedGeo, selectedClient, selectedCountry);
    setClientWiseAuditScore(result);
  } catch (err) {
    console.error('Error fetching location-wise audit metrics:', err);
  }
};

export  const fetchDepartmentWiseAuditScore = async (setClientWiseAuditScore, dateTimeRange, selectedSBU, selectedGeo, selectedClient, selectedCountry) => {
  try {
    const result = await getDepartmentWiseAuditScore(dateTimeRange, selectedSBU, selectedGeo, selectedClient, selectedCountry);
    setClientWiseAuditScore(result);
  } catch (err) {
    console.error('Error fetching department-wise audit metrics:', err);
  }
};

export const fetchQAByLocationData = async (setTotalQasByClient, dateTimeRange, selectedSBU, selectedGeo, selectedClient, selectedCountry) => {
  try {
      const result = await getQADataperLocation(dateTimeRange, selectedSBU, selectedGeo, selectedClient, selectedCountry);
      setTotalQasByClient(transformQAData(result));
    } catch (err) {
      console.error('Error fetching data:', err);
    }
}

export const fetchAgentsByLocationData = async (setTotalAgentsByClient, dateTimeRange, selectedSBU, selectedGeo, selectedClient, selectedCountry) => {
  try {
      const result = await getAgentDataperLocation(dateTimeRange, selectedSBU, selectedGeo, selectedClient, selectedCountry);
      setTotalAgentsByClient(transformAgentData(result));
    } catch (err) {
      console.error('Error fetching data:', err);
    }
}