import { getAgentSentiment, getCustomerSentiment, getDateWiseSentiment, getNegativeFeedback, getNegativeReasons, getNegativeWordCloud, getOverallSentiment, getPerformanceMetrics, getPositiveFeedback, getPositiveReasons, getPositiveWordCloud } from "../services/ApiService"

export const fetchOverallSentimentData = async (setSentimentData, dateTimeRange, client_id, department_id) => {
    try {
        let overall = await getOverallSentiment(dateTimeRange, client_id, department_id)
        let overallSentiment_data = {
            departments: overall.map((item) => item['VocSentimentMetrics.departmentName']),
            positive: overall.map((item) => parseInt(item['VocSentimentMetrics.totalPositiveOverallSentiment'])),
            negative: overall.map((item) => parseInt(item['VocSentimentMetrics.totalNegativeOverallSentiment'])),
            neutral: overall.map((item) => parseInt(item['VocSentimentMetrics.totalNeutralOverallSentiment']))
        }
        setSentimentData(overallSentiment_data)
    } catch (err) {
        console.error('Error fetching data:', err);
    }
}

export const fetchAgentSentimentData = async (setSentimentData, dateTimeRange, client_id, department_id) => {
    try {
        let overall = await getAgentSentiment(dateTimeRange, client_id, department_id)
        let overallSentiment_data = {
            departments: overall.map((item) => item['VocSentimentMetrics.departmentName']),
            positive: overall.map((item) => parseInt(item['VocSentimentMetrics.totalPositiveAgentSentiment'])),
            negative: overall.map((item) => parseInt(item['VocSentimentMetrics.totalNegativeAgentSentiment'])),
            neutral: overall.map((item) => parseInt(item['VocSentimentMetrics.totalNeutralAgentSentiment']))
        }
        setSentimentData(overallSentiment_data)
    } catch (err) {
        console.error('Error fetching data:', err);
    }
}

export const fetchCustomerSentimentData = async (setSentimentData, dateTimeRange, client_id, department_id) => {
    try {
        let overall = await getCustomerSentiment(dateTimeRange, client_id, department_id)
        let overallSentiment_data = {
            departments: overall.map((item) => item['VocSentimentMetrics.departmentName']),
            positive: overall.map((item) => parseInt(item['VocSentimentMetrics.totalPositiveProspectSentiment'])),
            negative: overall.map((item) => parseInt(item['VocSentimentMetrics.totalNegativeProspectSentiment'])),
            neutral: overall.map((item) => parseInt(item['VocSentimentMetrics.totalNeutralProspectSentiment']))
        }
        setSentimentData(overallSentiment_data)
    } catch (err) {
        console.error('Error fetching data:', err);
    }
}

export const fetchPerformanceMetrics = async (setPerformanceMetrics, dateTimeRange, client_id, department_id) => {
    try {
        let data = await getPerformanceMetrics(dateTimeRange, client_id, department_id)
        data = data?.map((item) => {
            return { 
                'Team Members': item['VocPerformanceMetrics.agentName'],
                'Opening': parseFloat(item['VocPerformanceMetrics.avgOpeningScore']).toFixed(2),
                'Closing': parseFloat(item['VocPerformanceMetrics.avgClosingScore']).toFixed(2),
                'Tone': parseFloat(item['VocPerformanceMetrics.avgTone']).toFixed(2),
                'Interruptions': parseFloat(item['VocPerformanceMetrics.avgInterruptions']).toFixed(2),
                'Enunciation': parseFloat(item['VocPerformanceMetrics.avgEnunciation']).toFixed(2),
                'Vocabulary': parseFloat(item['VocPerformanceMetrics.avgVocabulary']).toFixed(2),
                'Conciseness': parseFloat(item['VocPerformanceMetrics.avgConciseness']).toFixed(2),
                'Context Awareness': parseFloat(item['VocPerformanceMetrics.avgContextAwareness']).toFixed(2),
                'Empathy': parseFloat(item['VocPerformanceMetrics.avgEmpathy']).toFixed(2),
                'Sales Skills': parseFloat(item['VocPerformanceMetrics.avgSalesSkills']).toFixed(2),
              }
              
        })
        setPerformanceMetrics(data)
    } catch (err) {
        console.error('Error fetching data:', err);
    }
}

export const fetchDateWise = async (setSentimentData, dateTimeRange, client_id, department_id) => {
    try {
        let data = await getDateWiseSentiment(dateTimeRange, client_id, department_id)
        const sentimentData = {
            xLabel: [],
            yLabel: 'Count',
            data: [
                { name: 'Positive', data: [] },
                { name: 'Neutral', data: [] },
                { name: 'Negative', data: [] },
            ],
        };
    
        if (data && data.length > 0) {
            data.forEach((row) => {
                sentimentData.xLabel.push(row['VocSentimentMetrics.reportWeek']);
                sentimentData.data[0].data.push(row['VocSentimentMetrics.totalPositiveOverallSentiment']);
                sentimentData.data[1].data.push(row['VocSentimentMetrics.totalNeutralOverallSentiment']);
                sentimentData.data[2].data.push(row['VocSentimentMetrics.totalNegativeOverallSentiment']);
            });
        }
    
        setSentimentData(sentimentData)
    } catch (err) {
        console.error('Error fetching data:', err);
    }
}

export const fetchPositiveWordCloud = async (setPositiveWordCloud, dateTimeRange, client_id, department_id) => {
    try {
        let data = await getPositiveWordCloud(dateTimeRange, client_id, department_id)
        data = data.map((item) => {
            return {
                text: item['PositiveWordCloud.word'],
                value: item['PositiveWordCloud.avgValue']
            }
        })
        setPositiveWordCloud(data)
    } catch (err) {
        console.error('Error fetching data:', err);
    }
}

export const fetchPositiveReasons = async (setPositiveReasons, dateTimeRange, client_id, department_id) => {
    try {
        let data = await getPositiveReasons(dateTimeRange, client_id, department_id)
        data = data.sort((a, b) => parseFloat(b['PositiveReasons.avgValue']) - parseFloat(a['PositiveReasons.avgValue']))
        data = {
            labels: data.map((item) => item['PositiveReasons.reason']),
            values: data.map((item) => parseFloat(item['PositiveReasons.avgValue']).toFixed(2)),
            colors: ['#072B15', '#0B4522', '#126E36', '#1DB056', '#23CE6B'],
        };
        
        setPositiveReasons(data)
    } catch (err) {
        console.error('Error fetching data:', err);
    }
}

export const fetchNegativeReasons = async (setNegativeReasons, dateTimeRange, client_id, department_id) => {
    try {
        let data = await getNegativeReasons(dateTimeRange, client_id, department_id)
        data = data.sort((a, b) => parseFloat(a['NegativeReasons.avgValue']) - parseFloat(b['NegativeReasons.avgValue']))
        data = {
            labels: data.map((item) => item['NegativeReasons.reason']),
            values: data.map((item) => parseFloat(item['NegativeReasons.avgValue']).toFixed(2)),
            colors: ['#7A271A', '#912018', '#B42318', '#D92D20', '#F04438'],
        };
        
        setNegativeReasons(data)
    } catch (err) {
        console.error('Error fetching data:', err);
    }
}


export const fetchNegativeWordCloud = async (setNegativeWordCloud, dateTimeRange, client_id, department_id) => {
    try {
        let data = await getNegativeWordCloud(dateTimeRange, client_id, department_id)
        data = data.map((item) => {
            return {
                text: item['NegativeWordCloud.word'],
                value: item['NegativeWordCloud.avgValue']
            }
        })
        setNegativeWordCloud(data)
    } catch (err) {
        console.error('Error fetching data:', err);
    }
}

export const fetchPositiveFeedback = async (setPositiveFeedback, dateTimeRange, client_id, department_id) => {
    try {
        let data = await getPositiveFeedback(dateTimeRange, client_id, department_id)
        data = data
        .filter(item => item['PositiveFeedback.positive_feedback'])
        .flatMap(item => item['PositiveFeedback.positive_feedback']
          .split('\n')
          .map(line => line.replace(/^•\s*/, ''))
        );

        setPositiveFeedback(data.slice(0, 5))
    } catch (err) {
        console.error('Error fetching data:', err);
    }
}

export const fetchNegativeFeedback = async (setNegativeFeedback, dateTimeRange, client_id, department_id) => {
    try {
        let data = await getNegativeFeedback(dateTimeRange, client_id, department_id)
        data = data
        .filter(item => item['NegativeFeedback.negative_feedback'])
        .flatMap(item => item['NegativeFeedback.negative_feedback']
          .split('\n')
          .map(line => line.replace(/^•\s*/, ''))
        );



        setNegativeFeedback(data.slice(0, 5))
    } catch (err) {
        console.error('Error fetching data:', err);
    }
}
