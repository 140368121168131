export const transformQAData = (data) => {
    const categories = data.map(item => item["QA.client_name"]);
    const qaData = data.map(item => parseInt(item["QA.total_qas"], 10));
    const totalCount = qaData.reduce((sum, currentValue) => sum + currentValue, 0);
    return {
      data: qaData,
      categories: categories,
      totalCount: totalCount,
    };
  };
  
  export const transformAgentData = (data) => {
    const categories = data.map(item => item["Agent.client_name"]);
    const agentData = data.map(item => parseInt(item["Agent.total_agents"], 10));
    const totalCount = agentData.reduce((sum, currentValue) => sum + currentValue, 0);
    return {
      data: agentData,
      categories: categories,
      totalCount: totalCount,
    };
  };
  
  export const transformDepartmentData = (data) => {
    const categories = data.map(item => item["Agent.client_name"]);
    const departmentData = data.map(item => parseInt(item["Agent.total_departments"], 10));
    const totalCount = departmentData.reduce((sum, currentValue) => sum + currentValue, 0);
    return {
      data: departmentData,
      categories: categories,
      totalCount: totalCount,
    };
  };
  
  export const formatData = (data, dateParameter='report_week') => {
    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const options = { day: '2-digit', month: 'short' };
        const startDate = new Date(date);
        const endDate = new Date(date);
        let diff = 0;
        if(dateParameter=='report_week'){
            diff = 6
        } else if(dateParameter=='report_month'){
            diff = 29
        }else {
            return `${startDate.toLocaleDateString('en-GB', options)}`
        }
        endDate.setDate(startDate.getDate() + diff);
        return `${startDate.toLocaleDateString('en-GB', options)}-${endDate.toLocaleDateString('en-GB', options)}`;
    };

    return data.reduce((acc, row) => {
        const reportWeek = formatDate(row[`Agent.${dateParameter}`]);
        const sbuName = row['Agent.client_name'];
        const trendValue = parseFloat(row['Agent.wow_trend_of_audit_score']).toFixed(1);

        if (!acc[reportWeek]) acc[reportWeek] = [];
        acc[reportWeek].push({ [sbuName]: trendValue });

        return acc;
    }, {});
};
  
  export const transformDataForChart = (formattedData) => {
    const categories = Object.keys(formattedData);
  
    const allClientNames = Array.from(
      new Set(
        Object.values(formattedData).flatMap((weekData) =>
          weekData.map((entry) => Object.keys(entry)[0])
        )
      )
    );
  
    const data = allClientNames.map((clientName) => {
      const clientData = categories.map((week) => {
        const weekData = formattedData[week].find((entry) => entry[clientName] !== undefined);
        return weekData ? weekData[clientName] : null;
      });
  
      return {
        name: clientName,
        data: clientData,
      };
    });
  
    return { data, categories };
  };
  