import React, { useEffect, useState } from 'react';
import * as AuthSelectors from '../../../src/store/auth/selectors';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const KnowledgeMngtSideNav = ({ tab = 'notice-feed' }) => {
    const orgStrId = useSelector(AuthSelectors.orgStrId);
    const navigate = useNavigate();
    const [active, setActive] = useState(tab);
       const { t, i18n } = useTranslation();
    const handleNavLink = activeNav => {
        if (active == activeNav) {
            return;
        }
        setActive(activeNav);
        navigate(`/o/${orgStrId}/knowledge-management/${activeNav}`);
    };

    useEffect(() => {
        const language = localStorage.getItem('selectedLanguage');
        i18n.changeLanguage(language);
    }, []);
    return (
        <>
            <li>
                <a
                    className={active == 'notice-feed' ? 'active' : ''}
                    href='#'
                    onClick={e => {
                        e.preventDefault();
                        handleNavLink('notice-feed');
                    }}
                >
                    {t('Notice Feed.Notice Feed')}
                </a>
            </li>
            <li>
                <a
                    className={
                        active == 'process-knowledge-test' ? 'active' : ''
                    }
                    href='#'
                    onClick={e => {
                        e.preventDefault();
                        handleNavLink('process-knowledge-test');
                    }}
                >
                    {t('Notice Feed.Process Knowledge Test')}
                </a>
            </li>
        </>
    );
};

export default KnowledgeMngtSideNav;
