import React, { useState, useEffect, lazy } from 'react';
import Card from '../../../bootstrap/card';
import ApexChart from 'react-apexcharts';
import Chart from 'react-apexcharts';
import ReactApexChart from 'react-apexcharts';
import WordCloud from 'react-wordcloud';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import DateRangePickerComp from '../../../../views/modules/social/AuditSheet/DateRangePickerComp';
import { DateRangeFunction } from '../../../../CommonLogic';
import DataTable from '../../../DataTable';
import SingleLineChart from '../../Widgets/LineChart';
import TableWithBar from '../../Widgets/TableWithBar';
import DynamicTable from '../../Widgets/Table/TableWithSorting';
import HorizontalBarWithoutAxis from '../../Widgets/HorizontalBarWithoutAxis';
import WordCloudComp from '../../Widgets/WordCloudComp';
import ListView from '../../Widgets/ListView';
import { useNavigate } from 'react-router-dom';
import { useAnalyticsContext } from '../../AnalyticsContext';
import Filter from '../../Filter';
import { fetchAgentWise, fetchFatalTrend, fetchOrganisationMetrics, fetchQAMetrics, fetchQAWise, fetchSubparameterInfo } from './service/PageData/Page';
import LineChartComponent from '../../Widgets/MultiLineGraph';
import VoiceOfCustomer from '../Client/VoiceOfCustomer'
const ProgramWiseDashboard = () => {
    const [total_audits, setTotalAudits] = useState(0)
    const [averageAuditScore, setAverageAuditScore] = useState(0)
    const [total_agents, setTotalAgents] = useState(0)
    const [total_qas, setTotalQAs] = useState(0)
    const [total_fatals, setTotalFatals] = useState(0)
    const [fatal_trend, setFatalTrend] = useState([])
    const [businessCriticalScore, setBusinessCriticalScore] = useState(0)
    const [complianceCriticalScore, setComplianceCriticalScore] = useState(0)
    const [customerCriticalScore, setCustomerCriticalScore] = useState(0)
    const [subparameterInfo, setSubParameterInfo] = useState(0)

    const [agentWise, setAgentWise] = useState([])
    const [qaWise, setQaWise] = useState([])

    const { analyticsData, handleBackClick, setFilterData } = useAnalyticsContext()
    const { dateTimeRange, selectedFilters, baseURL } = analyticsData
    useEffect(() => {
        fetchOrganisationMetrics(setAverageAuditScore, setTotalAudits, setTotalAgents, setTotalFatals, setBusinessCriticalScore, setComplianceCriticalScore, setCustomerCriticalScore, dateTimeRange, selectedFilters['department']?.id, selectedFilters['client']?.id)
        fetchQAMetrics(setTotalQAs, dateTimeRange, selectedFilters['department']?.id, selectedFilters['client']?.id)
        fetchFatalTrend(setFatalTrend, dateTimeRange, selectedFilters['department']?.id, selectedFilters['client']?.id)
        fetchAgentWise(setAgentWise, dateTimeRange, selectedFilters['department']?.id, selectedFilters['client']?.id);
        fetchQAWise(setQaWise, dateTimeRange, selectedFilters['department']?.id , selectedFilters['client']?.id);
        fetchSubparameterInfo(setSubParameterInfo, dateTimeRange, selectedFilters['department']?.id, selectedFilters['client']?.id)
    }, [dateTimeRange, selectedFilters])
    const navigate = useNavigate();
    const department_redirection = (id, name) => {
        setFilterData('department', {id: id, name: name})
        navigate(`${baseURL}/program-dashboard/${id}`);
    }
    const agent_redirection = (id, name) => {
        setFilterData('agent', {id: id, name: name})
        navigate(`${baseURL}/agents-dashboard/${id}`);
    }
    // const qa_redirection = (id) => {
    //     setFilterData('qa', {id: id, name: name})
    //     navigate(`${baseURL}/qas-dashboard/${id}`);
    // }

    const ticket_redirection = () => {
        navigate(`${baseURL}/ticket-wise-dashboard`);
    }

    // positive sentimate word cloud
    const PositiveSentimentWords = [
        { text: 'Efficient', value: 100 },
        { text: 'Helpful', value: 95 },
        { text: 'Accurate', size: 90 },
        { text: 'Simple', value: 88 },
        { text: 'Quick', value: 85 },
        { text: 'Reliable', value: 83 },
        { text: 'Professional', value: 80 },
        { text: 'Smooth', value: 78 },
        { text: 'Effective', value: 75 },
        { text: 'Friendly', value: 73 },
        { text: 'Convenient', value: 70 },
        { text: 'Clear', value: 68 },
        { text: 'Fast', value: 65 },
        { text: 'Great Service', value: 63 },
        { text: 'Quality', value: 60 },
    ];

    const PositiveSentimentOptions = {
        rotations: 2,
        rotationAngles: [-90, 0],
        fontSizes: [12, 50],
        spiral: 'archimedean', // Circular layout shape
        padding: 2, // Adjust padding between words for readability
        colors: [
            '#072B15',
            '#0B4522',
            '#126E36',
            '#1DB056',
            '#23CE6B',
            '#27E376',
            '#2FFF8E',
            '#84FFBC',
            '#4080d4',
            '#d978b4',
        ], // Add your custom colors here
        enableTooltip: false, // Disable tooltip
    };

    // negative sentimate word cloud
    const NegativeSentimentWords = [
        { text: 'Confusing', value: 100 },
        { text: 'Delay', value: 95 },
        { text: 'Difficult', value: 90 },
        { text: 'Long Wait', value: 88 },
        { text: 'Unclear', value: 85 },
        { text: 'Issue', value: 83 },
        { text: 'Problem', value: 80 },
        { text: 'Complicated', value: 78 },
        { text: 'Poor Support', value: 75 },
        { text: 'Error', value: 73 },
        { text: 'Troubleshoot', value: 70 },
        { text: 'Frustrating', value: 68 },
        { text: 'Incorrect', value: 65 },
        { text: 'Cancelled', value: 60 },
        { text: 'Expensive', value: 55 },
    ];

    const NegativeSentimentOptions = {
        rotations: 2,
        rotationAngles: [-90, 0],
        fontSizes: [12, 50],
        spiral: 'archimedean', // Circular layout shape
        padding: 2, // Adjust padding between words for readability
        colors: [
            '#7A271A',
            '#912018',
            '#B42318',
            '#D92D20',
            '#F04438',
            '#F97066',
            '#FDA29B',
            '#FECDCA',
            '#f576c5',
            '#fb8b6d',
        ], // Add your custom colors here
        enableTooltip: false, // Disable tooltip
    };

    const PositiveSentimentBar = {
        labels: ['Pkg', 'Clean', 'Staff', 'Avail', 'Response'],
        values: [90, 770, 650, 470, 340],
        colors: ['#072B15', '#0B4522', '#126E36', '#1DB056', '#23CE6B'],
    };

    const NegativeSentimentBar = {
        labels: ['Service', 'Support', 'Speed', 'Quality', 'Price'],
        values: [450, 300, 500, 600, 700],
        colors: ['#7A271A', '#912018', '#B42318', '#D92D20', '#F04438'],
    };
    return (
        <>
            <div className='page-wrapper'>
                <div className='content pb-0'>
                    <div className='row'>
                        <div className='col-sm-12 content_wrap_style'>
                            <div className='content_wrap_comman'>
                            <div className='page_title_box'>
                                    <h3 className='page_title'>
                                        <span className='pe-3'><button className='btn btn_transparent' onClick={handleBackClick}>
                                            <img src='/images/back_arrow.svg' alt='back button' />
                                        </button></span>
                                        Program Dashboard
                                    </h3>
                                </div>
                                <Filter showDate showDepartment />
                                {/* count */}
                                <div className='row'>
                                    <div className='col-lg-3 card_legends_box'>
                                        <div className='card_legends card w-100 text-center'>
                                            <h4 className='mb-2'>{total_agents}</h4>
                                            <h6 className='mb-0'>
                                                Total Agents Audited
                                            </h6>
                                        </div>
                                    </div>
                                    <div className='col-lg-3 card_legends_box'>
                                        <div className='card_legends card w-100 text-center'>
                                            <h4 className='mb-2'>{total_qas}</h4>
                                            <h6 className='mb-0'>
                                                Total QA Audited
                                            </h6>
                                        </div>
                                    </div>
                                    <div className='col-lg-3 card_legends_box'>
                                        <div className='card_legends card_legends_clickable card w-100 text-center'  onClick={ticket_redirection}>
                                            <h4 className='mb-2'>{`${parseFloat(averageAuditScore).toFixed(2)}%`}</h4>
                                            <h6 className='mb-0'>
                                                Avg Audit Score
                                            </h6>
                                        </div>
                                    </div>
                                    <div className='col-lg-3 card_legends_box'>
                                        <div className='card_legends card_legends_clickable card w-100 text-center'  onClick={ticket_redirection}>
                                            <h4 className='mb-2'>{total_audits}</h4>
                                            <h6 className='mb-0'>
                                                Total Audits
                                            </h6>
                                        </div>
                                    </div>
                                    <div className='col-lg-4 card_legends_box'>
                                        <div className='card_legends card_legends_clickable card w-100 text-center'  onClick={ticket_redirection}>
                                            <h4 className='mb-2'>{`${parseFloat(businessCriticalScore).toFixed(2)}%`}</h4>
                                            <h6 className='mb-0'>
                                                Business Critical
                                            </h6>
                                        </div>
                                    </div>
                                    <div className='col-lg-4 card_legends_box'>
                                        <div className='card_legends card_legends_clickable card w-100 text-center'  onClick={ticket_redirection}>
                                            <h4 className='mb-2'>{`${parseFloat(customerCriticalScore).toFixed(2)}%`}</h4>
                                            <h6 className='mb-0'>
                                                Customer Critical
                                            </h6>
                                        </div>
                                    </div>
                                    <div className='col-lg-4 card_legends_box'>
                                        <div className='card_legends card_legends_clickable card w-100 text-center'  onClick={ticket_redirection}>
                                            <h4 className='mb-2'>{`${parseFloat(complianceCriticalScore).toFixed(2)}%`}</h4>
                                            <h6 className='mb-0'>
                                                Compliance Critical
                                            </h6>
                                        </div>
                                    </div>
                                </div>
                                {/* graph */}
                                <div className='row'>
                                    <div className='col-lg-12'>
                                        <div className='dash_graph'>
                                            <Card>
                                                <Card.Header className='d-flex justify-content-between card_header'>
                                                    <Card.Header.Title className='w-100'>
                                                        <div className='two_column_title'>
                                                            <h5>
                                                                Fatal Trends
                                                            </h5>
                                                        </div>
                                                    </Card.Header.Title>
                                                </Card.Header>
                                                <Card.Body>
                                                 <LineChartComponent
                                                    data={fatal_trend?.data}
                                                    categories={fatal_trend?.categories}
                                                    yLabel={'Sum Of Fatal Total Count'}
                                                />
                                                </Card.Body>
                                            </Card>
                                        </div>
                                    </div>

                                    <div className='col-lg-6 dash_graph dash_graph_height'>
                                        <Card>
                                            <Card.Header className='d-flex justify-content-between card_header'>
                                                <Card.Header.Title className='w-100'>
                                                    <div className='two_column_title'>
                                                        <h5>
                                                            Avg Audit Score by
                                                            Agent
                                                        </h5>
                                                    </div>
                                                </Card.Header.Title>
                                            </Card.Header>
                                            <Card.Body>
                                            <TableWithBar
                                                    data={agentWise}
                                                    header = {["Agent", "Audits Count","Average of Overall Audit Score",]}
                                                    rowOnClick={agent_redirection}
                                                />
                                            </Card.Body>
                                        </Card>
                                    </div>
                                    <div className='col-lg-6 dash_graph dash_graph_height'>
                                        <Card>
                                            <Card.Header className='d-flex justify-content-between card_header'>
                                                <Card.Header.Title className='w-100'>
                                                    <div className='two_column_title'>
                                                        <h5>
                                                            Audit Score by QA
                                                        </h5>
                                                    </div>
                                                </Card.Header.Title>
                                            </Card.Header>
                                            <Card.Body>
                                            <TableWithBar
                                                    data={qaWise}
                                                    header = {["QA", "Audits Count","Average of Overall Audit Score",]}
                                                    // rowOnClick={qa_redirection}
                                                />
                                            </Card.Body>
                                        </Card>
                                    </div>

                                    <div className='col-lg-12 dash_graph dash_graph_height'>
                                        <Card>
                                            <Card.Header className='d-flex justify-content-between card_header'>
                                                <Card.Header.Title className='w-100'>
                                                    <div className='two_column_title'>
                                                        <h5>
                                                            Program Wise
                                                            Sub-Parameter Count
                                                        </h5>
                                                    </div>
                                                </Card.Header.Title>
                                            </Card.Header>
                                            <Card.Body>
                                                <div className='table_style_comman'>
                                                    <div className='table-responsive'>
                                                        <DynamicTable
                                                            headers={[
                                                                'Sub-Parameter Name',
                                                                'Total Count',
                                                                'Weightage',
                                                            ]}
                                                            widths={[
                                                                '70%',
                                                                '15%',
                                                                '15%',
                                                            ]}
                                                            data={subparameterInfo || []}
                                                        />
                                                    </div>
                                                </div>
                                            </Card.Body>
                                        </Card>
                                    </div>
                                    <VoiceOfCustomer/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ProgramWiseDashboard;
