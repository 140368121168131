import React from 'react';

const ListView = ({ data }) => {
    return (
        <ul className='list_sentimate'>
            {data.map(el => (
                <li>{el}</li>
            ))}
        </ul>
    );
};

export default ListView;
