import App from './App';
import { AnalyticsProvider } from './AnalyticsContext';

const AnalyticsRender = () => {

    return (
        <AnalyticsProvider>
            <App />
        </AnalyticsProvider>
    );
}

export default AnalyticsRender;
