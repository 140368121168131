import { fetchData } from "../../services/config";

export const filterSbu = async (organisationId) => {
    
    const query = {
      measures: [],
      dimensions: ['Sbu.id', 'Sbu.name'],
      filters: []
    }
    if (organisationId && organisationId!='0') {
      query.filters.push({
        dimension: 'Sbu.organisation_id',
        operator: 'equals',
        values: [organisationId],
      });
    }
    return fetchData(query);
  }
  
  export const filterAgentNameAndIds = async (sbu_id, client_id, department_id) => {
    const query = {
      dimensions: ['Agent.agent_id', 'Agent.agent_name'],
      filters: []
    }
    if (sbu_id && sbu_id!='0') {
      query.filters.push({
        dimension: 'Agent.sbu_id',
        operator: 'equals',
        values: [sbu_id],
      });
    }
    if (client_id && client_id!='0') {
      query.filters.push({
        dimension: 'Agent.client_id',
        operator: 'equals',
        values: [client_id],
      });
    }
    if (department_id && department_id!='0') {
      query.filters.push({
        dimension: 'Agent.department_id',
        operator: 'equals',
        values: [department_id],
      });
    }
    return fetchData(query);
  }
  export const filterGeography = async () => {
  
    const query = {
      measures: [],
      dimensions: ['Geo.id', 'Geo.name'],
      filters: [],
    }
    return fetchData(query);
  }

  export const filterCountry = async () => {
  
    const query = {
      measures: [],
      dimensions: ['Country.id', 'Country.name'],
      filters: [],
    }
    return fetchData(query);
  }

  export const filterSite = async () => {
  
    const query = {
      measures: [],
      dimensions: ['Site.id', 'Site.name'],
      filters: [],
    }
    return fetchData(query);
  }

  export const filterClient = async (sbu_id) => {

    const query = {
      dimensions: ['Agent.client_id', 'Agent.client_name'],
      filters: []
    }
    if (sbu_id && sbu_id!='0') {
      query.filters.push({
        dimension: 'Agent.sbu_id',
        operator: 'equals',
        values: [sbu_id],
      });
    }
    
    return fetchData(query);
  }

  export const filterDepartment = async(sbu_id, client_id) => {
    const query = {
      dimensions: ['Agent.department_id', 'Agent.department_name'],
      filters: []
    }
    if (sbu_id && sbu_id!='0') {
      query.filters.push({
        dimension: 'Agent.sbu_id',
        operator: 'equals',
        values: [sbu_id],
      });
    }
    if (client_id && client_id!='0') {
      query.filters.push({
        dimension: 'Agent.client_id',
        operator: 'equals',
        values: [client_id],
      });
    }
    return fetchData(query);
  }