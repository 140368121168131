import { formatData, formatDataFatal, formatDataTotal, transformDataForChart } from "../../../Client/service/PageData/utils";
import { getOrganisationMetricsAgent, getWOWTrendPerDepartment, getWOWTrendPerDepartmentAuditCount, getWOWTrendPerDepartmentFatalCount } from "../ApiService";

export const fetchOrganisationMetrics = async (setAverageAuditScore, setTotalAudits, setTotalFatals, dateTimeRange, department_id, agent_id) => {
    try {
      let agentresult = await getOrganisationMetricsAgent(dateTimeRange, department_id, agent_id);
      agentresult = agentresult[0]
      if (!agentresult || agentresult?.length < 1) {
        setAverageAuditScore(0);
        setTotalAudits(0);
        setTotalFatals(0)
        return;
      }

      setTotalAudits(agentresult?.['Agent.total_reports'] || []);
      setAverageAuditScore(agentresult['Agent.average_audit_score']);
      setTotalFatals(agentresult['Agent.reports_with_fatal'] || 0)
    } catch (err) {
      console.error('Error fetching data:', err);
    }
};

export const fetchWowTrend = async (setWowTrend, dateTimeRange, selectedClient, selectedDepartment, dateParameter, sbu_id, agent_id) => {
    try {
        const result = await getWOWTrendPerDepartment(dateTimeRange, selectedClient, selectedDepartment, dateParameter, sbu_id, agent_id);
        const formattedData = formatData(result, dateParameter);
        const wowTrendData = transformDataForChart(formattedData);
        setWowTrend(wowTrendData);
    } catch (err) {
        console.error('Error fetching Wow Trend:', err);
    }
  };

export const fetchWowTrendAuditCount = async (setWowTrend, dateTimeRange, selectedClient, selectedDepartment, dateParameter, sbu_id, agent_id) => {
    try {
        const result = await getWOWTrendPerDepartmentAuditCount(dateTimeRange, selectedClient, selectedDepartment, dateParameter, sbu_id, agent_id);
        const formattedData = formatDataTotal(result, dateParameter);
        const wowTrendData = transformDataForChart(formattedData);
        setWowTrend(wowTrendData);
    } catch (err) {
        console.error('Error fetching Wow Trend:', err);
    }
};

export const fetchWowTrendFatalCount = async (setWowTrend, dateTimeRange, selectedClient, selectedDepartment, dateParameter, sbu_id, agent_id) => {
    try {
        const result = await getWOWTrendPerDepartmentFatalCount(dateTimeRange, selectedClient, selectedDepartment, dateParameter, sbu_id, agent_id);
        const formattedData = formatDataFatal(result, dateParameter);
        const wowTrendData = transformDataForChart(formattedData);
        setWowTrend(wowTrendData);
    } catch (err) {
        console.error('Error fetching Wow Trend:', err);
    }
}