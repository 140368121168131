const { fetchData } = require("../../../../services/config");

export const getOverallSentiment = async (dateRange, client_id, department_id) => {
    const query = {
        measures: [
          'VocSentimentMetrics.totalPositiveOverallSentiment',
          'VocSentimentMetrics.totalNegativeOverallSentiment',
          'VocSentimentMetrics.totalNeutralOverallSentiment',
        ],
        dimensions: ['VocSentimentMetrics.departmentName'],
        timeDimensions: [
          {
            dimension: 'VocSentimentMetrics.reportDate',
            dateRange: dateRange
          },
        ],
        filters: [],
      };
      
      // Add filters dynamically if needed
      if (client_id && client_id !== '0') {
        query.filters.push({
          dimension: 'VocSentimentMetrics.clientId',
          operator: 'equals',
          values: [client_id],
        });
      }
      
      if (department_id && department_id !== '0') {
        query.filters.push({
          dimension: 'VocSentimentMetrics.departmentId',
          operator: 'equals',
          values: [department_id],
        });
      }
      
    return fetchData(query);
}

export const getAgentSentiment = async (dateRange, client_id, department_id) => {
    const query = {
        measures: [
          'VocSentimentMetrics.totalPositiveAgentSentiment',
          'VocSentimentMetrics.totalNegativeAgentSentiment',
          'VocSentimentMetrics.totalNeutralAgentSentiment',
        ],
        dimensions: ['VocSentimentMetrics.departmentName'],
        timeDimensions: [
          {
            dimension: 'VocSentimentMetrics.reportDate',
            dateRange: dateRange
          },
        ],
        filters: [],
      };
      
      // Add filters dynamically if needed
      if (client_id && client_id !== '0') {
        query.filters.push({
          dimension: 'VocSentimentMetrics.clientId',
          operator: 'equals',
          values: [client_id],
        });
      }
      
      if (department_id && department_id !== '0') {
        query.filters.push({
          dimension: 'VocSentimentMetrics.departmentId',
          operator: 'equals',
          values: [department_id],
        });
      }
      
    return fetchData(query);
}


export const getCustomerSentiment = async (dateRange, client_id, department_id) => {
    const query = {
        measures: [
          'VocSentimentMetrics.totalPositiveProspectSentiment',
          'VocSentimentMetrics.totalNegativeProspectSentiment',
          'VocSentimentMetrics.totalNeutralProspectSentiment',
        ],
        dimensions: ['VocSentimentMetrics.departmentName'],
        timeDimensions: [
          {
            dimension: 'VocSentimentMetrics.reportDate',
            dateRange: dateRange
          },
        ],
        filters: [],
      };
      
      // Add filters dynamically if needed
      if (client_id && client_id !== '0') {
        query.filters.push({
          dimension: 'VocSentimentMetrics.clientId',
          operator: 'equals',
          values: [client_id],
        });
      }
      
      if (department_id && department_id !== '0') {
        query.filters.push({
          dimension: 'VocSentimentMetrics.departmentId',
          operator: 'equals',
          values: [department_id],
        });
      }
      
      
    return fetchData(query);
}

export const getPerformanceMetrics = async (dateRange, client_id, department_id) => {
  const query = {
      measures: [
        'VocPerformanceMetrics.avgTone',
        'VocPerformanceMetrics.avgOpeningScore',
        'VocPerformanceMetrics.avgClosingScore',
        'VocPerformanceMetrics.avgSalesSkills',
        'VocPerformanceMetrics.avgProspectServiceSkills',
        'VocPerformanceMetrics.avgActiveListening',
        'VocPerformanceMetrics.avgEmpathy',
        'VocPerformanceMetrics.avgInterruptions',
        'VocPerformanceMetrics.avgPatience',
        'VocPerformanceMetrics.avgContextAwareness',
        'VocPerformanceMetrics.avgEnunciation',
        'VocPerformanceMetrics.avgVocabulary',
        'VocPerformanceMetrics.avgConciseness'
      ],
      dimensions: ['VocPerformanceMetrics.agentName'],
      timeDimensions: [
        {
          dimension: 'VocPerformanceMetrics.reportDate',
          dateRange: dateRange
        },
      ],
      filters: [],
    };
    
    // Add filters dynamically if needed
    if (client_id && client_id !== '0') {
      query.filters.push({
        dimension: 'VocPerformanceMetrics.clientId',
        operator: 'equals',
        values: [client_id],
      });
    }
    
    if (department_id && department_id !== '0') {
      query.filters.push({
        dimension: 'VocPerformanceMetrics.departmentId',
        operator: 'equals',
        values: [department_id],
      });
    }
    
    
  return fetchData(query);
}

export const getDateWiseSentiment = async (dateRange, client_id, department_id) => {
    const query = {
        measures: [
            'VocSentimentMetrics.totalPositiveOverallSentiment',
            'VocSentimentMetrics.totalNegativeOverallSentiment',
            'VocSentimentMetrics.totalNeutralOverallSentiment',
        ],
        dimensions: ['VocSentimentMetrics.reportWeek'], // we will group by report date to get sentiment per day
        timeDimensions: [
            {
                dimension: 'VocSentimentMetrics.reportWeek',
                dateRange: dateRange
            },
        ],
        filters: [],
    };

    // Add filters dynamically if needed
    if (client_id && client_id !== '0') {
        query.filters.push({
            dimension: 'VocSentimentMetrics.clientId',
            operator: 'equals',
            values: [client_id],
        });
    }

    if (department_id && department_id !== '0') {
        query.filters.push({
            dimension: 'VocSentimentMetrics.departmentId',
            operator: 'equals',
            values: [department_id],
        });
    }
    return fetchData(query);
}

export const getPositiveWordCloud = async (dateRange, client_id, department_id) => {
  const query = {
    measures: ["PositiveWordCloud.totalWordCount", "PositiveWordCloud.avgValue"],
    dimensions: ["PositiveWordCloud.word"],
    timeDimensions: [
      {
          dimension: 'PositiveWordCloud.report_week',
          dateRange: dateRange
      },
    ],
    filters: [],
    order: {
      "PositiveWordCloud.totalWordCount": "desc",
      "PositiveWordCloud.avgValue": "desc"
    },
    "limit": 100
  }
  if (client_id && client_id !== '0') {
    query.filters.push({
        dimension: 'PositiveWordCloud.clientId',
        operator: 'equals',
        values: [client_id],
    });
  }

  if (department_id && department_id !== '0') {
    query.filters.push({
        dimension: 'PositiveWordCloud.departmentId',
        operator: 'equals',
        values: [department_id],
    });
  }
  return fetchData(query);
}

export const getPositiveReasons = async (dateRange, client_id, department_id) => {
  const query = {
    measures: ["PositiveReasons.totalReasonCount", "PositiveReasons.avgValue"],
    dimensions: ["PositiveReasons.reason"],
    timeDimensions: [
      {
          dimension: 'PositiveReasons.report_week',
          dateRange: dateRange
      },
    ],
    filters: [],
    order: {
      "PositiveReasons.totalReasonCount": "desc",
      "PositiveReasons.avgValue": "desc"
    },
    "limit": 5
  }
  if (client_id && client_id !== '0') {
    query.filters.push({
        dimension: 'PositiveReasons.clientId',
        operator: 'equals',
        values: [client_id],
    });
  }

  if (department_id && department_id !== '0') {
    query.filters.push({
        dimension: 'PositiveReasons.departmentId',
        operator: 'equals',
        values: [department_id],
    });
  }
  return fetchData(query);
}

export const getPositiveFeedback = async (dateRange, client_id, department_id) => {
  const query = {
    measures: [],
    dimensions: ["PositiveFeedback.positive_feedback"],
    timeDimensions: [
      {
          dimension: 'PositiveFeedback.report_week',
          dateRange: dateRange
      },
    ],
    filters: [],
    order: {},
    "limit": 10
  }
  if (client_id && client_id !== '0') {
    query.filters.push({
        dimension: 'PositiveFeedback.clientId',
        operator: 'equals',
        values: [client_id],
    });
  }

  if (department_id && department_id !== '0') {
    query.filters.push({
        dimension: 'PositiveFeedback.departmentId',
        operator: 'equals',
        values: [department_id],
    });
  }
  return fetchData(query);
}

export const getNegativeFeedback = async (dateRange, client_id, department_id) => {
  const query = {
    measures: [],
    dimensions: ["NegativeFeedback.negative_feedback"],
    timeDimensions: [
      {
          dimension: 'NegativeFeedback.report_week',
          dateRange: dateRange
      },
    ],
    filters: [],
    order: {},
    "limit": 10
  }
  if (client_id && client_id !== '0') {
    query.filters.push({
        dimension: 'NegativeFeedback.clientId',
        operator: 'equals',
        values: [client_id],
    });
  }

  if (department_id && department_id !== '0') {
    query.filters.push({
        dimension: 'NegativeFeedback.departmentId',
        operator: 'equals',
        values: [department_id],
    });
  }
  return fetchData(query);
}

export const getNegativeReasons = async (dateRange, client_id, department_id) => {
  const query = {
    measures: ["NegativeReasons.totalReasonCount", "NegativeReasons.avgValue"],
    dimensions: ["NegativeReasons.reason"],
    timeDimensions: [
      {
          dimension: 'NegativeReasons.report_week',
          dateRange: dateRange
      },
    ],
    filters: [],
    order: {
      "NegativeReasons.totalReasonCount": "desc",
      "NegativeReasons.avgValue": "desc"
    },
    "limit": 5
  }
  if (client_id && client_id !== '0') {
    query.filters.push({
        dimension: 'NegativeReasons.clientId',
        operator: 'equals',
        values: [client_id],
    });
  }

  if (department_id && department_id !== '0') {
    query.filters.push({
        dimension: 'NegativeReasons.departmentId',
        operator: 'equals',
        values: [department_id],
    });
  }
  return fetchData(query);
}

export const getNegativeWordCloud = async (dateRange, client_id, department_id) => {
  const query = {
    measures: ["NegativeWordCloud.totalWordCount", "NegativeWordCloud.avgValue"],
    dimensions: ["NegativeWordCloud.word"],
    timeDimensions: [
      {
          dimension: 'NegativeWordCloud.report_week',
          dateRange: dateRange
      },
    ],
    filters: [],
    order: {
      "NegativeWordCloud.totalWordCount": "desc",
      "NegativeWordCloud.avgValue": "desc"
    },
    "limit": 100
  }
  if (client_id && client_id !== '0') {
    query.filters.push({
        dimension: 'NegativeWordCloud.clientId',
        operator: 'equals',
        values: [client_id],
    });
  }

  if (department_id && department_id !== '0') {
    query.filters.push({
        dimension: 'NegativeWordCloud.departmentId',
        operator: 'equals',
        values: [department_id],
    });
  }
  return fetchData(query);
}