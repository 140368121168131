import { filterClient, filterDepartment, filterGeography, filterSbu, filterAgentNameAndIds, filterCountry, filterSite } from '../ApiService';
export const fetchSbuFilters = async (setFilterSBU, organisationId) => {
    try {
        const result = await filterSbu(organisationId);
        result.unshift({ 'Sbu.id': '0', 'Sbu.name': 'ALL' });
        setFilterSBU(result);
    } catch (err) {
        console.error('Error fetching SBU filters:', err);
    }
};

export const fetchAgentNamesAndIds = async(setFilterAgentNamesAndIds, sbu_id, client_id, department_id) => {
    try {
        const result = await filterAgentNameAndIds(sbu_id, client_id, department_id);
        result.unshift({ 'Agent.agent_id': '0', 'Agent.agent_name': 'ALL' });
        setFilterAgentNamesAndIds(result);
    } catch (err) {
        console.error('Error fetching Geo filters:', err);
    }
}

export const fetchGeoFilters = async (setFilterGeo) => {
    try {
        const result = await filterGeography(); 
        result.unshift({ 'Geo.id': '0', 'Geo.name': 'ALL' });
        setFilterGeo(result);
    } catch (err) {
        console.error('Error fetching Geo filters:', err);
    }
};

export const fetchCountryFilters = async (setFilterCountry) => {
    try {
        const result = await filterCountry(); 
        result.unshift({ 'Country.id': '0', 'Country.name': 'ALL' });
        setFilterCountry(result);
    } catch (err) {
        console.error('Error fetching Country filters:', err);
    }
};

export const fetchSiteFilters = async (setFilterSite) => {
    try {
        const result = await filterSite(); 
        result.unshift({ 'Site.id': '0', 'Site.name': 'ALL' });
        setFilterSite(result);
    } catch (err) {
        console.error('Error fetching Geo filters:', err);
    }
};

export const fetchClientFilters = async (setClientFilter, sbu_id) => {
  try {
      const result = await filterClient(sbu_id);
      result.unshift({ 'Agent.client_id': '0', 'Agent.client_name': 'ALL' });
      setClientFilter(result);
  } catch (err) {
      console.error('Error fetching Geo filters:', err);
  }
};

export const fetchDepartmentFilters = async (setFilterDepartment, sbu_id, client_id) => {
    try {
        const result = await filterDepartment(sbu_id, client_id);
        result.unshift({ 'Agent.department_id': '0', 'Agent.department_name': 'ALL' });
        setFilterDepartment(result);
    } catch (err) {
        console.error('Error fetching Geo filters:', err);
    }
}

