
import { getAgentDataperClient, getAgentDataperSBU, getClientWiseAuditCounts, getClientWiseAuditScore, getCountryWiseAuditScore, getDepartmentsDataperClient, getOrganisationMetricsAgent, getOrganisationMetricsQA, getQADataperClient, getQADataperSBU, getWowTrendPerClient } from '../ApiService';
import { transformAgentData, transformQAData, formatData, transformDataForChart, transformDepartmentData } from './utils';


export const fetchAgentMetrics = async (settotalAgents, setAverageAuditScore, setTotalAudits, setBusinessCriticalScore, setComplianceCriticalScore, setCustomerCriticalScore, dateTimeRange, organisation_id, selectedSBU, selectedGeo, selectedClient) => {
  try {
    let agentResult = await getOrganisationMetricsAgent(dateTimeRange, organisation_id, selectedSBU, selectedGeo, selectedClient);
    if (agentResult?.length < 1) {
      settotalAgents(0);
      setTotalQa(0)
      setAverageAuditScore(0);
      setTotalAudits(0);
      setBusinessCriticalScore(0);
      setComplianceCriticalScore(0);
      setCustomerCriticalScore(0);

      return;
    }
    agentResult = agentResult[0];
    setTotalAudits(agentResult['Agent.total_reports']);
    setAverageAuditScore(agentResult['Agent.average_audit_score']);
    settotalAgents(agentResult['Agent.total_agents']);
    setBusinessCriticalScore(agentResult['Agent.avg_business_critical'] || 0);
    setComplianceCriticalScore(agentResult['Agent.avg_compliance_critical'] || 0);
    setCustomerCriticalScore(agentResult['Agent.avg_customer_critical'] || 0);
  } catch (err) {
    console.error('Error fetching agent metrics:', err);
  }
};

export const fetchQAMetrics = async (setTotalQa, dateTimeRange, organisation_id, selectedSBU, selectedGeo, selectedClient) => {
  try {
    let qaResult = await getOrganisationMetricsQA(dateTimeRange, organisation_id, selectedSBU, selectedGeo, selectedClient);
    if (qaResult?.length > 0) {
      setTotalQa(qaResult[0]['QA.total_qas']);
    } else {
      setTotalQa(0);
    }
  } catch (err) {
    console.error('Error fetching QA metrics:', err);
  }
};

export  const fetchCountryWiseAuditMetrics = async (setCountryWiseAuditScore, dateTimeRange, organisation_id, selectedSBU, selectedGeo, selectedClient) => {
  try {
    const result = await getCountryWiseAuditScore(dateTimeRange, organisation_id, selectedSBU, selectedGeo, selectedClient);
    setCountryWiseAuditScore(result);
  } catch (err) {
    console.error('Error fetching country-wise audit metrics:', err);
  }
};

export  const fetchClientWiseAuditScore = async (setClientWiseAuditScore, dateTimeRange, organisation_id, selectedSBU, selectedGeo, selectedClient) => {
  try {
    const result = await getClientWiseAuditScore(dateTimeRange, organisation_id, selectedSBU, selectedGeo, selectedClient);
    setClientWiseAuditScore(result);
  } catch (err) {
    console.error('Error fetching country-wise audit metrics:', err);
  }
};

export const fetchWowTrend = async (setWowTrend, dateTimeRange, organisation_id, selectedSBU, selectedGeo, selectedClient, dateParameter) => {
  try {
      const result = await getWowTrendPerClient(dateTimeRange, organisation_id, selectedSBU, selectedGeo, selectedClient, dateParameter);
      const formattedData = formatData(result, dateParameter);
      const wowTrendData = transformDataForChart(formattedData);
      setWowTrend(wowTrendData);
  } catch (err) {
      console.error('Error fetching Wow Trend:', err);
  }
};


export const fetchClientWiseAuditCount = async (setClientWiseAuditCount, dateTimeRange, organisation_id, selectedSBU, selectedGeo, selectedClient) => {
  try {
      const result = await getClientWiseAuditCounts(dateTimeRange, organisation_id, selectedSBU, selectedGeo, selectedClient);
      setClientWiseAuditCount(result);
    } catch (err) {
      console.error('Error fetching data:', err);
    }
}


export const fetchDepartmentsByClientData = async (setTotalDepartmentsByClient, dateTimeRange, organisation_id, selectedSBU, selectedGeo, selectedClient) => {
  try {
      const result = await getDepartmentsDataperClient(dateTimeRange, organisation_id, selectedSBU, selectedGeo, selectedClient);
      setTotalDepartmentsByClient(transformDepartmentData(result));
    } catch (err) {
      console.error('Error fetching data:', err);
    }
}

export const fetchQAByClientData = async (setTotalQasByClient, dateTimeRange, organisation_id, selectedSBU, selectedGeo, selectedClient) => {
  try {
      const result = await getQADataperClient(dateTimeRange, organisation_id, selectedSBU, selectedGeo, selectedClient);
      setTotalQasByClient(transformQAData(result));
    } catch (err) {
      console.error('Error fetching data:', err);
    }
}

export const fetchAgentsByClientData = async (setTotalAgentsByClient, dateTimeRange, organisation_id, selectedSBU, selectedGeo, selectedClient) => {
  try {
      const result = await getAgentDataperClient(dateTimeRange, organisation_id, selectedSBU, selectedGeo, selectedClient);
      setTotalAgentsByClient(transformAgentData(result));
    } catch (err) {
      console.error('Error fetching data:', err);
    }
}