/* eslint-disable react/display-name */
import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { Form, Row, Col, Button } from 'react-bootstrap';
import ObsStructure from '../Audit/ObsStructure';
import Select from 'react-select';
import { useTranslation } from 'react-i18next';

function BulkUploadOptions({
    dashboardRole,
    designation = [],
    showUserEditModal,
    userDataForEdit={},
    handleChangeOption,
    optionBulkUser,
    validated,
    bulkUserData,
    obsForOrganization
}) {
    const userData = useRef(showUserEditModal?userDataForEdit:{});

    const [state,setState] =useState(false);
    const { t } = useTranslation();

    const handleChangeSingleUser = e => {
        const { name, value } = e.target;
        const temp = userData.current;
        temp[name] = value;
        if(['user_role','organistation_roles', 'Supervised_By'].includes(name)){
            setState(!state)
        }
        userData.current=temp;
        
    };

    const CustomFormControl = React.forwardRef(({ name, isNotValid }, ref) => {
        return (
            <ObsStructure
                selectedDepartment={{
                    name: '',
                    value: optionBulkUser?.departments,
                }}
                setSelectedDepartment={value => {
                        handleChangeSingleUser({
                            target: {
                                name: 'departments',
                                value: value.value,
                            },
                        });
                        handleChangeOption({
                            target: {
                                name: 'departments',
                                value: value.value,
                            },
                        });
                }}
                multiselect={true}
                allView={true}
                selectPlaceholder={true}
                id={isNotValid ? "obsParam" : ""}
                showPartiallySelected={false}
                disabled={bulkUserData.length}
                obsForOrganization = {obsForOrganization}
            />
        );
    });

    const CustomFormControlForDesignation = React.forwardRef(
        ({ defaultValue, isNotValid, id, name }, ref) => {
            const selectedRole = designation?.find(role => role.id === defaultValue);
            const selectedOption = selectedRole
                ? {
                      value: selectedRole.id,
                      label: selectedRole.Role_Name,
                  }
                : null;

            return (
                <Select
                    options={designation.map(des => ({
                        value: des.id,
                        label: des.Role_Name,
                    }))}
                    defaultValue={selectedOption}
                    onChange={selectedOption => {
                        handleChangeSingleUser({
                            target: {
                                name,
                                value: selectedOption?.value,
                            },
                        });
                        handleChangeOption({
                            target: {
                                name,
                                value: selectedOption?.value,
                            },
                        });
                    }}
                    isSearchable
                    placeholder='Select'
                    className={isNotValid ? 'invalid' : ''}
                    id={id}
                    isDisabled={bulkUserData.length}
                />
            );
        }
    );

    const CustomFormControlForDashboardRole = React.forwardRef(
        ({ defaultValue, isNotValid, id, name }, ref) => {
            const selectedRole = dashboardRole?.find(role => role.id === defaultValue); 
            const selectedOption = selectedRole
                ? {
                      value: selectedRole.id,
                      label: selectedRole.attributes.role,
                  }
                : null;
            return (
                <Select
                    options={dashboardRole.map(role => ({
                        value: role.id,
                        label: role.attributes.role,
                    }))}
                    defaultValue={selectedOption}
                    onChange={selectedOption => {
                        handleChangeSingleUser({
                            target: {
                                name,
                                value: selectedOption?.value,
                            },
                        });
                        handleChangeOption({
                            target: {
                                name,
                                value: selectedOption?.value,
                            },
                        });
                    }}
                    isSearchable
                    placeholder='Select'
                    className={isNotValid ? 'invalid' : ''}
                    id={id}
                    isDisabled={bulkUserData.length}
                />
            );
        }
    );

    return (
        <Form validated={validated} className='form_style_comman'>
            <Row>
                <Col xs={12} md={4} sm={6}>
                    <Form.Group className='form-group'>
                        <Form.Label>{t('LandingScreen.Designation')}  <sup className='sup_manatory text-danger'>*</sup></Form.Label>
                        <Form.Control
                            as={CustomFormControlForDesignation}
                            id='searchParam'
                            defaultValue={optionBulkUser?.organistation_roles}
                            isNotValid={optionBulkUser?.organistation_roles ? false : true}
                            name='organistation_roles'
                            required
                        />
                        <Form.Control.Feedback type='invalid' id='searchError'>
                            {t('LandingScreen.Organistation Roles is required')}
                        </Form.Control.Feedback>
                    </Form.Group>
                </Col>

                <Col xs={12} md={4} sm={6} className='obs_horizontal_scrollbar'>
                    <Form.Group className='form-group'>
                        <Form.Label>{t('Common.OBS')} <sup className='sup_manatory text-danger'>*</sup> </Form.Label>
                        <Form.Control
                            as={CustomFormControl}
                            placeholder='Enter OBS'
                            required
                            name='departments'
                            isNotValid={optionBulkUser?.departments ? false : true}

                            
                        />
                        <Form.Control.Feedback type='invalid' id='obsError'>
                            {t('LandingScreen.OBS is required')}
                        </Form.Control.Feedback>
                    </Form.Group>
                </Col>

                <Col xs={12} md={4} sm={6}>
                    <Form.Group className='form-group'>
                        <Form.Label>{t('LandingScreen.Role')}  <sup className='sup_manatory text-danger'>*</sup></Form.Label>
                        <Form.Control
                            as={CustomFormControlForDashboardRole}
                            id='searchParam'
                            defaultValue={optionBulkUser?.user_role}
                            isNotValid={optionBulkUser?.user_role ? false : true}
                            name='user_role'
                            required
                        />
                        <Form.Control.Feedback type='invalid' id='searchError'>
                            {t('LandingScreen.Dashboard Role is required')}
                        </Form.Control.Feedback>
                    </Form.Group>
                </Col>
            </Row>
        </Form>
    );
}

export default BulkUploadOptions;
