import React, { useState, useEffect, lazy } from 'react';
import Card from '../../../bootstrap/card';
import ApexChart from 'react-apexcharts';
import Chart from 'react-apexcharts';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import DateRangePickerComp from '../../../../views/modules/social/AuditSheet/DateRangePickerComp';
import { DateRangeFunction } from '../../../../CommonLogic';
import DataTable from '../../../DataTable';
import LineChartComponent from '../../Widgets/MultiLineGraph';
import VerticalBarGraphWithColors from '../../Widgets/VerticalBarGraphWithColors';
import DynamicTable from '../../Widgets/Table/TableWithSorting';
import { useAnalyticsContext } from '../../AnalyticsContext';

const QaDashboard = () => {
    const {handleBackClick} = useAnalyticsContext()
    //chart 2
    const chart2 = {
        options: {
            chart: {
                type: 'bar',
                height: 150,
                toolbar: {
                    show: false,
                },
            },
            plotOptions: {
                bar: {
                    horizontal: false,
                    columnWidth: '30%',
                    distributed: true, // Enable color distribution for individual bars
                    dataLabels: {
                        position: 'top',
                    },
                },
            },
            colors: ['#072B15', '#126E36', '#23CE6B'], // Array of colors for each bar
            dataLabels: {
                enabled: false,
                offsetX: -6,
                style: {
                    fontSize: '12px',
                },
            },
            stroke: {
                show: true,
                width: 0,
            },
            tooltip: {
                enabled: true,
            },
            xaxis: {
                categories: ['IB-Chat', 'Ameriflex Voice', 'Ameriflex Chat'],
                title: {
                    text: 'Departments',
                    style: {
                        fontFamily: 'Inter, sans-serif',
                        fontSize: '14px',
                        fontWeight: 400,
                        lineHeight: '13px',
                        color: '#344054',
                    },
                },
            },
            yaxis: {
                title: {
                    text: 'Avg Audit Score',
                    style: {
                        fontFamily: 'Inter, sans-serif',
                        fontSize: '14px',
                        fontWeight: 400,
                        lineHeight: '13px',
                        color: '#344054',
                    },
                },
            },
            legend: {
                position: 'top',
                horizontalAlign: 'left',
                markers: {
                    width: 12,
                    height: 12,
                    radius: 12, // Makes the markers circular
                },
                itemMargin: {
                    horizontal: 10,
                    vertical: 5,
                },
            },
        },
        series: [
            {
                name: 'Audit Done',
                data: [44, 55, 41], // Data for each category
            },
        ],
    };

    // line chart 2
    const lineChart2 = {
        chart: {
            type: 'line',
            height: 350,
            toolbar: {
                show: false, // Hides the toolbar
                dashArray: 5,
            },
        },
        stroke: {
            curve: 'smooth', // Smooth line for all series
            width: 2,
        },
        xaxis: {
            categories: [
                '01-08 Sep 24',
                '09-16 Sep 24',
                '17-24 Sep 24',
                '25-1 Oct 24',
                '2-9 Oct 24',
            ], // X-axis labels
            title: {
                text: 'Audit Date',
                style: {
                    fontSize: '14px',
                    fontWeight: '500',
                    // color: '#344054',
                },
            },
        },
        yaxis: {
            title: {
                text: 'No. of Audit',
                style: {
                    fontSize: '14px',
                    fontWeight: '500',
                    // color: '#344054',
                },
            },
        },
        tooltip: {
            enabled: true,
        },
        colors: [
            '#072B15',
            '#0B4522',
            '#126E36',
            '#1DB056',
            '#23CE6B',
            '#27E376',
            '#2FFF8E',
            '#84FFBC',
        ], // Colors for each line
        legend: {
            position: 'top',
            horizontalAlign: 'left',
            offsetX: 10, // Adjusts the X-offset of the legend
            offsetY: 10,
        },
    };

    const lineChart2Series = [
        {
            name: 'IB-Chat',
            data: [10, 41, 35, 51, 49], // Data for first line
        },
        {
            name: 'PH SEA_Ameriflex PSR Voice',
            data: [23, 33, 55, 67, 87], // Data for second line
        },
        {
            name: 'PH SEA_Ameriflex PSR Chat',
            data: [30, 25, 43, 55, 66], // Data for third line
        },
    ];

    // Line chart 3
    const lineChart3 = {
        chart: {
            type: 'line',
            height: 350,
            toolbar: {
                show: false, // Hides the toolbar
                dashArray: 5,
            },
        },
        stroke: {
            curve: 'smooth', // Smooth line for all series
            width: 2,
        },
        xaxis: {
            categories: [
                '01-08 Sep 24',
                '09-16 Sep 24',
                '17-24 Sep 24',
                '25-1 Oct 24',
                '2-9 Oct 24',
            ], // X-axis labels
            title: {
                text: 'Audit Date',
                style: {
                    fontSize: '14px',
                    fontWeight: '500',
                    // color: '#344054',
                },
            },
        },
        yaxis: {
            title: {
                text: 'Count',
                style: {
                    fontSize: '14px',
                    fontWeight: '500',
                    // color: '#344054',
                },
            },
        },
        tooltip: {
            enabled: true,
        },
        colors: [
            '#072B15',
            '#0B4522',
            '#126E36',
            '#1DB056',
            '#23CE6B',
            '#27E376',
            '#2FFF8E',
            '#84FFBC',
        ], // Colors for each line
        legend: {
            position: 'top',
            horizontalAlign: 'left',
            offsetX: 10, // Adjusts the X-offset of the legend
            offsetY: 10,
        },
    };

    const lineChart3Series = [
        {
            name: 'IB-Chat',
            data: [10, 41, 35, 51, 49], // Data for first line
        },
        {
            name: 'PH SEA_Ameriflex PSR Voice',
            data: [23, 33, 55, 67, 87], // Data for second line
        },
        {
            name: 'PH SEA_Ameriflex PSR Chat',
            data: [30, 25, 43, 55, 66], // Data for third line
        },
    ];

    // rebuttal table data
    const TableRebuttal = {
        columns: [
            { title: 'QA Name' },
            { title: 'Audit Count' },
            { title: 'Avg Audit Score' },
        ],
        data: [
            ['Kaiya Gouse', '4', '80%'],
            ['Kaiya Gouse', '4', '70%'],
            ['Kaiya Gouse', '4', '80%'],
            ['Kaiya Gouse', '4', '65%'],
            ['Kaiya Gouse', '4', '77%'],
            ['Kaiya Gouse', '4', '88%'],
            ['Kaiya Gouse', '4', '67%'],
        ],
    };

    return (
        <>
            <div className='page-wrapper'>
                <div className='content pb-0'>
                    <div className='row'>
                        <div className='col-sm-12 content_wrap_style'>
                            <div className='content_wrap_comman'>
                            <div className='page_title_box'>
                                    <h3 className='page_title'>
                                        <span className='pe-3'><button className='btn btn_transparent'>
                                            <img src='/images/back_arrow.svg' alt='back button' onClick={handleBackClick}/>
                                        </button></span>
                                        QA Dashboard
                                    </h3>
                                </div>
                                <form className='form_style_comman'>
                                    <div className='row'>
                                        <div className='col-lg-3 form-group'>
                                            <label>QA</label>
                                            <select className='form-control'>
                                                <option>QA Items</option>
                                                <option>QA Items</option>
                                                <option>QA Items</option>
                                            </select>
                                        </div>
                                        <div className='col-lg-3 form-group'>
                                            <label>Date Range</label>
                                            <DateRangePickerComp
                                                defaultRange={DateRangeFunction(
                                                    0,
                                                    6
                                                )}
                                                onDateChange={e => e}
                                                disabled={false}
                                            />
                                        </div>
                                    </div>
                                </form>

                                {/* graph */}
                                <div className='row'>
                                    <div className='col-lg-12'>
                                        <div className='dash_graph'>
                                            <Card>
                                                <Card.Header className='d-flex justify-content-between card_header'>
                                                    <Card.Header.Title className='w-100'>
                                                        <div className='two_column_title'>
                                                            <h5>
                                                                No. of Audit
                                                            </h5>
                                                        </div>
                                                    </Card.Header.Title>
                                                </Card.Header>
                                                <Card.Body>
                                                    <LineChartComponent
                                                        data={[
                                                            {
                                                                name: 'BFSI',
                                                                data: [
                                                                    10, 41, 35,
                                                                    51, 49, 62,
                                                                    69,
                                                                ],
                                                            },
                                                            {
                                                                name: 'BMS',
                                                                data: [
                                                                    23, 33, 55,
                                                                    67, 87, 76,
                                                                    55,
                                                                ],
                                                            },
                                                            {
                                                                name: 'HCL',
                                                                data: [
                                                                    30, 25, 43,
                                                                    55, 66, 78,
                                                                    93,
                                                                ],
                                                            },
                                                            {
                                                                name: 'HTG',
                                                                data: [
                                                                    20, 45, 53,
                                                                    55, 66, 78,
                                                                    93,
                                                                ],
                                                            },
                                                        ]}
                                                        categories={[
                                                            '01-08 Sep 24',
                                                            '09-16 Sep 24',
                                                            '17-24 Sep 24',
                                                            '25-1 Oct 24',
                                                            '2-9 Oct 24',
                                                            '10-17 Oct 24',
                                                            '18-25 Oct 24',
                                                        ]}
                                                        xLabel={'lkhdslkhfkds'}
                                                        yLabel={'hdslhufs'}
                                                    />
                                                </Card.Body>
                                            </Card>
                                        </div>
                                    </div>
                                    <div className='col-lg-6 dash_graph dash_graph_height'>
                                        <Card>
                                            <Card.Header className='d-flex justify-content-between card_header'>
                                                <Card.Header.Title className='w-100'>
                                                    <div className='two_column_title'>
                                                        <h5>
                                                            QA Wise SBU -
                                                            Overall Audit Score
                                                        </h5>
                                                    </div>
                                                </Card.Header.Title>
                                            </Card.Header>
                                            <Card.Body className='text-center'>
                                                <VerticalBarGraphWithColors
                                                    data={[
                                                        {
                                                            name: 'Audit Done',
                                                            data: [44, 55, 41], // Data for each category
                                                        },
                                                    ]}
                                                    xLabel={'Process'}
                                                    yLabel={'Avg Audit Score'}
                                                    categories={[
                                                        'IB-Chat',
                                                        'Ameriflex Voice',
                                                        'Ameriflex Chat',
                                                    ]}
                                                />
                                            </Card.Body>
                                        </Card>
                                    </div>
                                    <div className='col-lg-6'>
                                        <div className='dash_graph'>
                                            <Card>
                                                <Card.Header className='d-flex justify-content-between card_header'>
                                                    <Card.Header.Title className='w-100'>
                                                        <div className='two_column_title'>
                                                            <h5>
                                                                QA Wise Date
                                                                Wise - Overall
                                                                Audit Score
                                                            </h5>
                                                        </div>
                                                    </Card.Header.Title>
                                                </Card.Header>
                                                <Card.Body>
                                                    <LineChartComponent
                                                        data={[
                                                            {
                                                                name: 'BFSI',
                                                                data: [
                                                                    10, 41, 35,
                                                                    51, 49, 62,
                                                                    69,
                                                                ],
                                                            },
                                                            {
                                                                name: 'BMS',
                                                                data: [
                                                                    23, 33, 55,
                                                                    67, 87, 76,
                                                                    55,
                                                                ],
                                                            },
                                                            {
                                                                name: 'HCL',
                                                                data: [
                                                                    30, 25, 43,
                                                                    55, 66, 78,
                                                                    93,
                                                                ],
                                                            },
                                                            {
                                                                name: 'HTG',
                                                                data: [
                                                                    20, 45, 53,
                                                                    55, 66, 78,
                                                                    93,
                                                                ],
                                                            },
                                                        ]}
                                                        categories={[
                                                            '01-08 Sep 24',
                                                            '09-16 Sep 24',
                                                            '17-24 Sep 24',
                                                            '25-1 Oct 24',
                                                            '2-9 Oct 24',
                                                            '10-17 Oct 24',
                                                            '18-25 Oct 24',
                                                        ]}
                                                        xLabel={'lkhdslkhfkds'}
                                                        yLabel={'hdslhufs'}
                                                    />
                                                </Card.Body>
                                            </Card>
                                        </div>
                                    </div>
                                    <div className='col-lg-12 dash_graph dash_graph_height'>
                                        <Card>
                                            <Card.Header className='d-flex justify-content-between card_header'>
                                                <Card.Header.Title className='w-100'>
                                                    <div className='two_column_title'>
                                                        <h5>
                                                            QA Wise Average
                                                            Audit Score
                                                        </h5>
                                                    </div>
                                                </Card.Header.Title>
                                            </Card.Header>
                                            <Card.Body>
                                                <div className='table_style_comman'>
                                                    <div className='table-responsive'>
                                                        <DynamicTable
                                                            headers={[
                                                                'Agent Name',
                                                                'Audit Count',
                                                                'Avg Audit Count',
                                                            ]}
                                                            widths={[
                                                                '70%',
                                                                '15%',
                                                                '15%',
                                                            ]}
                                                            data={[
                                                                {
                                                                    'Agent Name':
                                                                        'Opening',
                                                                    'Audit Count':
                                                                        '82%',
                                                                    'Avg Audit Count':
                                                                        '82%',
                                                                },
                                                                {
                                                                    'Agent Name':
                                                                        'Tone',
                                                                    'Audit Count':
                                                                        '12%',
                                                                    'Avg Audit Count':
                                                                        '12%',
                                                                },
                                                                {
                                                                    'Agent Name':
                                                                        'Rate of Speech',
                                                                    'Audit Count':
                                                                        '58%',
                                                                    'Avg Audit Count':
                                                                        '58%',
                                                                },
                                                                {
                                                                    'Agent Name':
                                                                        'Enunciation',
                                                                    'Audit Count':
                                                                        '82%',
                                                                    'Avg Audit Count':
                                                                        '82%',
                                                                },
                                                                {
                                                                    'Agent Name':
                                                                        'Vocabulary',
                                                                    'Audit Count':
                                                                        '58%',
                                                                    'Avg Audit Count':
                                                                        '58%',
                                                                },
                                                                {
                                                                    'Agent Name':
                                                                        'Product Knowledge',
                                                                    'Audit Count':
                                                                        '58%',
                                                                    'Avg Audit Count':
                                                                        '58%',
                                                                },
                                                                {
                                                                    'Agent Name':
                                                                        'Process Knowledge',
                                                                    'Audit Count':
                                                                        '58%',
                                                                    'Avg Audit Count':
                                                                        '58%',
                                                                },
                                                                {
                                                                    'Agent Name':
                                                                        'Sales Skills',
                                                                    'Audit Count':
                                                                        '58%',
                                                                    'Avg Audit Count':
                                                                        '58%',
                                                                },
                                                                {
                                                                    'Agent Name':
                                                                        'Customer Service Skills',
                                                                    'Audit Count':
                                                                        '87%',
                                                                    'Avg Audit Count':
                                                                        '87%',
                                                                },
                                                            ]}
                                                        />
                                                    </div>
                                                </div>
                                            </Card.Body>
                                        </Card>
                                    </div>
                                    <div className='col-lg-12 dash_graph dash_graph_height'>
                                        <Card>
                                            <Card.Header className='d-flex justify-content-between card_header'>
                                                <Card.Header.Title className='w-100'>
                                                    <div className='two_column_title'>
                                                        <h5>
                                                            QA Wise
                                                            Sub-Parameter Count
                                                        </h5>
                                                    </div>
                                                </Card.Header.Title>
                                            </Card.Header>
                                            <Card.Body className=''>
                                                <div className='table_style_comman'>
                                                    <div className='table-responsive'>
                                                        <DynamicTable
                                                            headers={[
                                                                'Sub-Parameters',
                                                                'Total Count',
                                                                'Average Score',
                                                            ]}
                                                            widths={[
                                                                '70%',
                                                                '15%',
                                                                '15%',
                                                            ]}
                                                            data={[
                                                                {
                                                                    'Sub-Parameters':
                                                                        'Opening',
                                                                    'Total Count':
                                                                        '82%',
                                                                    'Average Score':
                                                                        '82%',
                                                                },
                                                                {
                                                                    'Sub-Parameters':
                                                                        'Tone',
                                                                    'Total Count':
                                                                        '12%',
                                                                    'Average Score':
                                                                        '12%',
                                                                },
                                                                {
                                                                    'Sub-Parameters':
                                                                        'Rate of Speech',
                                                                    'Total Count':
                                                                        '58%',
                                                                    'Average Score':
                                                                        '58%',
                                                                },
                                                                {
                                                                    'Sub-Parameters':
                                                                        'Enunciation',
                                                                    'Total Count':
                                                                        '82%',
                                                                    'Average Score':
                                                                        '82%',
                                                                },
                                                                {
                                                                    'Sub-Parameters':
                                                                        'Vocabulary',
                                                                    'Total Count':
                                                                        '58%',
                                                                    'Average Score':
                                                                        '58%',
                                                                },
                                                                {
                                                                    'Sub-Parameters':
                                                                        'Product Knowledge',
                                                                    'Total Count':
                                                                        '58%',
                                                                    'Average Score':
                                                                        '58%',
                                                                },
                                                                {
                                                                    'Sub-Parameters':
                                                                        'Process Knowledge',
                                                                    'Total Count':
                                                                        '58%',
                                                                    'Average Score':
                                                                        '58%',
                                                                },
                                                                {
                                                                    'Sub-Parameters':
                                                                        'Sales Skills',
                                                                    'Total Count':
                                                                        '58%',
                                                                    'Average Score':
                                                                        '58%',
                                                                },
                                                                {
                                                                    'Sub-Parameters':
                                                                        'Customer Service Skills',
                                                                    'Total Count':
                                                                        '87%',
                                                                    'Average Score':
                                                                        '87%',
                                                                },
                                                            ]}
                                                        />
                                                    </div>
                                                </div>
                                            </Card.Body>
                                        </Card>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default QaDashboard;
