import { useEffect, useState } from "react";
import { useAnalyticsContext } from "../AnalyticsContext";
import { fetchClientFilters, fetchDepartmentFilters, fetchGeoFilters, fetchSbuFilters, fetchAgentNamesAndIds, fetchCountryFilters, fetchSiteFilters } from "./service/PageData/Page";
import DateRangePickerComp from "../../../views/modules/social/AuditSheet/DateRangePickerComp";
import { filterClientData } from "./service/ApiService";

const Filter = ({ showDate = false, showSBU = false, showGeo = false, showClient = false, showDepartment = false, showAgent = false, showCountry = false, showSite = false,}) => {
    const { analyticsData, userData, setDateTimeRange, setFilterData } = useAnalyticsContext();
    const { datePickerRange, selectedFilters } = analyticsData;
    const { organisation_id } = userData;
    const [filterSBU, setFilterSBU] = useState([]);
    const [filterAgentNamesAndIds, setFilterAgentNamesAndIds] = useState([]);
    const [filterGeo, setFilterGeo] = useState([]);
    const [filterCountry, setFilterCountry] = useState([]);
    const [filterSite, setFilterSite] = useState([]);
    const [filterClient, setFilterClient] = useState([])
    const [filterDepartment, setFilterDepartment] = useState([])
    useEffect(() => {
        fetchSbuFilters(setFilterSBU, organisation_id);
        fetchGeoFilters(setFilterGeo, organisation_id);
        fetchCountryFilters(setFilterCountry, organisation_id);
        fetchSiteFilters(setFilterSite, organisation_id);
        fetchClientFilters(setFilterClient, selectedFilters['sbu']?.id)
        fetchDepartmentFilters(setFilterDepartment, selectedFilters['sbu']?.id, selectedFilters['client']?.id)
        fetchAgentNamesAndIds(setFilterAgentNamesAndIds, selectedFilters['sbu']?.id, selectedFilters['client']?.id, selectedFilters['department']?.id)
    }, [organisation_id, selectedFilters]);

    const handleAgentNameChange = (event) => {
        const selectedName = event.target.value;
        const selectedAgentName = filterAgentNamesAndIds.find((sbu) => sbu["Agent.agent_name"] === selectedName);
        setFilterData('agent', {id: selectedAgentName["Agent.agent_id"], name: selectedAgentName["Agent.agent_name"]});
    };

    // const handleAgentIdChange = (event) => {
    //     const selectedName = event.target.value;
    //     const selectedSbu = filterSBU.find((sbu) => sbu["Sbu.name"] === selectedName);
    //     setFilterData('sbu', {id: selectedSbu["Sbu.id"], name: selectedSbu["Sbu.name"]});
    //     setFilterData('client', {id: '0', name: 'ALL'});
    //     setFilterData('department', {id: '0', name: 'ALL'});
    // };

    const handleSbuChange = (event) => {
        const selectedName = event.target.value;
        const selectedSbu = filterSBU.find((sbu) => sbu["Sbu.name"] === selectedName);
        setFilterData('sbu', {id: selectedSbu["Sbu.id"], name: selectedSbu["Sbu.name"]});
        setFilterData('client', {id: '0', name: 'ALL'});
        setFilterData('department', {id: '0', name: 'ALL'});
    };

    const handleGeoChange = (event) => {
        const selectedName = event.target.value;
        const selectedGeo = filterGeo.find((geo) => geo["Geo.name"] === selectedName);
        setFilterData('geography', {id: selectedGeo["Geo.id"], name: selectedGeo["Geo.name"]});
    };

    const handleCountryChange = (event) => {
        const selectedName = event.target.value;
        const selectedGeo = filterCountry.find((geo) => geo["Country.name"] === selectedName);
        setFilterData('country', {id: selectedGeo["Country.id"], name: selectedGeo["Country.name"]});
    };

    const handleSiteChange = (event) => {
        const selectedName = event.target.value;
        const selectedGeo = filterSite.find((geo) => geo["Site.name"] === selectedName);
        setFilterData('site', {id: selectedGeo["Site.id"], name: selectedGeo["Site.name"]});
    };

    const handleClientChange = (event) => {
        const selectedName = event.target.value;
        const selectedClient = filterClient.find((client) => client["Agent.client_name"] === selectedName);
        setFilterData('client', {id: selectedClient["Agent.client_id"], name: selectedClient["Agent.client_name"]});
        setFilterData('department', {id: '0', name: 'ALL'});
    }

    const handleDepartmentChange = (event) => {
        const selectedName = event.target.value;
        const selectedDepartment = filterDepartment.find((department) => department["Agent.department_name"] === selectedName);
        setFilterData('department', {id: selectedDepartment["Agent.department_id"], name: selectedDepartment["Agent.department_name"]});
    }

    return (
        <form className="form_style_comman mb-1">
            <div className="row">
                <div
                    className="col-lg-3 form-group"
                    style={{ display: showDate ? "block" : "none" }}
                >
                    <label>Date Range</label>
                    <DateRangePickerComp
                        defaultRange={datePickerRange}
                        onDateChange={(e) => {
                            const startDate = new Date(e.startDate).toLocaleDateString("en-CA");
                            const endDate = new Date(e.endDate).toLocaleDateString("en-CA");
                            setDateTimeRange([startDate, endDate]);
                        }}
                        disabled={false}
                    />
                </div>
                <div
                    className="col-lg-3 form-group"
                    style={{ display: showSBU ? "block" : "none" }}
                >
                    <label>SBU</label>
                    <select className="form-control" onChange={handleSbuChange} value={selectedFilters['sbu']?.name}>
                        {filterSBU.map((obj) => (
                            <option key={obj["Sbu.id"]} value={obj["Sbu.name"]}>
                                {obj["Sbu.name"]}
                            </option>
                        ))}
                    </select>
                </div>
                <div
                    className="col-lg-3 form-group"
                    style={{ display: showAgent ? "block" : "none" }}
                >
                    <label>Agent Names</label>
                    <select className="form-control" onChange={handleAgentNameChange} value={selectedFilters['agent']?.name}>
                        {filterAgentNamesAndIds.map((obj) => (
                            <option key={obj["Agent.agent_id"]} value={obj["Agent.agent_name"]}>
                                {obj["Agent.agent_name"]}
                            </option>
                        ))}
                    </select>
                </div>
                {/* <div
                    className="col-lg-3 form-group"
                    style={{ display: showSBU ? "block" : "none" }}
                >
                    <label>Agent Ids</label>
                    <select className="form-control" onChange={handleSbuChange} value={selectedFilters['agent_id']?.name}>
                        {filterSBU.map((obj) => (
                            <option key={obj["Agent.agent_id"]} value={obj["Agent.agent_id"]}>
                                {obj["Agent.agent_id"]}
                            </option>
                        ))}
                    </select>
                </div> */}
                <div
                    className="col-lg-3 form-group"
                    style={{ display: showGeo ? "block" : "none" }}
                >
                    <label>Geography</label>
                    <select className="form-control" onChange={handleGeoChange} value={selectedFilters['geography']?.name}>
                        {filterGeo.map((obj) => (
                            <option key={obj["Geo.id"]} value={obj["Geo.name"]}>
                                {obj["Geo.name"]}
                            </option>
                        ))}
                    </select>
                </div>

                <div
                    className="col-lg-3 form-group"
                    style={{ display: showCountry ? "block" : "none" }}
                >
                    <label>Country</label>
                    <select className="form-control" onChange={handleCountryChange} value={selectedFilters['country']?.name}>
                        {filterCountry.map((obj) => (
                            <option key={obj["Country.id"]} value={obj["Country.name"]}>
                                {obj["Country.name"]}
                            </option>
                        ))}
                    </select>
                </div>

                <div
                    className="col-lg-3 form-group"
                    style={{ display: showSite ? "block" : "none" }}
                >
                    <label>Location</label>
                    <select className="form-control" onChange={handleSiteChange} value={selectedFilters['site']?.name}>
                        {filterSite.map((obj) => (
                            <option key={obj["Site.id"]} value={obj["Site.name"]}>
                                {obj["Site.name"]}
                            </option>
                        ))}
                    </select>
                </div>

                <div className='col-lg-2 form-group' style={{ display: showClient ? "block" : "none" }}>
                                            <label>Client</label>
                                            <select className="form-control" onChange={handleClientChange} value={selectedFilters['client']?.name}>
                                                {filterClient.map((obj) => {
                                                return (
                                                    <option key={obj['Agent.client_id']} value={obj['Agent.client_name']}>
                                                    {obj['Agent.client_name']}
                                                    </option>
                                                );
                                                })}
                                            </select>
                                        </div>

                                        <div
                    className="col-lg-3 form-group"
                    style={{ display: showDepartment ? "block" : "none" }}
                >
                    <label>Program</label>
                    <select className="form-control" onChange={handleDepartmentChange} value={selectedFilters['department']?.name}>
                        {filterDepartment.map((obj) => (
                            <option key={obj["Agent.department_id"]} value={obj["Agent.department_name"]}>
                                {obj["Agent.department_name"]}
                            </option>
                        ))}
                    </select>
                </div>
            </div>
        </form>
    );
};

export default Filter;
