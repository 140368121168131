import React, { useState } from "react";
import Chart from 'react-apexcharts';
import "../reporting_style.css";

const DonutChartWithScorePercentage = ({ data, categories, totalCount = 0 }) => {
    const totalQAbySBU = {
        chart: {
            type: 'donut',
        },
        labels: categories,
        colors: ['#072B15', '#0B4522', '#126E36', '#1DB056', '#23CE6B', '#27E376', '#2FFF8E', '#84FFBC'], // Custom colors for each segment
        legend: {
            position: 'left',
            fontSize: '12px',
            markers: {
                width: 10,
                height: 10,
            },
            itemMargin: {
                vertical: 7, // Margin between legend items
            },
            formatter: (label, { seriesIndex, w }) => {
                const data = w.globals.series[seriesIndex];
            return `
            <div class="legend-parent">
            <span class="legend-label">${label}</span>
            <span class="legend-data">${data}</span>
            <span class="legend-percentage">${calculatePercentage(data, w.globals.series)}%</span>
        </div>`;
            }
        },
        dataLabels: {
            enabled: false,
            formatter: (val) => `${val.toFixed(1)}%`, // Display percentage
            style: {
                fontSize: '12px',
                fontFamily: 'Inter, sans-serif',
                textShadow: 'none',
            },
        },
        plotOptions: {
            pie: {
                donut: {
                    size: '70%', // Size of the donut hole
                    labels: {
                        show: true,
                        total: {
                            show: true,
                            label: 'Total',
                            fontSize: '14px',
                            fontWeight: 400,
                            formatter: () => totalCount, // Replace with the total value if needed
                            style: {
                                textShadow: 'none', // Ensure no text shadow in total label
                            },
                        },
                    },
                },
            },
        },
    };

    function calculatePercentage(val, arr) {
        return ((val / arr.reduce((a, b) => a + b, 0)) * 100).toFixed(2)
    }
    return (
        <Chart
            options={totalQAbySBU}
            series={data}
            type="donut"
            width="500"
        />
    );
};

export default DonutChartWithScorePercentage;
