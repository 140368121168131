import React, { useEffect, useRef, useState } from "react";
import * as d3 from "d3";
import { feature } from "topojson-client";

const WorldMap = ({countryWiseData = [], onCountryClick = () => {}}) => {
  const [legendData, setLegendData] = useState(countryWiseData);
  const svgRef = useRef(null);

  useEffect(() => {
      const data = getLegendData(countryWiseData);
      setLegendData(data);
  }, [countryWiseData]);
  
  function getGreenShade(population) {
    const pop = Number(population);
  
    const normalizedPop = Math.min(Math.max(pop, 0), 100);

    const scaledPop = Math.pow(normalizedPop / 100, 2) * 100;
  
    const greenIntensity = Math.round((scaledPop / 100) * 255);
  
    const greenShade = `rgb(0, ${greenIntensity}, 0)`;
  
    return greenShade;
  }
  
  const getLegendData = (arr) => {
    return arr
      ?.filter((el) => el?.country_name)
      .map((el) => {
        const countryName = el.country_name
          .toLowerCase()
          .replace(/\b\w+/g, (word) =>
            ["of", "and", "the"].includes(word) ? word : word.charAt(0).toUpperCase() + word.slice(1)
          );
  
        return {
          name: countryName,
          population: parseFloat(el?.average_audit_score)?.toFixed(2),
          color: getGreenShade(+(el?.average_audit_score)),
          countries: [countryName],
        };
      });
  };
  
  
  
  useEffect(() => {
    const svg = d3.select(svgRef.current);

    // Set the initial width and aspect ratio
    const aspectRatio = 16 / 9; // Aspect ratio for 800x450
    const containerWidth = svg.node().parentNode.clientWidth;
    const width = containerWidth;
    const height = width / aspectRatio;
    svg.selectAll("*").remove();
    // Adjust the SVG element
    svg.attr("viewBox", `0 0 ${width} ${height}`).attr("preserveAspectRatio", "xMinYMin meet");

    // Create a tooltip div and append it to the body
    const tooltip = d3
      .select("body")
      .append("div")
      .style("position", "absolute")
      .style("background", "rgba(0, 0, 0, 0.7)")
      .style("color", "#fff")
      .style("padding", "5px 10px")
      .style("border-radius", "5px")
      .style("pointer-events", "none")
      .style("visibility", "hidden");

    // Legend and country data with synced colors

    // Add legend to the map
    const legend = svg
      .append("g")
      .attr("className", "legend")
      .attr("transform", `translate(20, 20)`); // Position in top-left corner
      
    // Add a table-like structure for the legend with circles
    legend
      .selectAll("g")
      .data(legendData)
      .enter()
      .append("g")
      .attr("transform", (d, i) => `translate(0, ${i * 25})`) // Reduced vertical spacing
      .each(function (d) {
        const g = d3.select(this);

        // Add smaller color circle
        g.append("circle")
          .attr("cx", 7) // Adjusted x position
          .attr("cy", 10) // Adjusted y position
          .attr("r", 5) // Smaller radius
          .attr("fill", d.color);

        // Add smaller name column
        g.append("text")
          .attr("x", 20) // Position after the circle
          .attr("y", 14) // Adjusted vertical alignment
          .text(d.name.length > 16 ? d.name.slice(0, 16) + '...' : d.name)
          .attr("font-size", "10px") // Smaller font size
          .attr("alignment-baseline", "middle");

        // Add smaller population column
        g.append("text")
          .attr("x", 130) // Position to the right of the name
          .attr("y", 14) // Adjusted vertical alignment
          .text(d.population)
          .attr("font-size", "10px") // Smaller font size
          .attr("alignment-baseline", "middle");
      });

    // Fetch the map data
    fetch("/maps/countries-110m.json")
      .then((response) => response.json())
      .then((topology) => {
        const worldGeoJSON = feature(topology, topology.objects.countries);

        // Remove Antarctica by filtering it out
        const filteredGeoJSON = worldGeoJSON.features.filter(
          (d) => d.properties.name !== "Antarctica"
        );

        // Adjust projection to avoid overlap with the legend
        const projection = d3
          .geoMercator()
          .fitSize([width - 170, height], { type: "FeatureCollection", features: filteredGeoJSON }); // Leave space for the legend and gap
        const pathGenerator = d3.geoPath().projection(projection);

        // Render countries
        svg
          .append("g")
          .attr("transform", "translate(170, 0)") // Shift the map to the right with 70px gap
          .selectAll("path")
          .data(filteredGeoJSON)
          .enter()
          .append("path")
          .attr("d", pathGenerator)
          .attr("fill", (d) => {
            // Use legendData to determine country color
            const legendItem = legendData.find((item) =>
              item.countries.includes(d.properties.name)
            );
            return legendItem ? legendItem.color : "#D0D5DD"; // Default color for other countries
          })
          .attr("stroke", "#ccc")
          .attr("stroke-width", 0.5)
          .on("mouseover", (event, d) => {
            // Show tooltip
            tooltip
              .style("visibility", "visible")
              .text(d.properties.name) // Show country name
              .style("left", `${event.pageX + 10}px`) // Position near the mouse
              .style("top", `${event.pageY + 10}px`)
              .style("cursor", "pointer")
          })
          .on("mousemove", (event) => {
            // Move tooltip with the mouse
            tooltip
              .style("left", `${event.pageX + 10}px`)
              .style("top", `${event.pageY + 10}px`);
          })
          .on("mouseout", () => {
            // Hide tooltip
            tooltip.style("visibility", "hidden");
          })
          .on("click", (event) => {
            onCountryClick(event.target['__data__']?.properties?.name)
          });
      });

    // Cleanup the tooltip on component unmount
    return () => tooltip.remove();
  }, [legendData]);

  return <svg ref={svgRef} style={{ width: "100%" }} />;
};

export default WorldMap;
