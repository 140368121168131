import React, { useState, useEffect, lazy } from 'react';
import Card from '../../../../bootstrap/card';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import DynamicTable from '../../../Widgets/Table/TableWithSorting';
import { useNavigate } from 'react-router-dom';
import { useAnalyticsContext } from '../../../AnalyticsContext';
import Filter from '../../../Filter';
import { fetchReportData } from './service/PageData/Page';
import { useSelector } from 'react-redux';
import * as AuthSelector from '../../../../../store/auth/selectors'

const TicketWiseTable = () => {

    const navigate = useNavigate()
    const organisation_str_id = useSelector(AuthSelector.organization)?.orgStrId
    const performAuditRedirection = (id) => {
        id = id?.['Ticket ID/Case Number']
        if(!id) return
        navigate(`/o/${organisation_str_id}/view-audit-qa/${id}`);
    }
    const { analyticsData, handleBackClick, userData } = useAnalyticsContext()
    const { dateTimeRange , selectedFilters } = analyticsData
    const { organisation_id } = userData
    const [reportData, setReportData] = useState([])
    const metrics = [{
        id: 1,
        value: 'Audit Score'
    },{
        id: 2,
        value: 'Business Critical'
    },{
        id: 3,
        value: 'Compliance Critical'
    },{
        id: 4,
        value: 'Customer Critical'
    },{
        id: 5,
        value: 'Fatal Count'
    }]
    const [selectedMetric, setSelectedMetric] = useState('Audit Score')
    const handleMetricChange = (event) => {
        const selectedName = event.target.value;
        setSelectedMetric(selectedName)
    };
    useEffect(() => {
        fetchReportData(setReportData,dateTimeRange, selectedFilters['sbu']?.id, selectedFilters['geography']?.id, selectedFilters['client']?.id, selectedFilters['department']?.id, selectedFilters['country']?.id, selectedFilters['site']?.id, organisation_id )
    }, [selectedFilters, organisation_id])

    return (
        <>
            <div className="page-wrapper">
                <div className="content pb-0">
                    <div className="row">
                        <div className="col-sm-12 content_wrap_style">
                            <div className="content_wrap_comman">
                            <div className='page_title_box'>
                                    <h3 className='page_title'>
                                        <span className='pe-3'><button className='btn btn_transparent' onClick={handleBackClick}>
                                            <img src='/images/back_arrow.svg' alt='back button' />
                                        </button></span>
                                        Reports Dashboard
                                    </h3>
                                </div>
                                <Filter showDate showSBU showClient showDepartment showCountry/>
                                <div
                                    className="col-lg-3 form-group"
                                >
                                    <label>Metrics</label>
                                    <select className="form-control" onChange={handleMetricChange} value={selectedMetric.value}>
                                        {metrics.map((obj) => (
                                            <option key={obj.id} value={obj.value}>
                                                {obj.value}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                                {/* graph */}
                                <div className='row'>
                                    <div className='col-lg-12 dash_graph dash_graph_height'>
                                        <Card>
                                            <Card.Header className='d-flex justify-content-between card_header'>
                                                <Card.Header.Title className='w-100'>
                                                    <div className='two_column_title'>
                                                        <h5>
                                                            Reports
                                                        </h5>
                                                    </div>
                                                </Card.Header.Title>
                                            </Card.Header>
                                            <Card.Body>
                                                <div className="table_style_comman">
                                                    <div className="table-responsive">
                                                    <DynamicTable
                                                            headers={[
                                                                'Ticket ID/Case Number',
                                                                'SBU',
                                                                'Client',
                                                                'Program',
                                                                "Agent's Name",
                                                                "QA's Name",
                                                                `${selectedMetric}`,
                                                            ]}
                                                            widths={[
                                                                '15%', // Adjust as per your UI requirement
                                                                '10%',
                                                                '10%',
                                                                '25%',
                                                                '15%',
                                                                '15%',
                                                                '10%',
                                                                '10%',
                                                            ]}
                                                            data = {reportData || []}                                                            
                                                            rowOnClick = {performAuditRedirection}
                                                        />

                                                    </div>
                                                </div>
                                            </Card.Body>
                                        </Card>
                                    </div>
                                    
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default TicketWiseTable