import { fetchData } from "./cubejs_config";

export const getAgentAvgOverallScriptAccuracy = async (dateTimeRange, department_id = []) => {
    const query = {
        measures: ['AgentSubparameterMv.avgScriptAccuracy'],
        dimensions: ['AgentSubparameterMv.agentManagerName'],
        timeDimensions: [
            {
                dimension: "AgentSubparameterMv.reportDate",
                dateRange: dateTimeRange
            },
        ],
        order: {
            'AgentSubparameterMv.avgScriptAccuracy': 'desc'
        },
        filters: []
    };

    if (department_id && department_id.length > 0) {
        query.filters.push({
            dimension: 'AgentSubparameterMv.department_id',
            operator: 'in',
            values: department_id
        });
    } else {
        return []
    }

    try {
        const data = await fetchData(query);
        return data;
    } catch (error) {
        console.error('Error fetching agent-wise avg script accuracy:', error);
        throw error;
    }
};

export const getDODScriptAccuracy = async (dateTimeRange, department_id = [], agent_names = []) => {
    const query = {
        measures: ["AgentSubparameterMv.avgScriptAccuracy"],
        dimensions: ["AgentSubparameterMv.agent_id", "AgentSubparameterMv.agentName", "AgentSubparameterMv.reportDate"],
        timeDimensions: [
            {
                dimension: "AgentSubparameterMv.reportDate",
                granularity: "day",
                dateRange: dateTimeRange
            },
        ],
        filters: [],
        order: {
            "AgentSubparameterMv.reportDate": "asc",
        },
    };

    if (agent_names && agent_names != []) {
        query.filters.push({
            dimension: "AgentSubparameterMv.agent_id",
            operator: 'in',
            values: agent_names,
        });
    }

    if (department_id && department_id.length > 0) {
        query.filters.push({
            dimension: 'AgentSubparameterMv.department_id',
            operator: 'in',
            values: department_id
        });
    } else {
        return []
    }

    try {
        const data = await fetchData(query);
        return data;
    } catch (error) {
        console.error('Error fetching time trend script accuracy:', error);
        throw error;
    }
};

export const getSubparameterWiseAccuracy = async (dateTimeRange, department_id = []) => {
    const query = {
        measures: ['AgentSubparameterMv.subparameterScriptAccuracy'],
        dimensions: ['AgentSubparameterMv.subparameterName', 'AgentSubparameterMv.agentName'],
        timeDimensions: [
            {
                dimension: "AgentSubparameterMv.reportDate",
                granularity: "day",
                dateRange: dateTimeRange
            },
        ],
        filters: [],
        order: {
            'AgentSubparameterMv.subparameterName': 'desc'
        }
    };

    if (department_id && department_id.length > 0) {
        query.filters.push({
            dimension: 'AgentSubparameterMv.department_id',
            operator: 'in',
            values: department_id
        });
    } else {
        return []
    }

    try {
        const data = await fetchData(query);
        return data;
    } catch (error) {
        console.error('Error fetching agent-wise avg script accuracy:', error);
        throw error;
    }
};

export const getAgentLeaderBoard = async (dateTimeRange, department_id = []) => {
    const query = {
        measures: [
            "AgentSubparameterMv.totalReports",
            "AgentSubparameterMv.avgScriptAccuracy",
        ],
        dimensions: ["AgentSubparameterMv.agentName"],
        timeDimensions: [
            {
                dimension: "AgentSubparameterMv.reportDate",
                dateRange: dateTimeRange
            },
        ],
        filters: [],
        order: {
            "AgentSubparameterMv.totalReports": "desc",
        },
    };

    if (department_id && department_id.length > 0) {
        query.filters.push({
            dimension: 'AgentSubparameterMv.department_id',
            operator: 'in',
            values: department_id
        });
    } else {
        return []
    }

    try {
        const data = await fetchData(query);
        return data;
    } catch (error) {
        console.error('Error fetching agent leaderboard', error);
        throw error;
    }
};


export const getDistinctAgentIdsAndNames = async (dateTimeRange, department_id = [], organisation_name) => {
    const query = {
        dimensions: [
            "AgentSubparameterMv.agent_id",
            "AgentSubparameterMv.agentName",
        ],
        filters: [            {
            dimension: 'AgentSubparameterMv.organisationName',
            operator: 'equals',
            values: [organisation_name],
          },],
        timeDimensions: [
            {
                dimension: "AgentSubparameterMv.reportDate",
                dateRange: dateTimeRange,
            },
        ],
    };

    if (department_id && department_id.length > 0) {
        query.filters.push({
            dimension: 'AgentSubparameterMv.department_id',
            operator: 'in',
            values: department_id,
        });
    }

    try {
        const data = await fetchData(query);
        const distinctAgents = data.map(agent => ({
            agent_id: agent["AgentSubparameterMv.agent_id"],
            agentName: agent["AgentSubparameterMv.agentName"],
        }));

        return distinctAgents;
    } catch (error) {
        console.error('Error fetching distinct agent IDs and names:', error);
        throw error;
    }
};

export const getDistinctDepartmentIdsAndNames = async (organisation_name='') => {
    const query = {
        dimensions: [
            "AgentSubparameterMv.department_id",
            "AgentSubparameterMv.departmentName",
        ],
        filters: [
            {
              dimension: 'AgentSubparameterMv.organisationName',
              operator: 'equals',
              values: [organisation_name],
            },
          ]
    };
    try {
        const data = await fetchData(query);
        const distinctDepartments = data.map(agent => ({
            department_id: agent["AgentSubparameterMv.department_id"],
            department_name: agent["AgentSubparameterMv.departmentName"],
        }));

        return distinctDepartments;
    } catch (error) {
        console.error('Error fetching distinct department IDs and names:', error);
        throw error;
    }
};
