import { fetchData } from "../../../services/config";


export const getOrganisationMetricsAgent = async (dateRange, organisation_id, sbu_id, geography_id, client_id) => {

  const query = {
    measures: [
      'Agent.total_reports',
      'Agent.average_audit_score',
      'Agent.total_agents',
      'Agent.avg_business_critical',
      'Agent.avg_customer_critical',
      'Agent.avg_compliance_critical'
    ],
    dimensions: ['Agent.organisation_id'],
    timeDimensions: [
      {
        dimension: 'Agent.report_date',
        dateRange: dateRange, 
      },
    ],
    filters: [{
      dimension: 'Agent.organisation_id',
      operator: 'equals',
      values: [organisation_id],
    }]
  };
  if (sbu_id && sbu_id!='0') {
    query.filters.push({
      dimension: 'Agent.sbu_id',
      operator: 'equals',
      values: [sbu_id],
    });
  }
  if (geography_id && geography_id!='0') {
    query.filters.push({
      dimension: 'Agent.geography_id',
      operator: 'equals',
      values: [geography_id],
    });
  }
  if (client_id && client_id!='0') {
    query.filters.push({
      dimension: 'Agent.client_id',
      operator: 'equals',
      values: [client_id],
    });
  }
  return fetchData(query);
};

export const getOrganisationMetricsQA = async (dateRange, organisation_id, sbu_id, geography_id, client_id) => {
    const query = {
      measures: [
        'QA.total_qas'
      ],
      dimensions: ['QA.organisation_id'],
      timeDimensions: [
        {
          dimension: 'QA.report_date',
          dateRange: dateRange, 
        },
      ],
      filters: [{
        dimension: 'QA.organisation_id',
        operator: 'equals',
        values: [organisation_id],
      }]
    };

    if (sbu_id && sbu_id!='0') {
      query.filters.push({
        dimension: 'QA.sbu_id',
        operator: 'equals',
        values: [sbu_id],
      });
    }
    if (geography_id && geography_id!='0') {
      query.filters.push({
        dimension: 'QA.geography_id',
        operator: 'equals',
        values: [geography_id],
      });
    }
  
      if (client_id && client_id!='0') {
        query.filters.push({
          dimension: 'QA.client_id',
          operator: 'equals',
          values: [client_id],
        });
      }
    return fetchData(query);
  };

export const getCountryWiseAuditScore = async (dateRange, organisation_id, sbu_id, geography_id, client_id) => {
  const query = {
    measures: ['Agent.average_audit_score'],
    dimensions: ['Agent.country_id', 'Agent.country_name'],
    timeDimensions: [
      {
        dimension: 'Agent.report_date',
        dateRange: dateRange
      },
    ],
    filters: [{
      dimension: 'Agent.organisation_id',
      operator: 'equals',
      values: [organisation_id],
    }]
  };
  if (sbu_id && sbu_id!='0') {
    query.filters.push({
      dimension: 'Agent.sbu_id',
      operator: 'equals',
      values: [sbu_id],
    });
  }
  if (geography_id && geography_id!='0') {
    query.filters.push({
      dimension: 'Agent.geography_id',
      operator: 'equals',
      values: [geography_id],
    });
  }
  if (client_id && client_id!='0') {
    query.filters.push({
      dimension: 'Agent.client_id',
      operator: 'equals',
      values: [client_id],
    });
  }
  return fetchData(query);
};

export const getClientWiseAuditScore = async (dateRange, organisation_id, sbu_id, geography_id, client_id) => {
    const query = {
      measures: ['Agent.average_audit_score'],
      dimensions: ['Agent.client_name', 'Agent.client_id'],
      timeDimensions: [
        {
          dimension: 'Agent.report_date',
          dateRange: dateRange, // date range, e.g., ['2024-01-01', '2024-12-31']
        },
      ],
      filters: [{
        dimension: 'Agent.organisation_id',
        operator: 'equals',
        values: [organisation_id],
      }]
    };
  
    if (sbu_id && sbu_id!='0') {
      query.filters.push({
        dimension: 'Agent.sbu_id',
        operator: 'equals',
        values: [sbu_id],
      });
    }
    if (geography_id && geography_id!='0') {
      query.filters.push({
        dimension: 'Agent.geography_id',
        operator: 'equals',
        values: [geography_id],
      });
    }
    if (client_id && client_id!='0') {
      query.filters.push({
        dimension: 'Agent.client_id',
        operator: 'equals',
        values: [client_id],
      });
    }
  
    return fetchData(query);
};

export const getWowTrendPerClient = async (dateRange, organisation_id, sbu_id, geography_id, client_id, dateParameter) => {
    const query = {
      measures: ['Agent.wow_trend_of_audit_score'],
      timeDimensions: [
        {
          dimension: `Agent.${dateParameter}`,
          dateRange: dateRange,
        },
      ],
      dimensions: [`Agent.${dateParameter}`, 'Agent.client_name'],
      order: {
        [`Agent.${dateParameter}`]: 'asc',
      },
      filters: [{
        dimension: 'Agent.organisation_id',
        operator: 'equals',
        values: [organisation_id],
      }]
    }
    if (sbu_id && sbu_id!='0') {
      query.filters.push({
        dimension: 'Agent.sbu_id',
        operator: 'equals',
        values: [sbu_id],
      });
    }
    if (geography_id && geography_id!='0') {
      query.filters.push({
        dimension: 'Agent.geography_id',
        operator: 'equals',
        values: [geography_id],
      });
    }
    if (client_id && client_id!='0') {
      query.filters.push({
        dimension: 'Agent.client_id',
        operator: 'equals',
        values: [client_id],
      });
    }
    return fetchData(query);
  }
export const getAgentDataperClient = async (dateRange, organisation_id, sbu_id ,geography_id, client_id) => {
    const query = {
      measures: [
        'Agent.total_agents',
        'Agent.average_audit_score',
      ],
      dimensions: [
        'Agent.client_name',
      ],
      timeDimensions: [
        {
          dimension: 'Agent.report_date',
          dateRange: dateRange,
        },
      ],
      filters: [{
        dimension: 'Agent.organisation_id',
        operator: 'equals',
        values: [organisation_id],
      }],
      order: {
        'Agent.client_name': 'asc',
      },
    }
    if (sbu_id && sbu_id!='0') {
      query.filters.push({
        dimension: 'Agent.sbu_id',
        operator: 'equals',
        values: [sbu_id],
      });
    }
    if (geography_id && geography_id!='0') {
      query.filters.push({
        dimension: 'Agent.geography_id',
        operator: 'equals',
        values: [geography_id],
      });
    }
    if (client_id && client_id!='0') {
      query.filters.push({
        dimension: 'Agent.client_id',
        operator: 'equals',
        values: [client_id],
      });
    }
    return fetchData(query);
  }

  export const getDepartmentsDataperClient = async (dateRange, organisation_id, sbu_id, geography_id, client_id) => {
    const query = {
      measures: [
        'Agent.total_departments',
        'Agent.average_audit_score',
      ],
      dimensions: [
        'Agent.client_name',
      ],
      timeDimensions: [
        {
          dimension: 'Agent.report_date',
          dateRange: dateRange,
        },
      ],
      filters: [{
        dimension: 'Agent.organisation_id',
        operator: 'equals',
        values: [organisation_id],
      }],
      order: {
        'Agent.client_name': 'asc',
      },
    }
    if (sbu_id && sbu_id!='0') {
      query.filters.push({
        dimension: 'Agent.sbu_id',
        operator: 'equals',
        values: [sbu_id],
      });
    }
    if (geography_id && geography_id!='0') {
      query.filters.push({
        dimension: 'Agent.geography_id',
        operator: 'equals',
        values: [geography_id],
      });
    }
    if (client_id && client_id!='0') {
      query.filters.push({
        dimension: 'Agent.client_id',
        operator: 'equals',
        values: [client_id],
      });
    }
    return fetchData(query);
  }
  
  export const getQADataperClient = async (dateRange, organisation_id, sbu_id, geography_id, client_id) => {
    const query = {
      measures: [
        'QA.total_qas',
        'QA.average_audit_score',
      ],
      dimensions: [
        'QA.client_name',
      ],
      timeDimensions: [
        {
          dimension: 'QA.report_date',
          dateRange: dateRange,
        },
      ],
      filters: [{
        dimension: 'QA.organisation_id',
        operator: 'equals',
        values: [organisation_id],
      }],
      order: {
        'QA.client_name': 'asc',
      },
    }
    if (sbu_id && sbu_id!='0') {
      query.filters.push({
        dimension: 'QA.sbu_id',
        operator: 'equals',
        values: [sbu_id],
      });
    }
    if (geography_id && geography_id!='0') {
      query.filters.push({
        dimension: 'QA.geography_id',
        operator: 'equals',
        values: [geography_id],
      });
    }
    if (client_id && client_id!='0') {
      query.filters.push({
        dimension: 'QA.client_id',
        operator: 'equals',
        values: [client_id],
      });
    }
    return fetchData(query);
  }

  export const getClientWiseAuditCounts = async (dateRange, organisation_id, sbu_id, geography_id, client_id) => {
    const query = {
      measures: [
        'Agent.total_reports'
      ],
      dimensions: [
        'Agent.client_name',
      ],
      timeDimensions: [
        {
          dimension: 'Agent.report_date',
          dateRange: dateRange,
        },
      ],
      filters: [{
        dimension: 'Agent.organisation_id',
        operator: 'equals',
        values: [organisation_id],
      }],
      order: {
        'Agent.client_name': 'asc',
      },
    }
    if (sbu_id && sbu_id!='0') {
      query.filters.push({
        dimension: 'Agent.sbu_id',
        operator: 'equals',
        values: [sbu_id],
      });
    }
    if (geography_id && geography_id!='0') {
      query.filters.push({
        dimension: 'Agent.geography_id',
        operator: 'equals',
        values: [geography_id],
      });
    }
    if (client_id && client_id!='0') {
      query.filters.push({
        dimension: 'Agent.client_id',
        operator: 'equals',
        values: [client_id],
      });
    }
    return fetchData(query);
  }
  