import cubejs from '@cubejs-client/core';
const CUBEJS_API_SECRET = process.env.REACT_APP_CUBEJS_API_SECRET
const CUBEJS_API_URL = process.env.REACT_APP_CUBEJS_API_URL;

export const cubejsApi = cubejs(CUBEJS_API_SECRET, {
  apiUrl: CUBEJS_API_URL
});

export const fetchData = async (query) => {
  try {
    const result = await cubejsApi.load(query);
    return result.rawData();
  } catch (error) {
    console.error('Error fetching data from Cube.js', error);
    throw error;
  }
};

export const getOrganisationId = async (organisation_name) => {

  const query = {
    measures: [],
    dimensions: ['Organisation.organisation_id', 'Organisation.name'],
    filters: [
      {
        dimension: 'Organisation.name',
        operator: 'equals',
        values: [organisation_name]
      }
    ],
  }
  return fetchData(query);
}