import React, { useState, useEffect, useRef } from 'react';
import { Breadcrumb, Form } from 'react-bootstrap';
import { ErrorNotify, imagePath, infoNotify, successNotify } from '../../CommonLogic';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import * as AuthSelector from '../../store/auth/selectors';
import { client } from '../../services/apiService';
import { countryList } from '../../Constant';
import { useTranslation } from 'react-i18next';

const OrganisationProfile = () => {
    const { t } = useTranslation();
    const orgStrId = useSelector(AuthSelector.orgStrId);
    const organisation = useSelector(AuthSelector.organization);
    const [originalData, setOriginalData] = useState({});
    const [file, setFile] = useState(null);
    const [data, setData] = useState({});
    const [fileName, setFileName] = useState(t('Organization Profiles.Select your file!'));
    const navigate = useNavigate();
    const { id } = useParams();
    const [edit, setEdit] = useState(false);
    const isLoading = useRef(false);
    const validateEmail = (email) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
    const validateUrl = (url) => /^(https?:\/\/|www\.)[^\s/$.?#].[^\s]*$/.test(url);
    const validateContact = (contact) => /^\+?[0-9]+$/.test(contact);
    const handleFileChange = event => {
        const fileinput = event.target;
        const uploadedFileName = fileinput.value.replace(/.*(\/|\\)/, '');
        setFileName(uploadedFileName || t('Organization Profiles.Select your file!'));
        setFile(fileinput.files[0]);
    };
    useEffect(() => {
        if (id && organisation && organisation?.organisationName) {
        const [identity, ID] = id.split("-");
        if (identity && ID) fetchDetails(identity, ID);
        }
    }, [id,organisation?.organisationName])
    async function fetchDetails(identity, id) {
        if (identity == 'org') {
            try {
                const response = await client.get(`/organisations/${id}`);
                if (response.success) {
                    if (organisation?.organisationName !== 'Omind' && response?.data?.attributes?.Organisation_Name !== organisation?.organisationName) {
                        navigate(-1)
                        ErrorNotify(t('Organization Profiles.Error fetching Organisation'));
                        return;
                    }
                    setData(response?.data?.attributes);
                    setOriginalData(response?.data.attributes);
                    if (response?.data?.attributes?.Agreement_Document_Url) {
                        setFileName(extractFileName(response.data.attributes.Agreement_Document_Url))
                    }
                }
            } catch (error) {
                ErrorNotify(t('Organization Profile.Error fetching organisations'));
            }
        } else if (identity == 'dept') {
            try {
                const response = await client.get(`/departments/${id}?populate[Organisation][fields][0]=Organisation_Name`);
                if (response.success) {
                    if (organisation?.organisationName !== 'Omind' && response?.data?.attributes?.Organisation?.data?.attributes?.Organisation_Name !== organisation?.organisationName) {
                        navigate(-1)
                        ErrorNotify(t('Organization Profiles.Error fetching Department'));
                        return;
                    }
                    setData(response?.data?.attributes);
                    setOriginalData(response?.data?.attributes);
                    if (response?.data?.attributes?.Agreement_Document_Url) {
                        setFileName(extractFileName(response.data.attributes.Agreement_Document_Url))
                    }
                }
            } catch (error) {
                ErrorNotify(t('Organization Profile.Error fetching organisations'));
            }
        }
    }
    const handleChange = (e) => {
        const { name, value, type } = e.target;
        const numbersList = ['Phone_Number', 'Omind_POC_Contact', 'Client_POC_Contact'];
        if (!value) {
            return;
        }
        // Validate email
        if (name == 'Work_Email' && !validateEmail(value)) {
            infoNotify(t('Organization Profile.Please enter a valid email address.'))
            return;
        }

        // Validate URL
        if (name == 'Url' && !validateUrl(value)) {
            infoNotify(t('Organization Profiles.Please enter a valid URL.'))
            return;
        }

        // Validate contact
        if (numbersList.includes(name) && !validateContact(value)) {
            infoNotify(t('Organization Profiles.Please enter a valid number.'))
            return;
        }
        if (type == 'checkbox') {
            setData((prevData) => ({
                ...prevData,
                [name]: e?.target?.checked,
            }));
        }
        else {
            setData((prevData) => ({
                ...prevData,
                [name]: value.trim(),
            }));
        }
    };

    async function handleSaveData() {
        if (isLoading.current) {
            return;
        }
        const uploadData = JSON.parse(JSON.stringify(data));
        const [identity, ID] = id.split("-");
        isLoading.current = true;
        if (file) {
            const reference = identity == 'org' ? 'api::organisation.organisation' : 'api::department.department'
            const formData = new FormData();
            formData.append('files', file);
            formData.append('refId', ID);
            formData.append('ref', reference);
            formData.append('field', 'Agreement_Document');
            try {
                const { data: { data: [{ url }] } } = await client.post('/upload', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                });
                uploadData.Agreement_Document_Url = url;
            } catch (error) {
                ErrorNotify(t('Organization Profiles.File upload failed. Please try again later!'));
                isLoading.current = false;
                return;
            }
        }
        if (identity == 'org') {
            try {
                const response = await client.put(`/organisations/${ID}`, { data: uploadData })
                if (response.success) {
                    successNotify(t('Organization Profiles.Data Updated Successfully'));
                    setEdit(false)
                    const [identity, ID] = id.split("-");
                    if (identity && ID) fetchDetails(identity, ID);
                } else {
                    throw response
                }
            } catch (error) {
                ErrorNotify(t('Common.Something went wrong. Please try again later!'))
            }
        } else if (identity == 'dept') {
            try {
                const response = await client.put(`/departments/${ID}`, { data: uploadData })
                if (response.success) {
                    successNotify(t('Organization Profiles.Data Updated Successfully'));
                    setEdit(false);
                    const [identity, ID] = id.split("-");
                    if (identity && ID) fetchDetails(identity, ID);
                } else {
                    throw response
                }
            } catch (error) {
                ErrorNotify(t('Common.Something went wrong. Please try again later!'))
            }
        }
        isLoading.current = false;
    }

    function extractFileName(url) {
        // Get the part after the last '/'
        let fileNameWithParams = url.split('/').pop();

        // Split at the last underscore before the hash, remove the hash and extension part
        let fileNameParts = fileNameWithParams.split('_');

        // Remove the hash and get the extension
        let lastPart = fileNameParts.pop();
        let extension = lastPart.split('.').pop();

        // Join back the remaining parts for the file name
        let fileName = fileNameParts.join(' ').replace(/_/g, ' ');

        // Return the file name with the dynamic extension
        return fileName + "." + extension;
    }
    return (
        <>
            <div className='page-wrapper'>
                <div className='content pb-0'>
                    <div className='row'>
                        <div className='col-sm-12 content_wrap_style'>
                            <div className='content_wrap_comman'>
                                <div className=''>
                                    <div className='row'>
                                        <div className='col-md-12'>
                                            <div className='breadcrumb_box'>
                                                <Breadcrumb>
                                                    <Breadcrumb.Item
                                                        as='li'
                                                        href='/'>
                                                        <img
                                                            src={imagePath(
                                                                '/images/home_icon_pagig.svg'
                                                            )}
                                                            alt=''
                                                        />
                                                    </Breadcrumb.Item>
                                                    <Breadcrumb.Item as='li' onClick={() => { navigate(`/o/${orgStrId}/setup`) }}>
                                                    {t('Organization Profile.Settings')}
                                                    </Breadcrumb.Item>
                                                    <Breadcrumb.Item
                                                        as='li'
                                                        active
                                                        aria-current='page'>
                                                        {t('Organization Profiles.Organisation Profile')}
                                                    </Breadcrumb.Item>
                                                </Breadcrumb>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='page_title_box'>
                                        <h3 className='page_title'>
                                            <span className='pe-3'>
                                                <button className='btn btn_transparent'>
                                                    <img
                                                        onClick={() => {
                                                            navigate(-1);
                                                        }}
                                                        src={imagePath('/images/back_arrow.svg')}
                                                        alt='back button'
                                                    />
                                                </button>
                                            </span>{' '}
                                            {t('Organization Profiles.Organisation Profile')}
                                        </h3>
                                    </div>
                                </div>
                                <div className=''>
                                    <h3 className='page_title_sub_head'>
                                        {data?.Organisation_Name ? data?.Organisation_Name : data?.Display_Name ? data?.Display_Name?.split(" / ")[0] : 'Omind Tech'}
                                    </h3>
                                    <p className='mb-0 label_color'>
                                        {data?.Url ||
                                            'https://www.omind.ai/'}
                                    </p>
                                </div>
                                <div className='mt-4 two_column_title col_flex_wrap'>
                                    <div className='res_col_mb_10'>
                                        <h6 className='h6'>Business Info</h6>
                                        <p className='mb-0 label_color'>
                                            Update the business details here.
                                        </p>
                                    </div>
                                    <div className='text-end'>
                                        {!edit ? (
                                            <button
                                                onClick={() => {
                                                    setEdit(true);
                                                }}
                                                className='btn bordered_btn me-3'>
                                                {t('Common.Edit')}
                                            </button>
                                        ) : (
                                            <>
                                                <button
                                                    onClick={() => {
                                                        setEdit(false);
                                                        setData(originalData);
                                                    }}
                                                    className='btn bordered_btn me-3'>
                                                    {t('Common.Cancel')}
                                                </button>
                                                <button onClick={handleSaveData} className='btn comman_btn'>
                                                    {t('Organization Profiles.Save Changes')}
                                                </button>
                                            </>
                                        )}
                                    </div>
                                </div>

                                <div className='mt-4'>
                                    <form className='form_style_comman'>
                                        <div className=''>
                                            <p className='mb-0 fw_500'>
                                                {t('Organization Profiles.Information')}
                                            </p>
                                        </div>
                                        <div className='mt-3'>
                                            <div className='row'>
                                                <label class='col-lg-2 form-group'>
                                                    {t('Common.Name')}
                                                </label>
                                                <div class='col-lg-4 form-group'>
                                                    <input disabled={!edit} onChange={handleChange}
                                                        type='text'
                                                        className='form-control'
                                                        name='Organisation_Name'
                                                        value={
                                                            data
                                                                ?.Organisation_Name
                                                                ? data

                                                                    ?.Organisation_Name
                                                                : data

                                                                    ?.Department_Name
                                                                    ? data

                                                                        ?.Department_Name
                                                                    : 'Omind Tech'
                                                        }
                                                    />
                                                </div>
                                            </div>
                                            <div className='row'>
                                                <label class='col-lg-2 form-group'>
                                                    Website URL
                                                </label>
                                                <div class='col-lg-4 form-group'>
                                                    <input disabled={!edit} onChange={handleChange}
                                                        type='text'
                                                        className='form-control'
                                                        name='Url'
                                                        defaultValue={
                                                            data
                                                                ?.Url
                                                        }
                                                    />
                                                </div>
                                            </div>
                                            <div className='row'>
                                                <label class='col-lg-2 form-group'>
                                                    {t('Organization Profiles.Work Email')}
                                                </label>
                                                <div class='col-lg-4 form-group'>
                                                    <input disabled={!edit} onChange={handleChange}
                                                        type='text'
                                                        className='form-control'
                                                        name='Work_Email'
                                                        defaultValue={
                                                            data
                                                                ?.Work_Email
                                                        }
                                                    />
                                                </div>
                                            </div>
                                            <div className='row'>
                                                <label class='col-lg-2 form-group'>
                                                    Enable Masking
                                                </label>
                                                <div class='col-lg-4 form-group'>
                                                    <Form.Check className="form-switch p-0 d-flex">
                                                        <Form.Check.Input
                                                            disabled={!edit}
                                                            onChange={handleChange}
                                                            type="checkbox"
                                                            className="ms-0"
                                                            name='Deepgram_PII_PCI_PHI'
                                                            checked={data?.Deepgram_PII_PCI_PHI}
                                                        />
                                                    </Form.Check>
                                                </div>
                                            </div>
                                            <div className='row'>
                                                <label class='col-sm-12 form-group mb-2'>
                                                    {t('Organization Profiles.Agreement Document')}
                                                </label>
                                                <div class='col-sm-12 col-md-7 col-lg-5 form-group'>
                                                    <div className='custom_fileupload'>
                                                        {(fileName !== '' || fileName !== 'Select your file!') && data?.Agreement_Document_Url ?
                                                            <div className='agreement_view' style={{ display: 'flex', gap: '10px' }}> <p class={`form_output mb-0 ${!edit?'disabled':''}`}><span className='me-2'><img src='/images/document_icon.svg' /></span> {fileName}</p><ViewButton url={data?.Agreement_Document_Url} /></div> :
                                                            <div
                                                                className={`file-upload-wrapper ${!edit ? 'disabled' : ''}`}
                                                                data-text={
                                                                    fileName
                                                                }>
                                                                <input disabled={!edit}
                                                                    name='file-upload-field'
                                                                    type='file'
                                                                    className='file-upload-field'
                                                                    onChange={
                                                                        handleFileChange
                                                                    }
                                                                />
                                                            </div>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <hr className='hr_border_color' />
                                        <div className=''>
                                            <p className='mb-0 fw_500'>
                                                {t('Organization Profiles.Basic Info')}
                                            </p>
                                        </div>
                                        <div class=' mt-3'>
                                            <div className='row'>
                                                <label class='col-lg-2 form-group'>
                                                    {t('Organization Profiles.Channel of Communication')}
                                                </label>
                                                <div class='col-lg-4 form-group'>
                                                    <select
                                                        className='form-control'
                                                        disabled={!edit}
                                                        name='Channel_Of_Communication'
                                                        onChange={handleChange}
                                                        value={
                                                            data?.Channel_Of_Communication
                                                        }>
                                                        <option value={''}>
                                                            {t('Organization Profiles.Select a channel')}
                                                        </option>
                                                        <option value={'Email'}>
                                                            {t('Organization Profiles.Email')}
                                                        </option>
                                                        <option value={'Voice'}>
                                                            {t('Organization Profiles.Voice')}
                                                        </option>
                                                        <option value={'Chat'}>
                                                            {t('Organization Profiles.Chat')}
                                                        </option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className='row'>
                                                <label class='col-lg-2 form-group'>
                                                    {t('Organization Profiles.Industry')}
                                                </label>
                                                <div class='col-lg-4 form-group'>
                                                    <input disabled={!edit} onChange={handleChange}
                                                        type='text'
                                                        className='form-control'
                                                        name='Industry'
                                                        defaultValue={
                                                            data
                                                                ?.Industry
                                                        }
                                                    />
                                                </div>
                                            </div>
                                            <div className='row'>
                                                <label class='col-sm-2 form-group'>
                                                    {t('Organization Profiles.Address')}
                                                </label>
                                                <div className='col-lg-10'>
                                                    <div className='row'>
                                                        <div class='col-lg-6 form-group'>
                                                            <label>
                                                                {t('Organization Profiles.Address Line 1')}
                                                            </label>
                                                            <input disabled={!edit} onChange={handleChange}
                                                                type='text'
                                                                className='form-control'
                                                                name='Address_Line_1'
                                                                defaultValue={
                                                                    data

                                                                        ?.Address_Line_1
                                                                }
                                                            />
                                                        </div>
                                                        <div class='col-lg-6 form-group'>
                                                            <label>
                                                                {t('Organization Profiles.Address Line 2')}
                                                            </label>
                                                            <input disabled={!edit} onChange={handleChange}
                                                                type='text'
                                                                className='form-control'
                                                                name='Address_Line_2'
                                                                defaultValue={
                                                                    data

                                                                        ?.Address_Line_2
                                                                }
                                                            />
                                                        </div>
                                                        <div class='col-sm-4 form-group'>
                                                            <label>
                                                                {t('Organization Profiles.Zipcode')}
                                                            </label>
                                                            <input disabled={!edit} onChange={handleChange}
                                                                type='text'
                                                                className='form-control'
                                                                name='Zip_Code'
                                                                defaultValue={
                                                                    data

                                                                        ?.Zip_Code
                                                                }
                                                            />
                                                        </div>
                                                        <div class='col-sm-4 form-group'>
                                                            <label>{t('Organization Profiles.City')}</label>
                                                            <input disabled={!edit} onChange={handleChange}
                                                                type='text'
                                                                className='form-control'
                                                                name='City'
                                                                defaultValue={
                                                                    data

                                                                        ?.City
                                                                }
                                                            />
                                                        </div>
                                                        <div class='col-sm-4 form-group'>
                                                            <label>
                                                                {t('Organization Profiles.Country')}
                                                            </label>
                                                            <select
                                                                className='form-control'
                                                                name='Country'
                                                                disabled={!edit} onChange={handleChange}
                                                                value={
                                                                    data

                                                                        ?.Country
                                                                }>
                                                                    <option value=''>
                                                                        {t('Organization Profiles.Select Country')}
                                                                    </option>
                                                                {countryList.map((country, index) => (
                                                                    <option key={index} value={country}>
                                                                        {country}
                                                                    </option>
                                                                ))}
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='row'>
                                                <label class='col-lg-2 form-group'>
                                                    {t('Organization Profiles.Phone Number')}
                                                </label>
                                                <div class='col-lg-4 form-group d-flex align-items-center'>
                                                    <input disabled={!edit} onChange={handleChange}
                                                        type='text'
                                                        className='form-control'
                                                        name='Phone_Number'
                                                        defaultValue={
                                                            data
                                                                ?.Phone_Number
                                                        }
                                                    />
                                                    {/* <button className='btn bordered_btn ms-3 text-nowrap'>
                                                        Change Number
                                                    </button> */}
                                                </div>
                                            </div>
                                        </div>
                                        <hr className='hr_border_color' />
                                        <div className=''>
                                            <h3 class='page_title_sub_head mb-3'>
                                                {t('Organization Profiles.Key Account Management')}
                                            </h3>
                                            <div className='client_box_repeat mb-3'>
                                                <p className='mb-2 fw_500'>
                                                    {t('Organization Profiles.Omind')}
                                                </p>
                                                <div className='row'>
                                                    <div class='col-sm-4 col-lg-3 form-group'>
                                                        <label>{t('Organization Profiles.POC Name')}</label>
                                                        <input disabled={!edit} onChange={handleChange}
                                                            type='text'
                                                            className='form-control'
                                                            placeholder='POC Name'
                                                            name='Omind_POC_Name'
                                                            defaultValue={
                                                                data
                                                                    ?.Omind_POC_Name
                                                            }
                                                        />
                                                    </div>
                                                    <div class='col-sm-4 col-lg-3 form-group'>
                                                        <label>{t('Organization Profiles.Contact')}</label>
                                                        <input disabled={!edit} onChange={handleChange}
                                                            type='number'
                                                            className='form-control'
                                                            placeholder='POC Contact'
                                                            name='Omind_POC_Contact'
                                                            defaultValue={
                                                                data
                                                                    ?.Omind_POC_Contact
                                                            }
                                                        />
                                                    </div>
                                                    <div class='col-sm-4 col-lg-3 form-group'>
                                                        <label>{t('Organization Profiles.Location')}</label>
                                                        <input disabled={!edit} onChange={handleChange}
                                                            type='text'
                                                            className='form-control'
                                                            placeholder='Location'
                                                            name='Omind_POC_Location'
                                                            defaultValue={
                                                                data
                                                                    ?.Omind_POC_Location
                                                            }
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='client_box_repeat mb-3'>
                                                <p className='mb-2 fw_500'>
                                                    {t('Organization Profiles.Client')}
                                                </p>
                                                <div className='row'>
                                                    <div class='col-sm-4 col-lg-3 form-group'>
                                                        <label>{t('Organization Profiles.POC Name')}</label>
                                                        <input disabled={!edit} onChange={handleChange}
                                                            type='text'
                                                            className='form-control'
                                                            placeholder='POC Name'
                                                            name='Client_POC_Name'
                                                            defaultValue={
                                                                data
                                                                    ?.Client_POC_Name
                                                            }
                                                        />
                                                    </div>
                                                    <div class='col-sm-4 col-lg-3 form-group'>
                                                        <label>{t('Organization Profiles.Contact')}</label>
                                                        <input disabled={!edit} onChange={handleChange}
                                                            type='text'
                                                            className='form-control'
                                                            placeholder='Contact'
                                                            name='Client_POC_Contact'
                                                            defaultValue={
                                                                data
                                                                    ?.Client_POC_Contact
                                                            }
                                                        />
                                                    </div>
                                                    <div class='col-sm-4 col-lg-3 form-group'>
                                                        <label>{t('Organization Profiles.Location')}</label>
                                                        <input disabled={!edit} onChange={handleChange}
                                                            type='text'
                                                            className='form-control'
                                                            placeholder='Location'
                                                            name='Client_POC_Location'
                                                            defaultValue={
                                                                data
                                                                    ?.Client_POC_Location
                                                            }
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default OrganisationProfile;

const ViewButton = ({ url, t }) => {
    const viewFile = (event) => {
        event.preventDefault();
        window.open(url, '_blank');
    };

    return (
        <button className='btn comman_btn' onClick={viewFile}>
            {t('Common.View')}
        </button>
    );
};