import React, { useState, useEffect, lazy } from 'react';
// import Card from '../bootstrap/card'
import { Link, useNavigate, useLocation } from 'react-router-dom';
import * as AuthSelector from '../../../../store/auth/selectors';
import * as SettingSelector from '../../../../store/setting/selectors';

import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';

import DateRangePickerComp from '../../../../views/modules/social/AuditSheet/DateRangePickerComp';
import WorldMap from '../../Widgets/WorldMap';
import TableWithBar from '../../Widgets/TableWithBar';
import DonutChartWithScorePercentage from '../../Widgets/DonutChartWithScorePercentage';
import VerticalBarGraph from '../../Widgets/VerticalBarGraph';
import LineChartComponent from '../../Widgets/MultiLineGraph';
import Card from '../../../bootstrap/card';
import { useSelector } from 'react-redux';
import {
    fetchAgentsBySBUData,
    fetchQABySBUData,
    fetchDepartmentsPerSbu,
    fetchWowTrend,
    fetchAgentMetrics,
    fetchQAMetrics,
    fetchSBUWiseAuditMetrics,
    fetchGeoWiseAuditMetrics,
    fetchCountryWiseAuditMetrics,
} from './service/PageData/Page';
import { useAnalyticsContext } from '../../AnalyticsContext';
import Filter from '../../Filter';

const AdminAnalytics = () => {
    const orgStrId = useSelector(AuthSelector.orgStrId);
    useSelector(SettingSelector.theme_color);
    const getVariableColor = () => {
        let prefix =
            getComputedStyle(document.body).getPropertyValue('--prefix') ||
            'bs-';
        if (prefix) {
            prefix = prefix.trim();
        }
        const color1 = getComputedStyle(document.body).getPropertyValue(
            `--${prefix}primary`
        );
        const color2 = getComputedStyle(document.body).getPropertyValue(
            `--${prefix}info`
        );
        const color3 = getComputedStyle(document.body).getPropertyValue(
            `--${prefix}primary-tint-20`
        );
        const color4 = getComputedStyle(document.body).getPropertyValue(
            `--${prefix}warning`
        );
        return {
            primary: color1.trim(),
            info: color2.trim(),
            warning: color4.trim(),
            primary_light: color3.trim(),
        };
    };
    const variableColors = getVariableColor();
    const colors = [variableColors.primary, variableColors.info];
    const { userData, analyticsData, handleBackClick, setFilterData } = useAnalyticsContext()
    const { dateTimeRange , selectedFilters, baseURL, dateLabel, dateParameter } = analyticsData
    const { organisation_id } = userData

    const navigate = useNavigate();
    const location = useLocation();

    const [totalAudits, setTotalAudits] = useState(0)
    const [averageAuditScore, setAverageAuditScore] = useState(0)
    const [totalAgents, settotalAgents] = useState(0)
    const [totalQa, setTotalQa] = useState(0)
    const [countryWiseAuditScore, setCountryWiseAuditScore] = useState([])
    const [geoWiseAuditScore, setGeoWiseAuditScore] = useState([])
    const [sbuWiseAuditScore, setSbuWiseAuditScore] = useState([])
    const [departmentsPerSbu, setDepartmentsPerSbu] = useState([])
    const [wowTrend, setWowTrend] = useState({data: [], categories: []})
    const [totalAgentsBySbu, setTotalAgentsBySbu] = useState([])
    const [totalQasBySbu, setTotalQasBySbu] = useState([])

    const sbu_redirection = (id, name) => {
        setFilterData('sbu', {id: id, name: name})
        navigate(`${baseURL}/sbu-dashboard/${id}`);
    }

    const ticket_redirection = () => {
        navigate(`${baseURL}/ticket-wise-dashboard`);
    }
    
    const country_redirection = (name) => {
        let country = countryWiseAuditScore?.find((element) => element["Agent.country_name"]?.toLowerCase() == name?.toLowerCase())
        if(country?.["Agent.country_id"]) {
            navigate(`${baseURL}/country-wise-dashboard`);
            setFilterData('country', {id: country["Agent.country_id"], name: country["Agent.country_name"]})
        }
    }
    useEffect(() => {
        return () => colors;
    });

    useEffect(() => {
        fetchAgentMetrics(settotalAgents, setAverageAuditScore, setTotalAudits, dateTimeRange, organisation_id, selectedFilters['sbu']?.id, selectedFilters['geography']?.id)
        fetchQAMetrics(setTotalQa, dateTimeRange, organisation_id,  selectedFilters['sbu']?.id, selectedFilters['geography']?.id)
        fetchSBUWiseAuditMetrics(setSbuWiseAuditScore, dateTimeRange, organisation_id, selectedFilters['sbu']?.id, selectedFilters['geography']?.id) 
        fetchGeoWiseAuditMetrics(setGeoWiseAuditScore, dateTimeRange, organisation_id, selectedFilters['sbu']?.id, selectedFilters['geography']?.id)
        fetchCountryWiseAuditMetrics(setCountryWiseAuditScore, dateTimeRange, organisation_id, selectedFilters['sbu']?.id, selectedFilters['geography']?.id)
        fetchAgentsBySBUData(setTotalAgentsBySbu, dateTimeRange, organisation_id,  selectedFilters['sbu']?.id, selectedFilters['geography']?.id);
        fetchQABySBUData(setTotalQasBySbu, dateTimeRange, organisation_id,  selectedFilters['sbu']?.id, selectedFilters['geography']?.id);
        fetchDepartmentsPerSbu(setDepartmentsPerSbu, dateTimeRange, organisation_id,  selectedFilters['sbu']?.id, selectedFilters['geography']?.id);
        fetchWowTrend(setWowTrend, dateTimeRange, organisation_id,  selectedFilters['sbu']?.id, selectedFilters['geography']?.id, dateParameter);
    }, [organisation_id, dateTimeRange, selectedFilters]);

    return (
        <>
            <div className='page-wrapper'>
                <div className='content pb-0'>
                    <div className='row'>
                        <div className='col-sm-12 content_wrap_style'>
                            <div className='content_wrap_comman'>
                                <div className='page_title_box'>
                                    <h3 className='page_title'>
                                        <span className='pe-3'><button className='btn btn_transparent'>
                                            <img src='/images/back_arrow.svg' alt='back button' onClick={handleBackClick}/>
                                        </button></span>
                                        Dashboard
                                    </h3>
                                </div>
                                    <Filter showDate showSBU showGeo/>
                                <div className='row'>
                                    <div className='col-lg-3 card_legends_box'>
                                        <div className='card_legends card_legends_clickable card w-100 text-center' onClick={ticket_redirection}>
                                            <h4 className='mb-2'>{totalAudits}</h4>
                                            <h6 className='mb-0'>
                                                Total Audits
                                            </h6>
                                        </div>
                                    </div>
                                    <div className='col-lg-3 card_legends_box'>
                                        <div className='card_legends card_legends_clickable card w-100 text-center' onClick={ticket_redirection}>
                                            <h4 className='mb-2'>{`${parseFloat(averageAuditScore).toFixed(2)}%`}</h4>
                                            <h6 className='mb-0'>
                                                Avg Audit Score
                                            </h6>
                                        </div>
                                    </div>
                                    <div className='col-lg-3 card_legends_box'>
                                        <div className='card_legends card w-100 text-center'>
                                            <h4 className='mb-2'>{totalAgents}</h4>
                                            <h6 className='mb-0'>
                                                Total Agents who Audited
                                            </h6>
                                        </div>
                                    </div>
                                    <div className='col-lg-3 card_legends_box'>
                                        <div className='card_legends card w-100 text-center'>
                                            <h4 className='mb-2'>{totalQa}</h4>
                                            <h6 className='mb-0'>
                                                Total QA who Audited
                                            </h6>
                                        </div>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-lg-6 dash_graph dash_graph_height'>
                                        <Card>
                                            <Card.Header className='d-flex justify-content-between card_header'>
                                                <Card.Header.Title className='w-100'>
                                                    <div className='two_column_title'>
                                                        <h5>
                                                            Country Wise Audit
                                                            Score
                                                        </h5>
                                                    </div>
                                                </Card.Header.Title>
                                            </Card.Header>
                                            <Card.Body className='no_graph_data_'>
                                                {/* <img src='/images/graph_data_not_fount_img.svg' alt='no data' /> */}
                                                <WorldMap
                                                    countryWiseData={countryWiseAuditScore.map(item => ({
                                                        country_id: item['Agent.country_id'],
                                                        country_name: item['Agent.country_name'],
                                                        average_audit_score: item['Agent.average_audit_score']
                                                      }))}
                                                    onCountryClick={country_redirection}
                                                />
                                            </Card.Body>
                                        </Card>
                                    </div>
                                    <div className='col-lg-6 dash_graph dash_graph_height'>
                                        <Card>
                                            <Card.Header className='d-flex justify-content-between card_header'>
                                                <Card.Header.Title className='w-100'>
                                                    <div className='two_column_title'>
                                                        <h5>
                                                            Avg Audit Scores per SBU
                                                        </h5>
                                                    </div>
                                                </Card.Header.Title>
                                            </Card.Header>
                                            <Card.Body>
                                                <TableWithBar
                                                    data={sbuWiseAuditScore.map(item => ({
                                                        score: item['Agent.average_audit_score'],
                                                        name: item['Agent.sbu_name'],
                                                        id: item['Agent.sbu_id']
                                                      }))}
                                                      rowOnClick={sbu_redirection}
                                                    header={['SBU', 'Average Audit Score']}
                                                />
                                            </Card.Body>
                                        </Card>
                                    </div>
                                    <div className='col-lg-6 dash_graph dash_graph_height'>
                                        <Card>
                                            <Card.Header className='d-flex justify-content-between card_header'>
                                                <Card.Header.Title className='w-100'>
                                                    <div className='two_column_title'>
                                                        <h5>
                                                            Number of Programs
                                                            By SBUs
                                                        </h5>
                                                    </div>
                                                </Card.Header.Title>
                                            </Card.Header>
                                            <Card.Body className='text-center'>
                                                <VerticalBarGraph
                                                    data={[
                                                        {
                                                            data: departmentsPerSbu?.map(item => item['Agent.total_departments_per_sbu']),
                                                            name: 'Program Count',
                                                        },
                                                    ]}
                                                    categories={departmentsPerSbu?.map(item => item['Agent.sbu_name'])}
                                                    yLabel='Count'
                                                    xLabel='SBU'
                                                />
                                            </Card.Body>
                                        </Card>
                                    </div>
                                    <div className='col-lg-6 dash_graph dash_graph_height'>
                                        <Card>
                                            <Card.Header className='d-flex justify-content-between card_header'>
                                                <Card.Header.Title className='w-100'>
                                                    <div className='two_column_title'>
                                                        <h5>
                                                            Geo Wise Audit Score
                                                        </h5>
                                                    </div>
                                                </Card.Header.Title>
                                            </Card.Header>
                                            <Card.Body>
                                                <TableWithBar
                                                    data={geoWiseAuditScore.map(item => ({
                                                        score: item['Agent.average_audit_score'],
                                                        name: item['Agent.geography_name']
                                                      }))}
                                                    header={['Geography', 'Average Audit Score']}
                                                />
                                            </Card.Body>
                                        </Card>
                                    </div>
                                    <div className='col-lg-12'>
                                        <div className='dash_graph'>
                                            <Card>
                                                <Card.Header className='d-flex justify-content-between card_header'>
                                                    <Card.Header.Title className='w-100'>
                                                        <div className='two_column_title'>
                                                            <h5>
                                                                {`${dateLabel} Trend of Avg
                                                                Audit Score -
                                                                SBU Wise`}
                                                            </h5>
                                                            <div className='form_style_comman'>
                                                                {/* <MultiSelectSBU /> */}
                                                            </div>
                                                        </div>
                                                    </Card.Header.Title>
                                                </Card.Header>
                                                <Card.Body>
                                                    <LineChartComponent
                                                        data={wowTrend?.data}
                                                        categories={wowTrend?.categories}
                                                        xLabel={""}
                                                        yLabel={"SBU Wise Audit Score"}
                                                    />
                                                </Card.Body>
                                            </Card>
                                        </div>
                                    </div>
                                    <div className='col-lg-6 dash_graph dash_graph_height'>
                                        <Card>
                                            <Card.Header className='d-flex justify-content-between card_header'>
                                                <Card.Header.Title className='w-100'>
                                                    <div className='two_column_title'>
                                                        <h5>
                                                            Total Agents By SBU
                                                        </h5>
                                                    </div>
                                                </Card.Header.Title>
                                            </Card.Header>
                                            <Card.Body>
                                                <DonutChartWithScorePercentage
                                                    data={totalAgentsBySbu?.data || []}
                                                    categories={totalAgentsBySbu?.categories || []}
                                                    totalCount={totalAgentsBySbu?.totalCount}
                                                />
                                            </Card.Body>
                                        </Card>
                                    </div>
                                    <div className='col-lg-6 dash_graph dash_graph_height'>
                                        <Card>
                                            <Card.Header className='d-flex justify-content-between card_header'>
                                                <Card.Header.Title className='w-100'>
                                                    <div className='two_column_title'>
                                                        <h5>Total QA By SBU</h5>
                                                    </div>
                                                </Card.Header.Title>
                                            </Card.Header>
                                            <Card.Body className=''>
                                                <DonutChartWithScorePercentage
                                                    data={totalQasBySbu?.data || []}
                                                    categories={totalQasBySbu?.categories || []}
                                                    totalCount={totalQasBySbu?.totalCount}
                                                />
                                            </Card.Body>
                                        </Card>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default AdminAnalytics;
