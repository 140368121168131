import { useAnalyticsContext } from './AnalyticsContext';
import { useLocation, Navigate, useRoutes } from 'react-router-dom';
import AdminAnalytics from './Admin/Organisation';
import AgentsDashboard from './Admin/Agent';
import ClientDashboard from './Admin/Client';
import ProgramWiseDashboard from './Admin/Program';
import QaDashboard from './Admin/QA';
import SBUDashboard from './Admin/SBU';
import TicketWiseTable from './Admin/DrillDown/Report/TicketWiseTable';
import CountryDashboard from './Admin/DrillDown/Country';

const adminRoutes = [
    {
        path: '/admin',
        element: <AdminAnalytics/>
    },
    {
        path: '/admin/sbu-dashboard/:id',
        element: <SBUDashboard />,
    },
    {
        path: '/admin/client-dashboard/:id',
        element: <ClientDashboard />,
    },
    {
        path: '/admin/agents-dashboard/:id',
        element: <AgentsDashboard />,
    },
    {
        path: '/admin/qas-dashboard/:id',
        element: <QaDashboard />,
    },
    {
        path: '/admin/program-dashboard/:id',
        element: <ProgramWiseDashboard />,
    },
    {
        path: '/admin/ticket-wise-dashboard',
        element: <TicketWiseTable />,
    },
    {
        path: '/admin/country-wise-dashboard',
        element: <CountryDashboard />,
    }
];


const App = () => {
    const { analyticsData } = useAnalyticsContext();
    const role = analyticsData?.role;
    const location = useLocation();

    const childrenRoutes = () => {
        let routes;

        switch (role) {
            case 'Admin':
            routes = adminRoutes;
            break;
            default:
            routes = adminRoutes;
            break;
        }
        return routes;
    }
    const router = useRoutes([{
        path: '/',
        element: <Navigate to={`${analyticsData?.baseURL}`} />,
        },
        ...childrenRoutes()
    ])

    
    return <>{router}</>;
}

export default App;
