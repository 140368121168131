import React from 'react';
import {
  Chart as ChartJS,
  BarElement,
  CategoryScale,
  LinearScale,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';

ChartJS.register(BarElement, CategoryScale, LinearScale, Tooltip, Legend);

const HorizontalBarWithoutAxis = ({ categories, values, colors }) => {
  const data = {
    labels: categories,
    datasets: [
      {
        data: values,
        backgroundColor: colors,
        borderWidth: 0, // Removes the border around the bars
      },
    ],
  };

  const options = {
    indexAxis: 'y',
    responsive: true,
    maintainAspectRatio: false,
    categoryPercentage: 1.1,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: false,
      },
    },
    scales: {
      x: {
        display: false,
      },
      y: {
        display: false,
      },
    },
    layout: {
      padding: {
        left: 0,
      },
    },
  };

  const customPlugin = {
    id: 'customLabels',
    afterDatasetDraw(chart) {
      const { ctx, data } = chart;
      const dataset = data.datasets[0];

      chart.getDatasetMeta(0).data.forEach((bar, index) => {
        const value = dataset.data[index];
        ctx.save();
        ctx.fillStyle = '#FFFFFF';
        ctx.font = 'normal 12px Arial';
        ctx.textBaseline = 'middle';
        ctx.textAlign = 'left';
        const label = `${data.labels[index]}: ${value}`;
        const xPosition = bar.base + 10;
        const yPosition = bar.y;
        ctx.fillText(label, xPosition, yPosition);
        ctx.restore();
      });
    },
  };

  return (
    <div style={{ width: '100%', height: '290px' }}>
      <Bar data={data} options={options} plugins={[customPlugin]} />
    </div>
  );
};

export default HorizontalBarWithoutAxis;
