import React, { createContext, useState, useContext, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import * as AuthSelector from '../../store/auth/selectors'
import { getOrganisationId } from './services/config';

const AnalyticsContext = createContext();

const defaultRangeFn = () => {
  const currentDate = new Date();
  const pastYearDate = new Date();
  pastYearDate.setMonth(currentDate.getMonth() - 12);
  return {
    dateTimeRange: [
      pastYearDate.toISOString().split('T')[0], 
      currentDate.toISOString().split('T')[0]
    ],
    datePickerRange: {
      startDate: pastYearDate,
      endDate: currentDate,
      key: "selection"
    }
  };
}

export const AnalyticsProvider = ({ children }) => {
const organisation_name = useSelector(AuthSelector.organization)?.organisationName
const organisation_str_id = useSelector(AuthSelector.organization)?.orgStrId
const user_role = useSelector(AuthSelector.loggedInUser)?.role

const organisation_id = getOrganisationId(organisation_name)
const [userData,setUserData] = useState({
  organisation_id: organisation_id,
  department_id: 4,
  user_id: 4
})
useEffect(() => {
  const fetchOrganisationId = async (organisation_name) => {
    try {
      const organisation = await getOrganisationId(organisation_name);
      const organisation_id = organisation?.[0]?.['Organisation.organisation_id']
      setUserData({
        organisation_id: parseInt(organisation_id),
        department_id: 4,
        user_id: 4
      })
    } catch (err) {
      console.error('Error fetching QA metrics:', err);
    }
  }
  fetchOrganisationId(organisation_name)
},[organisation_name])

  let defaultRange = defaultRangeFn()
  const [analyticsData, setAnalyticsData] = useState({
    baseURL: `/o/${organisation_str_id}/analytics-dashboard/admin`,
    dateTimeRange: defaultRange?.dateTimeRange,
    datePickerRange: defaultRange?.datePickerRange,
    dateLabel: 'WOW',
    dateParameter: 'report_week',
    role: user_role,
    selectedFilters: {
      sbu: {id: '0', name: 'ALL'},
      client: {id: '0', name: 'ALL'},
      geography: {id: '0', name: 'ALL'},
      department: {id: '0', name: 'ALL'},
      agent: {id: '0', name: 'ALL'},
      qa: {id: '0', name: 'ALL'},
      country: {id: '0', name: 'ALL'},
      site: {id: '0', name: 'ALL'},
      level: {id: '0', name: 'ALL'}
    }
  });
  const navigate = useNavigate()

  function getReportLabelAndField(dateRange) {
    const startDate = new Date(dateRange[0]);
    const endDate = new Date(dateRange[1]);
    const diffDays = (endDate - startDate) / (1000 * 60 * 60 * 24);

    if (diffDays < 14) {
        return { label: "DOD", field: "report_date" };
    } else if (diffDays < 90) {
        return { label: "WOW", field: "report_week" };
    } else {
        return { label: "MOM", field: "report_month" };
    }
  }

  const setDateTimeRange = (dateRange) => {
    const dateLabelAndField = getReportLabelAndField(dateRange)
    setAnalyticsData((prev) => ({
      ...prev,
      dateTimeRange: dateRange,
      dateLabel: dateLabelAndField?.label,
      dateParameter: dateLabelAndField?.field,
      datePickerRange: {
        startDate: new Date(dateRange[0]),
        endDate: new Date(dateRange[1])
      }
    }));
  };

  const setFilterData = (filter, valueObj) => {
    setAnalyticsData((prev) => ({
      ...prev,
      selectedFilters: {
        ...prev.selectedFilters,
        [filter]: valueObj
      }
    }));
  };

  const handleBackClick = () => {
    navigate(-1);
  };

  return (
    <AnalyticsContext.Provider
      value={{
        analyticsData,
        userData,
        setUserData,
        setDateTimeRange,
        handleBackClick,
        setFilterData,
        // setBaseURL
      }}
    >
      {children}
    </AnalyticsContext.Provider>
  );
};

export const useAnalyticsContext = () => {
  const context = useContext(AnalyticsContext);
  if (!context) {
    throw new Error(
      'useAnalyticsContext must be used within an AnalyticsProvider'
    );
  }
  return context;
};
