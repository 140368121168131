import React, { useState, useEffect, lazy } from 'react';
import Card from '../../../bootstrap/card';
import { useNavigate, useParams } from 'react-router-dom';
import * as AuthSelector from '../../../../store/auth/selectors';
import * as SettingSelector from '../../../../store/setting/selectors';
import { useSelector } from 'react-redux';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import WorldMap from '../../Widgets/WorldMap';
import TableWithBar from '../../Widgets/TableWithBar';
import DonutChartWithScorePercentage from '../../Widgets/DonutChartWithScorePercentage';
import LineChartComponent from '../../Widgets/MultiLineGraph';
import { useAnalyticsContext } from '../../AnalyticsContext';
import { fetchAgentMetrics, fetchAgentsByClientData, fetchClientWiseAuditCount, fetchClientWiseAuditScore, fetchCountryWiseAuditMetrics, fetchDepartmentsByClientData, fetchQAByClientData, fetchQAMetrics, fetchWowTrend } from './service/PageData/Page';
import Filter from '../../Filter';
const SBUDashboard = () => {

    const { analyticsData, handleBackClick, setFilterData, userData } = useAnalyticsContext()
    const { organisation_id } = userData
    const { dateTimeRange, selectedFilters, baseURL, dateLabel, dateParameter} = analyticsData

    const [total_audits, setTotalAudits] = useState(0)
    const [averageAuditScore, setAverageAuditScore] = useState(0)
    const [totalAgents, settotalAgents] = useState(0)
    const [totalQa, setTotalQa] = useState(0)
    const [businessCriticalScore, setBusinessCriticalScore] = useState(0)
    const [complianceCriticalScore, setComplianceCriticalScore] = useState(0)
    const [customerCriticalScore, setCustomerCriticalScore] = useState(0)
    
    const [clientWiseAuditScore, setClientWiseAuditScore] = useState([])
    const [countryWiseAuditScore, setCountryWiseAuditScore] = useState([])
    const [wowTrend, setWowTrend] = useState({data: [], categories: []})
    const [totalAgentsByClient, setTotalAgentsByClient] = useState([])
    const [totalQasByClient, setTotalQasByClient] = useState([])
    const [totalDepartmentsByClient, setTotalDepartmentsByClient] = useState([])
    const [clientWiseAuditCount, setClientWiseAuditCount] = useState([])
    const [dateParameterTimeFrame, setDateParameterTimeFrame] = useState('Day')
    const navigate = useNavigate();

    const client_redirection = (id, name) => {
        setFilterData('client', {id: id, name: name})
        navigate(`${baseURL}/client-dashboard/${id}`);
    }

    const ticket_redirection = () => {
        navigate(`${baseURL}/ticket-wise-dashboard`);
    }

    const country_redirection = (name) => {
        let country = countryWiseAuditScore?.find((element) => element["Agent.country_name"]?.toLowerCase() == name?.toLowerCase())
        if(country?.["Agent.country_id"]) {
            navigate(`${baseURL}/country-wise-dashboard`);
            setFilterData('country', {id: country["Agent.country_id"], name: country["Agent.country_name"]})
        }
    }

    useEffect(() => {
        if(dateLabel == 'DOD'){
            setDateParameterTimeFrame('Day')
        }else if(dateLabel == 'WOW'){
            setDateParameterTimeFrame('Week')
        }else {
            setDateParameterTimeFrame('Month')
        }
        fetchAgentMetrics(settotalAgents, setAverageAuditScore, setTotalAudits, setBusinessCriticalScore, setComplianceCriticalScore, setCustomerCriticalScore, dateTimeRange, organisation_id, selectedFilters['sbu']?.id, selectedFilters['geography']?.id, selectedFilters['client']?.id)
        fetchQAMetrics(setTotalQa, dateTimeRange, organisation_id, selectedFilters['sbu']?.id, selectedFilters['geography']?.id, selectedFilters['client']?.id)
        fetchCountryWiseAuditMetrics(setCountryWiseAuditScore, dateTimeRange, organisation_id, selectedFilters['sbu']?.id, selectedFilters['geography']?.id, selectedFilters['client']?.id)
        fetchClientWiseAuditScore(setClientWiseAuditScore, dateTimeRange, organisation_id, selectedFilters['sbu']?.id, selectedFilters['geography']?.id, selectedFilters['client']?.id)
        fetchWowTrend(setWowTrend, dateTimeRange, organisation_id, selectedFilters['sbu']?.id, selectedFilters['geography']?.id, selectedFilters['client']?.id, dateParameter)
        fetchAgentsByClientData(setTotalAgentsByClient, dateTimeRange, organisation_id, selectedFilters['sbu']?.id, selectedFilters['geography']?.id, selectedFilters['client']?.id);
        fetchQAByClientData(setTotalQasByClient, dateTimeRange, organisation_id, selectedFilters['sbu']?.id, selectedFilters['geography']?.id, selectedFilters['client']?.id);
        fetchDepartmentsByClientData(setTotalDepartmentsByClient, dateTimeRange, organisation_id, selectedFilters['sbu']?.id, selectedFilters['geography']?.id, selectedFilters['client']?.id )
        fetchClientWiseAuditCount(setClientWiseAuditCount, dateTimeRange, organisation_id, selectedFilters['sbu']?.id, selectedFilters['geography']?.id, selectedFilters['client']?.id);
      }, [dateTimeRange, selectedFilters]);

    return (
        <>
            <div className='page-wrapper'>
                <div className='content pb-0'>
                    <div className='row'>
                        <div className='col-sm-12 content_wrap_style'>
                            <div className='content_wrap_comman'>
                            <div className='page_title_box'>
                                    <h3 className='page_title'>
                                        <span className='pe-3'><button className='btn btn_transparent'>
                                            <img src='/images/back_arrow.svg' alt='back button' onClick={handleBackClick}/>
                                        </button></span>
                                        SBU Dashboard
                                    </h3>
                                </div>
                                <Filter showDate showSBU showGeo showClient/>
                                <div className='row'>
                                    <div className='col-lg-3 card_legends_box'>
                                        <div className='card_legends card_legends_clickable card w-100 text-center'  onClick={ticket_redirection}>
                                            <h4 className='mb-2'>{total_audits}</h4>
                                            <h6 className='mb-0'>
                                                Total Audits
                                            </h6>
                                        </div>
                                    </div>
                                    <div className='col-lg-3 card_legends_box'>
                                        <div className='card_legends card_legends_clickable card w-100 text-center'  onClick={ticket_redirection}>
                                            <h4 className='mb-2'>{`${parseFloat(averageAuditScore).toFixed(2)}%`}</h4>
                                            <h6 className='mb-0'>
                                                Avg Audit Score
                                            </h6>
                                        </div>
                                    </div>
                                    <div className='col-lg-3 card_legends_box'>
                                        <div className='card_legends card w-100 text-center'>
                                            <h4 className='mb-2'>{totalAgents}</h4>
                                            <h6 className='mb-0'>
                                                Total Agents Audits
                                            </h6>
                                        </div>
                                    </div>
                                    <div className='col-lg-3 card_legends_box'>
                                        <div className='card_legends card w-100 text-center'>
                                            <h4 className='mb-2'>{totalQa}</h4>
                                            <h6 className='mb-0'>
                                                Total QA who Audited
                                            </h6>
                                        </div>
                                    </div>
                                    <div className='col-lg-4 card_legends_box'>
                                        <div className='card_legends card_legends_clickable card w-100 text-center'  onClick={ticket_redirection}>
                                            <h4 className='mb-2'>{`${parseFloat(businessCriticalScore).toFixed(2)}%`}</h4>
                                            <h6 className='mb-0'>
                                                Business Critical
                                            </h6>
                                        </div>
                                    </div>
                                    <div className='col-lg-4 card_legends_box'>
                                        <div className='card_legends card_legends_clickable card w-100 text-center'  onClick={ticket_redirection}>
                                            <h4 className='mb-2'>{`${parseFloat(complianceCriticalScore).toFixed(2)}%`}</h4>
                                            <h6 className='mb-0'>
                                                Compliance Critical
                                            </h6>
                                        </div>
                                    </div>
                                    <div className='col-lg-4 card_legends_box'>
                                        <div className='card_legends card_legends_clickable card w-100 text-center'  onClick={ticket_redirection}>
                                            <h4 className='mb-2'>{`${parseFloat(customerCriticalScore).toFixed(2)}%`}</h4>
                                            <h6 className='mb-0'>
                                                Customer Critical
                                            </h6>
                                        </div>
                                    </div>
                                </div>
                                {/* graph */}
                                <div className='row'>
                                    <div className='col-lg-6 dash_graph dash_graph_height'>
                                        <Card>
                                            <Card.Header className='d-flex justify-content-between card_header'>
                                                <Card.Header.Title className='w-100'>
                                                    <div className='two_column_title'>
                                                        <h5>
                                                            Country Wise Audit
                                                            Score
                                                        </h5>
                                                    </div>
                                                </Card.Header.Title>
                                            </Card.Header>
                                            <Card.Body className='no_graph_data_'>
                                                {/* <img src='/images/graph_data_not_fount_img.svg' alt='no data' /> */}
                                                <WorldMap                                                     
                                                countryWiseData={countryWiseAuditScore.map(item => ({
                                                        country_id: item['Agent.country_id'],
                                                        country_name: item['Agent.country_name'],
                                                        average_audit_score: item['Agent.average_audit_score']
                                                      }))}
                                                    header={['Client', 'Average Audit Score']}
                                                    onCountryClick={country_redirection}
                                                />
                                            </Card.Body>
                                        </Card>
                                    </div>
                                    <div className='col-lg-6 dash_graph dash_graph_height'>
                                        <Card>
                                            <Card.Header className='d-flex justify-content-between card_header'>
                                                <Card.Header.Title className='w-100'>
                                                    <div className='two_column_title'>
                                                        <h5>
                                                            Client Wise Avg
                                                            Audit Scores
                                                        </h5>
                                                    </div>
                                                </Card.Header.Title>
                                            </Card.Header>
                                            <Card.Body>
                                                <TableWithBar
                                                    rowOnClick={client_redirection}
                                                    data={clientWiseAuditScore.map(item => ({
                                                        score: item['Agent.average_audit_score'],
                                                        name: item['Agent.client_name'],
                                                        id: item['Agent.client_id']
                                                      }))}
                                                    header={['Client', 'Average Audit Score']}
                                                />
                                            </Card.Body>
                                        </Card>
                                    </div>
                                    <div className='col-lg-12'>
                                        <div className='dash_graph'>
                                            <Card>
                                                <Card.Header className='d-flex justify-content-between card_header'>
                                                    <Card.Header.Title className='w-100'>
                                                        <div className='two_column_title'>
                                                            <h5>
                                                                {`Count Of Audit -
                                                                Per ${dateParameterTimeFrame}`}
                                                            </h5>
                                                            <div className='form_style_comman'>
                                                                {/* <MultiSelectSBU /> */}
                                                            </div>
                                                        </div>
                                                    </Card.Header.Title>
                                                </Card.Header>
                                                <Card.Body>
                                                <LineChartComponent
                                                        data={wowTrend?.data}
                                                        categories={wowTrend?.categories}
                                                        xLabel={""}
                                                        yLabel={"Count of Audit - Per Day"}
                                                    />
                                                </Card.Body>
                                            </Card>
                                        </div>
                                    </div>
                                    <div className='col-lg-6 dash_graph dash_graph_height'>
                                        <Card>
                                            <Card.Header className='d-flex justify-content-between card_header'>
                                                <Card.Header.Title className='w-100'>
                                                    <div className='two_column_title'>
                                                        <h5>
                                                            Total Agents By
                                                            Client
                                                        </h5>
                                                    </div>
                                                </Card.Header.Title>
                                            </Card.Header>
                                            <Card.Body>
                                                <DonutChartWithScorePercentage
                                                    data={totalAgentsByClient?.data || []}
                                                    categories={totalAgentsByClient?.categories || []}
                                                    totalCount={totalAgentsByClient?.totalCount}
                                                />
                                            </Card.Body>
                                        </Card>
                                    </div>
                                    <div className='col-lg-6 dash_graph dash_graph_height'>
                                        <Card>
                                            <Card.Header className='d-flex justify-content-between card_header'>
                                                <Card.Header.Title className='w-100'>
                                                    <div className='two_column_title'>
                                                        <h5>
                                                            Total QA By Client
                                                        </h5>
                                                    </div>
                                                </Card.Header.Title>
                                            </Card.Header>
                                            <Card.Body
                                                className=''
                                                style={{ width: '400px' }}>
                                                <DonutChartWithScorePercentage
                                                    data={totalQasByClient?.data || []}
                                                    categories={totalQasByClient?.categories || []}
                                                    totalCount={totalQasByClient?.totalCount}
                                                />
                                            </Card.Body>
                                        </Card>
                                    </div>
                                    <div className='col-lg-6 dash_graph dash_graph_height'>
                                        <Card>
                                            <Card.Header className='d-flex justify-content-between card_header'>
                                                <Card.Header.Title className='w-100'>
                                                    <div className='two_column_title'>
                                                        <h5>
                                                            No. of Programs - By
                                                            Client
                                                        </h5>
                                                    </div>
                                                </Card.Header.Title>
                                            </Card.Header>
                                            <Card.Body className=''>
                                            <DonutChartWithScorePercentage
                                                    data={totalDepartmentsByClient?.data || []}
                                                    categories={totalDepartmentsByClient?.categories || []}
                                                    totalCount={totalDepartmentsByClient?.totalCount}
                                                />
                                            </Card.Body>
                                        </Card>
                                    </div>
                                    <div className='col-lg-6 dash_graph dash_graph_height'>
                                        <Card>
                                            <Card.Header className='d-flex justify-content-between card_header'>
                                                <Card.Header.Title className='w-100'>
                                                    <div className='two_column_title'>
                                                        <h5>
                                                            Client Wise Audit
                                                            Counts
                                                        </h5>
                                                    </div>
                                                </Card.Header.Title>
                                            </Card.Header>
                                            <Card.Body className=''>
                                                <table className='table table-borderless graph_tables'>
                                                    <thead>
                                                        <tr>
                                                            <th>
                                                                Client{' '}
                                                                <i
                                                                    className='fa fa-angle-down'
                                                                    aria-hidden='true'></i>
                                                            </th>
                                                            <th className='text-end'>
                                                                <i
                                                                    className='fa fa-angle-down'
                                                                    aria-hidden='true'></i>{' '}
                                                                Audit Count
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {clientWiseAuditCount.map((clientData) => {
                                                            return <tr>
                                                                <td>{clientData['Agent.client_name']}</td>
                                                                <td className='text-end'>{clientData['Agent.total_reports']}</td>
                                                            </tr>
                                                        })}
                                                        
                                                    </tbody>
                                                </table>
                                            </Card.Body>
                                        </Card>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default SBUDashboard;
