import { getReportData } from "../ApiService";

export const fetchReportData = async (setReportData, dateTimeRange, selectedSBU, selectedGeo, selectedClient, selectedDepartment, selectedCountry, selectedSite, organisation_id) => {
    try {
        const result = await getReportData(dateTimeRange, selectedSBU, selectedGeo, selectedClient, selectedDepartment, selectedCountry, selectedSite, organisation_id);
        const formattedData = result.map(item => ({
            "Ticket ID/Case Number": item["ReportAggregated.ticketId"],
            "SBU": item["ReportAggregated.sbuName"],
            "Client": item["ReportAggregated.clientName"],
            "Program": item["ReportAggregated.departmentName"],
            "Agent's Name": item["ReportAggregated.agentName"],
            "QA's Name": item["ReportAggregated.qaName"],
            "Audit Score": `${item["ReportAggregated.overallAuditScore"]}%`,
            "Fatal Count": `${item["ReportAggregated.fatalTotalCount"]}`,
            "Business Critical": `${item["ReportAggregated.criticalBusinessScoreOverall"]}%`,
            "Customer Critical": `${item["ReportAggregated.criticalCustomerScoreOverall"]}%`,
            "Compliance Critical": `${item["ReportAggregated.criticalComplianceScoreOverall"]}%`,
            id: item["ReportAggregated.ticketId"]
        }));
        setReportData(formattedData);
      } catch (err) {
        console.error('Error fetching country-wise audit metrics:', err);
      }
  };