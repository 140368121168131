import { fetchData } from "../../../services/config";

export const getOrganisationMetricsAgent = async (dateRange, department_id, agent_id) => {

    const query = {
      measures: [
        'Agent.total_reports',
        'Agent.average_audit_score',
        'Agent.reports_with_fatal'
      ],
      dimensions: ['Agent.organisation_id'],
      timeDimensions: [
        {
          dimension: 'Agent.report_date',
          dateRange: dateRange, 
        },
      ],
      filters: []
    };
  
    if (department_id && department_id!='0') {
      query.filters.push({
        dimension: 'Agent.department_id',
        operator: 'equals',
        values: [department_id],
      });
    }

    if (agent_id && agent_id!='0') {
        query.filters.push({
          dimension: 'Agent.agent_id',
          operator: 'equals',
          values: [agent_id],
        });
      }
    return fetchData(query);
  
};

export const getWOWTrendPerDepartment = async (dateRange, client_id, department_id, dateParameter, sbu_id, agent_id) => {
    const query = {
      measures: ['Agent.wow_trend_of_audit_score'],
      timeDimensions: [
        {
          dimension: `Agent.${dateParameter}`,
          dateRange: dateRange,
        },
      ],
      dimensions: [`Agent.${dateParameter}`, 'Agent.department_name'],
      order: {
        [`Agent.${dateParameter}`]: 'asc',
      },
      filters: []
    }
    if (agent_id && agent_id!='0') {
        query.filters.push({
          dimension: 'Agent.agent_id',
          operator: 'equals',
          values: [agent_id],
        });
    }
    if (sbu_id && sbu_id!='0') {
      query.filters.push({
        dimension: 'Agent.sbu_id',
        operator: 'equals',
        values: [sbu_id],
      });
    }
    if (client_id && client_id!='0')  {
      query.filters.push({
        dimension: 'Agent.client_id',
        operator: 'equals',
        values: [client_id],
      });
    }
    if (department_id && department_id!='0')  {
        query.filters.push({
          dimension: 'Agent.department_id',
          operator: 'equals',
          values: [department_id],
        });
      }
    return fetchData(query);
}

export const getWOWTrendPerDepartmentAuditCount = async (dateRange, client_id, department_id, dateParameter, sbu_id, agent_id) => {
    const query = {
      measures: ['Agent.total_reports'],
      timeDimensions: [
        {
          dimension: `Agent.${dateParameter}`,
          dateRange: dateRange,
        },
      ],
      dimensions: [`Agent.${dateParameter}`, 'Agent.department_name'],
      order: {
        [`Agent.${dateParameter}`]: 'asc',
      },
      filters: []
    }
    if (agent_id && agent_id!='0') {
        query.filters.push({
          dimension: 'Agent.agent_id',
          operator: 'equals',
          values: [agent_id],
        });
    }
    if (sbu_id && sbu_id!='0') {
      query.filters.push({
        dimension: 'Agent.sbu_id',
        operator: 'equals',
        values: [sbu_id],
      });
    }
    if (client_id && client_id!='0')  {
      query.filters.push({
        dimension: 'Agent.client_id',
        operator: 'equals',
        values: [client_id],
      });
    }
    if (department_id && department_id!='0')  {
        query.filters.push({
          dimension: 'Agent.department_id',
          operator: 'equals',
          values: [department_id],
        });
      }
    return fetchData(query);
}

//this
export const getWOWTrendPerDepartmentFatalCount = async (dateRange, client_id, department_id, dateParameter, sbu_id, agent_id) => {
    const query = {
      measures: ['Agent.total_fatal_count'],
      timeDimensions: [
        {
          dimension: `Agent.${dateParameter}`,
          dateRange: dateRange,
        },
      ],
      dimensions: [`Agent.${dateParameter}`, 'Agent.department_name'],
      order: {
        [`Agent.${dateParameter}`]: 'asc',
      },
      filters: []
    }
    if (agent_id && agent_id!='0') {
        query.filters.push({
          dimension: 'Agent.agent_id',
          operator: 'equals',
          values: [agent_id],
        });
    }
    if (sbu_id && sbu_id!='0') {
      query.filters.push({
        dimension: 'Agent.sbu_id',
        operator: 'equals',
        values: [sbu_id],
      });
    }
    if (client_id && client_id!='0')  {
      query.filters.push({
        dimension: 'Agent.client_id',
        operator: 'equals',
        values: [client_id],
      });
    }
    if (department_id && department_id!='0')  {
        query.filters.push({
          dimension: 'Agent.department_id',
          operator: 'equals',
          values: [department_id],
        });
      }
    return fetchData(query);
}
