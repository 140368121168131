import { fetchData } from "../../../../services/config";


export const getOrganisationMetricsAgent = async (dateRange, sbu_id, geography_id, client_id, country_id) => {

  const query = {
    measures: [
      'Agent.total_reports',
      'Agent.average_audit_score',
      'Agent.total_agents'
    ],
    dimensions: ['Agent.organisation_id'],
    timeDimensions: [
      {
        dimension: 'Agent.report_date',
        dateRange: dateRange, 
      },
    ],
    filters: []
  };
  if (sbu_id && sbu_id!='0') {
    query.filters.push({
      dimension: 'Agent.sbu_id',
      operator: 'equals',
      values: [sbu_id],
    });
  }
  if (geography_id && geography_id!='0') {
    query.filters.push({
      dimension: 'Agent.geography_id',
      operator: 'equals',
      values: [geography_id],
    });
  }
  if (client_id && client_id!='0') {
    query.filters.push({
      dimension: 'Agent.client_id',
      operator: 'equals',
      values: [client_id],
    });
  }
  if (country_id && country_id!='0') {
    query.filters.push({
      dimension: 'Agent.country_id',
      operator: 'equals',
      values: [country_id],
    });
  }
  return fetchData(query);
};

export const getOrganisationMetricsQA = async (dateRange, sbu_id, geography_id, client_id, country_id) => {
    const query = {
      measures: [
        'QA.total_qas'
      ],
      dimensions: ['QA.organisation_id'],
      timeDimensions: [
        {
          dimension: 'QA.report_date',
          dateRange: dateRange, 
        },
      ],
      filters: []
    };

    if (sbu_id && sbu_id!='0') {
      query.filters.push({
        dimension: 'QA.sbu_id',
        operator: 'equals',
        values: [sbu_id],
      });
    }
    if (geography_id && geography_id!='0') {
      query.filters.push({
        dimension: 'QA.geography_id',
        operator: 'equals',
        values: [geography_id],
      });
    }
  
      if (client_id && client_id!='0') {
        query.filters.push({
          dimension: 'QA.client_id',
          operator: 'equals',
          values: [client_id],
        });
      }
      if (country_id && country_id!='0') {
        query.filters.push({
          dimension: 'QA.country_id',
          operator: 'equals',
          values: [country_id],
        });
      }
    return fetchData(query);
  };

export const getLocationWiseAuditScore = async (dateRange, sbu_id, geography_id, client_id, country_id) => {
    const query = {
      measures: ['Agent.average_audit_score'],
      dimensions: ['Agent.site_name', 'Agent.site_id'],
      timeDimensions: [
        {
          dimension: 'Agent.report_date',
          dateRange: dateRange,
        },
      ],
      filters: []
    };
  
    if (sbu_id && sbu_id!='0') {
      query.filters.push({
        dimension: 'Agent.sbu_id',
        operator: 'equals',
        values: [sbu_id],
      });
    }
    if (geography_id && geography_id!='0') {
      query.filters.push({
        dimension: 'Agent.geography_id',
        operator: 'equals',
        values: [geography_id],
      });
    }
    if (client_id && client_id!='0') {
      query.filters.push({
        dimension: 'Agent.client_id',
        operator: 'equals',
        values: [client_id],
      });
    }

    if (country_id && country_id!='0') {
      query.filters.push({
        dimension: 'Agent.country_id',
        operator: 'equals',
        values: [country_id],
      });
    }
  
    return fetchData(query);
};

export const getDepartmentWiseAuditScore = async (dateRange, sbu_id, geography_id, client_id, country_id) => {
  const query = {
    measures: ['Agent.average_audit_score'],
    dimensions: ['Agent.department_name', 'Agent.department_id'],
    timeDimensions: [
      {
        dimension: 'Agent.report_date',
        dateRange: dateRange,
      },
    ],
    filters: []
  };

  if (sbu_id && sbu_id!='0') {
    query.filters.push({
      dimension: 'Agent.sbu_id',
      operator: 'equals',
      values: [sbu_id],
    });
  }
  if (geography_id && geography_id!='0') {
    query.filters.push({
      dimension: 'Agent.geography_id',
      operator: 'equals',
      values: [geography_id],
    });
  }
  if (client_id && client_id!='0') {
    query.filters.push({
      dimension: 'Agent.client_id',
      operator: 'equals',
      values: [client_id],
    });
  }
  if (country_id && country_id!='0') {
    query.filters.push({
      dimension: 'Agent.country_id',
      operator: 'equals',
      values: [country_id],
    });
  }
  return fetchData(query);
};

export const getAgentDataperLocation = async (dateRange, sbu_id ,geography_id, client_id, country_id) => {
    const query = {
      measures: [
        'Agent.total_agents',
        'Agent.average_audit_score',
      ],
      dimensions: [
        'Agent.site_name',
      ],
      timeDimensions: [
        {
          dimension: 'Agent.report_date',
          dateRange: dateRange,
        },
      ],
      filters: [],
      order: {
        'Agent.site_name': 'asc',
      },
    }
    if (sbu_id && sbu_id!='0') {
      query.filters.push({
        dimension: 'Agent.sbu_id',
        operator: 'equals',
        values: [sbu_id],
      });
    }
    if (geography_id && geography_id!='0') {
      query.filters.push({
        dimension: 'Agent.geography_id',
        operator: 'equals',
        values: [geography_id],
      });
    }
    if (client_id && client_id!='0') {
      query.filters.push({
        dimension: 'Agent.client_id',
        operator: 'equals',
        values: [client_id],
      });
    }
    if (country_id && country_id!='0') {
      query.filters.push({
        dimension: 'Agent.country_id',
        operator: 'equals',
        values: [country_id],
      });
    }
    return fetchData(query);
  }
export const getQADataperLocation = async (dateRange, sbu_id, geography_id, client_id, country_id) => {
    const query = {
      measures: [
        'QA.total_qas',
        'QA.average_audit_score',
      ],
      dimensions: [
        'QA.site_name',
      ],
      timeDimensions: [
        {
          dimension: 'QA.report_date',
          dateRange: dateRange,
        },
      ],
      filters: [],
      order: {
        'QA.site_name': 'asc',
      },
    }
    if (sbu_id && sbu_id!='0') {
      query.filters.push({
        dimension: 'QA.sbu_id',
        operator: 'equals',
        values: [sbu_id],
      });
    }
    if (geography_id && geography_id!='0') {
      query.filters.push({
        dimension: 'QA.geography_id',
        operator: 'equals',
        values: [geography_id],
      });
    }
    if (client_id && client_id!='0') {
      query.filters.push({
        dimension: 'QA.client_id',
        operator: 'equals',
        values: [client_id],
      });
    }
    if (country_id && country_id!='0') {
      query.filters.push({
        dimension: 'QA.country_id',
        operator: 'equals',
        values: [country_id],
      });
    }
    return fetchData(query);
  }
  