import React, { useEffect, useState } from 'react';
import './table.css';

const DynamicTable = ({
    headers,
    data,
    widths = [],
    rowOnClick = () => console.log(""),
    rowsPerPage = 10,
    pagination = true
}) => {
    const [sortedRows, setSortedRows] = useState(data);
    const [sortConfig, setSortConfig] = useState({
        key: null,
        direction: null,
    });

    const [currentPage, setCurrentPage] = useState(1);

    useEffect(() => {
        setSortedRows(data)
    }, [data, headers])

    const totalPages = Math.ceil(sortedRows.length / rowsPerPage);

    const handleSort = (header) => {
        const key = header;
        let direction = 'ascending';

        // Toggle sort direction
        if (sortConfig.key === key && sortConfig.direction === 'ascending') {
            direction = 'descending';
        }

        const sorted = [...data].sort((a, b) => {
            if (a[key] < b[key]) return direction === 'ascending' ? -1 : 1;
            if (a[key] > b[key]) return direction === 'ascending' ? 1 : -1;
            return 0;
        });

        setSortedRows(sorted);
        setSortConfig({ key, direction });
        setCurrentPage(1); // Reset to first page after sorting
    };

    // Calculate the rows to display on the current page
    const indexOfLastRow = currentPage * rowsPerPage;
    const indexOfFirstRow = indexOfLastRow - rowsPerPage;
    const currentRows = sortedRows.slice(indexOfFirstRow, indexOfLastRow);

    const goToPage = (pageNumber) => {
        if (pageNumber > 0 && pageNumber <= totalPages) {
            setCurrentPage(pageNumber);
        }
    };

    const nextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };

    const prevPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    return (
        <div className='table-container'>
            <table className='table custom-table tbl_custom_reporing'>
                <thead>
                    <tr>
                        {headers.map((header, index) => (
                            <th
                                key={index}
                                style={{
                                    width: widths[index] || 'auto',
                                    cursor: 'pointer',
                                }}
                                onClick={() => handleSort(header)}>
                                {header}
                                <span
                                    className={`tbl_sorting_icon ${sortConfig.key === header ? (sortConfig.direction === 'ascending' ? 'ascending-icon' : 'descending-icon') : ''}`}></span>
                            </th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {currentRows.map((row, rowIndex) => (
                        <tr key={rowIndex} onClick={() => rowOnClick(row)}>
                            {headers.map((header, colIndex) => (
                                <td key={colIndex}>{row[header]}</td>
                            ))}
                        </tr>
                    ))}
                </tbody>
            </table>

            {/* Pagination Controls */}
            {pagination && <div className="pagination">
                <span className='pagination_text'>
                    Page {currentPage} of {totalPages}
                </span>
                <div  className='pagination_buttons'>
                <button className='pagination_button' onClick={prevPage} disabled={currentPage === 1}>
                    Previous
                </button>
                <button className='pagination_button' onClick={nextPage} disabled={currentPage === totalPages}>
                    Next
                </button>
                </div>
            </div>}
        </div>
    );
};

export default DynamicTable;
